<script setup lang="ts">
import DeviceTableCardMode from '@/components/DeviceComponents/Table/DeviceTableCardMode.vue'
import { DEVICE_ROUTES } from '@/router/routes'
import organizationApi from '@/services/organization.service'
import SocketDeviceAlertsWrapper from '@/socket/SocketDeviceAlertsWrapper.vue'
import { buildDetectionArray } from '@/socket/socketUtils'
import store from '@/store'
import { EDetectionActions } from '@/store/detectionStore/DetectionStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { type DeviceModel } from '@/types/models/DeviceModel'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type { SocketDeviceDetectionModel } from '@/types/socket/SocketDetectionModel'
import { onMounted, ref, watch } from 'vue'

type Props = {
    detections?: SocketDeviceDetectionModel[]
    eventId: number
    currentEvent?: boolean
    associates?: number[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'devicesUpdate', value: SocketDeviceDetectionModel[]): void
}
const emit = defineEmits<Emits>()

const latestDetections = ref<SocketDeviceDetectionModel[]>([])
watch(
    () => props.detections,
    newDetection => (newDetection?.length ? (latestDetections.value = newDetection) : undefined)
)
watch(
    () => props.associates,
    () => getDevices()
)

const devices = ref<DeviceModel[]>([])
const getDevices = () =>
    organizationApi
        .getMyDevices({ per_page: -1, filters: { event_id: props.eventId } })
        .then(res => {
            devices.value = res.data as DeviceModel[]
            const devicesDetections = devices.value.map(item => ({
                device_id: item.device_id ?? item.id,
                detections: []
            }))
            store
                .dispatch(`${EStoreModules.DETECTION}/${EDetectionActions.GET_LATEST_DETECTIONS}`, {
                    device_ids: devices.value.map(item => item.device_id)
                })
                .then((resDetections: DetectionModel[]) =>
                    emit(
                        'devicesUpdate',
                        buildDetectionArray(resDetections, devicesDetections, 'device_id')
                    )
                )
        })

onMounted(() => getDevices())

const deviceAlerts = ref<AlertSocketMessageModel[]>([])
const handleAlertsUpdate = (value: AlertSocketMessageModel[]) => (deviceAlerts.value = value)

defineExpose({ devices })
</script>

<template>
    <SocketDeviceAlertsWrapper
        v-if="currentEvent"
        :device_ids="devices.map(item => item.device_id ?? item.id)"
        @update="handleAlertsUpdate" />

    <DeviceTableCardMode
        :data="devices"
        :statuses="deviceAlerts"
        :detections="detections"
        showNextCheckpoint
        :editPermission="EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW"
        :editButtonHref="DEVICE_ROUTES.baseUrl" />
</template>
