<script setup lang="ts">
import ButtonsGroupFilters from '@/components/Filters/components/ButtonsGroupFilters.vue'
import { FullTableLayout, StackLayout } from '@/components/Layouts'
import { TablePagination } from '@/components/Table'
import router from '@/router'
import { ORGANIZATIONS_NETWORKS_ROUTES } from '@/router/routing/organizationRouting/organizationRoutes'
import { ESortBy } from '@/types/enum/SortByEnum'
import { ETableMode, ETableModeIcons, ETableModeValues } from '@/types/enum/TableModeEnum'
import type { NetworkModel } from '@/types/models/NetworkModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import store from '../../../store'
import { ENetworkActions } from '../../../store/networksStore/NetworkStateType'
import { EStoreModules } from '../../../store/storeType'
import HrefCreateButton from '../../Buttons/HrefCreateButton.vue'
import FullScreenLoader from '../../LoadingSpinner/FullScreenLoader.vue'
import PermissionWrapper from '../../Permission/PermissionWrapper.vue'
import OrganizationCustomersTableCardMode from './Table/OrganizationNetworksTableCardMode.vue'
import OrganizationCustomersTableListMode from './Table/OrganizationNetworksTableListMode.vue'

type Props = {
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    editButtonHref?: string
}
defineProps<Props>()

const loading = ref(false)
const data = computed(() => store.state.network)

const sort = ref(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const networks = computed(() => store.state.network)
const getCustomers = () =>
    store.dispatch(`${EStoreModules.NETWORK}/${ENetworkActions.FETCH_NETWORKS}`)

const goToDetail = (user: NetworkModel) =>
    router.push(ORGANIZATIONS_NETWORKS_ROUTES.buildUrl(user.id))

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getCustomers()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getCustomers()
}

const handleDeleteNetwork = (value: NetworkModel) =>
    store
        .dispatch(`${EStoreModules.NETWORK}/${ENetworkActions.DELETE_NETWORK}`, {
            id: value.id,
            name: value.party_name
        })
        .then(() => getCustomers())

const tableMode = ref<number>(ETableMode.CARD)
const tableModes = ETableModeValues.map(item => ({ icon: ETableModeIcons[item] }))
</script>

<template>
    <FullScreenLoader :isLoading="!!data?.isLoading" />
    <StackLayout direction="column" :gap="16">
        <PermissionWrapper :permission="editPermission" hasPermission>
            <HrefCreateButton
                :label="$t('organization.organizations.buttons.invitations')"
                :href="ORGANIZATIONS_NETWORKS_ROUTES.generateUrl" />
        </PermissionWrapper>
        <FullTableLayout :sortBy="sort" @changeSortBy="$emit('changeSortBy', $event)">
            <template #filters>
                <slot name="filters" />
            </template>
            <template #moreFilters>
                <ButtonsGroupFilters
                    :items="tableModes"
                    :value="tableMode"
                    @click="value => (tableMode = value as number)" />
            </template>

            <template #table>
                <OrganizationCustomersTableListMode
                    v-if="tableMode === ETableMode.LIST"
                    :data="networks.data.networks"
                    @click="goToDetail"
                    @delete="handleDeleteNetwork"
                    :editPermission="editPermission"
                    :deletePermission="deletePermission"
                    :editButtonHref="editButtonHref"
                    :loading="loading" />
                <OrganizationCustomersTableCardMode
                    v-else
                    :data="data.data.networks"
                    @click="goToDetail"
                    @revoke="handleDeleteNetwork"
                    :editPermission="editPermission"
                    :deletePermission="deletePermission"
                    :editButtonHref="editButtonHref"
                    :loading="loading" />
            </template>
            <template #pagination>
                <TablePagination
                    :perPage="data.per_page"
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :total="data.total"
                    forcePropsCurrentPage
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
