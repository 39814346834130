export enum EOrganizationRoutes {
    ORGANIZATION_HOME = 'organizationHome'
}
export const EOrganizationRoutesValues = [EOrganizationRoutes.ORGANIZATION_HOME]

export enum EOrganizationUsersRoutes {
    ORGANIZATION_USERS_CREATE = 'organizationUsersCreate',
    ORGANIZATION_USERS_DETAIL = 'organizationUsersDetail',
    ORGANIZATION_USERS_TABLE = 'organizationUsersTable'
}
export const EOrganizationUsersRoutesValues = [
    EOrganizationUsersRoutes.ORGANIZATION_USERS_CREATE,
    EOrganizationUsersRoutes.ORGANIZATION_USERS_DETAIL,
    EOrganizationUsersRoutes.ORGANIZATION_USERS_TABLE
]

export enum EOrganizationNetworksRoutes {
    ORGANIZATION_NETWORKS_CREATE = 'organizationNetworksCreate',
    ORGANIZATION_NETWORKS_DETAIL = 'organizationNetworksDetail',
    ORGANIZATION_NETWORKS_TABLE = 'organizationNetworksTable',
    ORGANIZATION_NETWORKS_GENERATE = 'organizationNetworksGenerate'
}
export const EOrganizationNetworksRoutesValues = [
    EOrganizationNetworksRoutes.ORGANIZATION_NETWORKS_CREATE,
    EOrganizationNetworksRoutes.ORGANIZATION_NETWORKS_DETAIL,
    EOrganizationNetworksRoutes.ORGANIZATION_NETWORKS_GENERATE,
    EOrganizationNetworksRoutes.ORGANIZATION_NETWORKS_TABLE
]

export enum EOrganizationRolesRoutes {
    ORGANIZATION_ROLES_CREATE = 'organizationRolesCreate',
    ORGANIZATION_ROLES_DETAIL = 'organizationRolesDetail',
    ORGANIZATION_ROLES_TABLE = 'organizationRolesTable'
}
export const EOrganizationRolesRoutesValues = [
    EOrganizationRolesRoutes.ORGANIZATION_ROLES_CREATE,
    EOrganizationRolesRoutes.ORGANIZATION_ROLES_DETAIL,
    EOrganizationRolesRoutes.ORGANIZATION_ROLES_TABLE
]

export enum EOrganizationApisRoutes {
    ORGANIZATION_APIS_CREATE = 'organizationApisCreate',
    ORGANIZATION_APIS_DETAIL = 'organizationApisDetail',
    ORGANIZATION_APIS_TABLE = 'organizationApisTable'
}
export const EOrganizationApisRoutesValues = [
    EOrganizationApisRoutes.ORGANIZATION_APIS_CREATE,
    EOrganizationApisRoutes.ORGANIZATION_APIS_DETAIL,
    EOrganizationApisRoutes.ORGANIZATION_APIS_TABLE
]
