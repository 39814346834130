<script setup lang="ts">
import CollapseItem from '@/components/Collapse/CollapseItem.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceCommandHistoryModel } from '@/types/models/DeviceCommandHistoryModel'
import { ref, watch } from 'vue'
import TablePagination from '../../../../../components/Table/TablePagination.vue'
import { type PaginationDataType } from '../../../../../types/PaginationType'
import HistoryArguments from './HistoryArguments.vue'
import HistoryCommandMetaData from './HistoryCommandMetaData.vue'
import HistoryRemarks from './HistoryRemarks.vue'
import HistoryTitle from './HistoryTitle.vue'

type Props = {
    deviceId: number
    open?: boolean
}
const props = defineProps<Props>()

const collapseOpen = ref(false)
const pagination = ref<Partial<PaginationDataType<DeviceCommandHistoryModel[]>>>({
    per_page: 10,
    current_page: 1
})

const getCommandsHistory = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.GET_DEVICE_COMMANDS_HISTORY}`, {
            deviceId: props.deviceId,
            params: {
                per_page: pagination.value.per_page,
                page: pagination.value.current_page
            }
        })
        .then(res => (pagination.value = res))

watch(
    () => props.open,
    newOpen => {
        if (newOpen) {
            collapseOpen.value = false
            getCommandsHistory()
        }
    },
    { immediate: true }
)

const handleChangePageNumber = (page: number) => {
    pagination.value = { ...(pagination.value ?? {}), current_page: page }
    getCommandsHistory()
}
</script>

<template>
    <CollapseItem
        v-if="pagination.data?.length"
        :open="collapseOpen"
        @click="collapseOpen = $event"
        withIcon
        class="collapse-item history-collapse">
        <template #title>
            <TypographyItem :label="$t('device.actions.commands.historyText')" />
        </template>
        <template #content>
            <StackLayout direction="column" :gap="8">
                <CollapseItem
                    v-for="(command, key) in pagination.data"
                    :key="key"
                    withIcon
                    class="collapse-item full-width">
                    <template #title>
                        <HistoryTitle v-if="command" :command="command" />
                    </template>
                    <template #content>
                        <StackLayout direction="column" :gap="8">
                            <HistoryCommandMetaData v-if="command" :command="command" />
                            <HistoryArguments
                                v-if="
                                    command?.arguments &&
                                    !Array.isArray(command?.arguments) &&
                                    Object.entries(command?.arguments)?.length
                                "
                                :arguments="command.arguments" />
                            <HistoryRemarks v-if="command?.remarks" :remarks="command.remarks" />
                        </StackLayout>
                    </template>
                </CollapseItem>
                <TablePagination
                    :perPage="pagination.per_page"
                    :currentPage="pagination.current_page"
                    :lastPage="pagination.last_page"
                    :total="pagination.total"
                    hidePageSize
                    forcePropsCurrentPage
                    @change:pageNumber="handleChangePageNumber" />
            </StackLayout>
        </template>
    </CollapseItem>
</template>

<style scoped lang="scss">
.collapse-item {
    & :deep(.collapse-title) {
        width: 100%;
        place-content: space-between;
        box-shadow: 0px 0px 3px 1px $primary;
        padding: 4px;
    }
    & :deep(.collapse-content) {
        padding: 8px;
        border: 1px solid $primary;
    }
    &:deep(.collapse-title) {
        flex-direction: row;
    }

    color: var(--color-text);

    & :deep(.pagination-stack) {
        flex-direction: column;
    }
    & :deep(.pagination-buttons-stack) {
        flex-direction: row;
    }

    & :deep(.pagination-page-stack) {
        gap: 0px;
    }
}

.command-arguments {
    &.stack {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.history-collapse {
    overflow-y: auto;
}
</style>
