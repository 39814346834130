<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import TabsComponent from '@/components/Tabs/TabsComponent.vue'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { showTab } from '@/utils/permissionsUtils'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import {
    ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES,
    ADMIN_DEVICE_TEMPLATES_ROUTES,
    ADMIN_DEVICE_TYPES_ROUTES,
    ADMIN_FIRMWARES_ROUTES
} from '../../../router/routing/adminRouting/adminRoutes'
import DeviceFirmwaresTableView from './Firmwares/DeviceFirmwaresTableView.vue'
import DeviceTemplateKeysTableView from './TemplateKeys/DeviceTemplateKeysTableView.vue'
import DeviceTemplatesTableView from './Templates/DeviceTemplatesTableView.vue'
import DeviceTypesTableView from './Types/DeviceTypesTableView.vue'

const { t } = useI18n()
const route = useRoute()

const hasConfigurationsViewPermission = computed(() =>
    showTab(
        {
            id: 'configurations',
            label: t('deviceConfig.tabs.configurations')
        },
        EAdminPerm.ADMIN_DEVICES_CONFIG
    )
)
const hasTemplateKeysViewPermission = computed(() =>
    showTab(
        {
            id: 'templateKeys',
            label: t('deviceConfig.tabs.templateKeys')
        },
        EAdminPerm.ADMIN_DEVICES_CONFIG
    )
)
const hasTypesViewPermission = computed(() =>
    showTab(
        {
            id: 'types',
            label: t('deviceConfig.tabs.types')
        },
        EAdminPerm.ADMIN_DEVICES_CONFIG
    )
)
const hasFirmwaresViewPermission = computed(() =>
    showTab(
        {
            id: 'firmwares',
            label: t('deviceConfig.tabs.firmwares')
        },
        EAdminPerm.ADMIN_DEVICES_CONFIG
    )
)

const tabs = computed(() => [
    ...hasConfigurationsViewPermission.value,
    ...hasTemplateKeysViewPermission.value,
    ...hasTypesViewPermission.value,
    ...hasFirmwaresViewPermission.value
])

const currentTab = computed(() => parseInt((route.query.tab ?? 0).toString()) ?? 0)
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <TabsComponent :tabs="tabs" class="relative">
            <template #configurations>
                <DeviceTemplatesTableView
                    v-if="currentTab === 0"
                    :isCurrentTab="currentTab === 0"
                    :permission="EAdminPerm.ADMIN_DEVICES_CONFIG"
                    :editButtonHref="ADMIN_DEVICE_TEMPLATES_ROUTES.baseUrl"
                    :createButtonHref="ADMIN_DEVICE_TEMPLATES_ROUTES.createUrl" />
            </template>
            <template #templateKeys>
                <DeviceTemplateKeysTableView
                    v-if="currentTab === 1"
                    :isCurrentTab="currentTab === 1"
                    :permission="EAdminPerm.ADMIN_DEVICES_CONFIG"
                    :editButtonHref="ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES.baseUrl"
                    :createButtonHref="ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES.createUrl" />
            </template>
            <template #types>
                <DeviceTypesTableView
                    v-if="currentTab === 2"
                    :isCurrentTab="currentTab === 2"
                    :permission="EAdminPerm.ADMIN_DEVICES_CONFIG"
                    :editButtonHref="ADMIN_DEVICE_TYPES_ROUTES.baseUrl"
                    :createButtonHref="ADMIN_DEVICE_TYPES_ROUTES.createUrl" />
            </template>
            <template #firmwares>
                <DeviceFirmwaresTableView
                    v-if="currentTab === 3"
                    :isCurrentTab="currentTab === 3"
                    :permission="EAdminPerm.ADMIN_DEVICES_CONFIG"
                    :editButtonHref="ADMIN_FIRMWARES_ROUTES.baseUrl"
                    :createButtonHref="ADMIN_FIRMWARES_ROUTES.createUrl" />
            </template>
        </TabsComponent>
    </StackLayout>
</template>
