import i18n from '@/locales/i18n'
import type { RouteType } from '@/types/Route'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { getValuesFromEnum } from '@/utils/enumUtils'
import {
    AdministrationApiTableView,
    AdministrationDeviceCreateView,
    AdministrationDeviceDetailView,
    AdministrationDevicesTableView,
    AdministrationRoleCreate,
    AdministrationRoleEdit,
    AdministrationRolesTableView,
    AdministrationUsersTableView,
    AdministrationView
} from '@/views/AdministrationView'
import AdministrationApiCreate from '@/views/AdministrationView/Api/Detail/AdministrationApiCreate.vue'
import AdministrationApiEdit from '@/views/AdministrationView/Api/Detail/AdministrationApiEdit.vue'
import DeviceConfigTableView from '@/views/AdministrationView/DeviceConfig/DeviceConfigTableView.vue'
import DeviceTemplatesCreateView from '@/views/AdministrationView/DeviceConfig/Templates/DeviceTemplatesCreateView.vue'
import DeviceTemplatesDetailView from '@/views/AdministrationView/DeviceConfig/Templates/DeviceTemplatesDetailView.vue'
import AdministrationOrganizationsTableView from '@/views/AdministrationView/Organization/AdministrationOrganizationsTableView.vue'
import AdministrationOrganizationApisCreate from '@/views/AdministrationView/Organization/Api/AdministrationOrganizationApisCreate.vue'
import AdministrationOrganizationApisDetail from '@/views/AdministrationView/Organization/Api/AdministrationOrganizationApisDetail.vue'
import AdministrationCreateOrganization from '@/views/AdministrationView/Organization/Detail/AdministrationCreateOrganization.vue'
import AdministrationUpdateOrganization from '@/views/AdministrationView/Organization/Detail/AdministrationUpdateOrganization.vue'
import AdministrationOrganizationUsersCreate from '@/views/AdministrationView/Organization/Users/AdministrationOrganizationUsersCreate.vue'
import AdministrationOrganizationUsersDetail from '@/views/AdministrationView/Organization/Users/AdministrationOrganizationUsersDetail.vue'
import AdministrationCreateUser from '@/views/AdministrationView/User/Detail/AdministrationCreateUser.vue'
import AdministrationUpdateUser from '@/views/AdministrationView/User/Detail/AdministrationUpdateUser.vue'
import { ref } from 'vue'
import {
    ADMIN_APIS_ROUTES,
    ADMIN_DEVICES_ROUTES,
    ADMIN_DEVICE_CONFIGURATION_ROUTE,
    ADMIN_DEVICE_TEMPLATES_ROUTES,
    ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES,
    ADMIN_DEVICE_TYPES_ROUTES,
    ADMIN_FIRMWARES_ROUTES,
    ADMIN_ORGANIZATIONS_ROUTES,
    ADMIN_ORGANIZATION_API_ROUTES,
    ADMIN_ORGANIZATION_DEVICES_ROUTES,
    ADMIN_ORGANIZATION_USER_ROUTES,
    ADMIN_ROLES_ROUTES,
    ADMIN_ROUTE,
    ADMIN_USERS_ROUTES
} from './adminRoutes'

import DeviceTemplateKeysCreateView from '@/views/AdministrationView/DeviceConfig/TemplateKeys/DeviceTemplateKeysCreateView.vue'
import DeviceTemplateKeysDetailView from '@/views/AdministrationView/DeviceConfig/TemplateKeys/DeviceTemplateKeysDetailView.vue'

import DeviceTypesCreateView from '@/views/AdministrationView/DeviceConfig/Types/DeviceTypesCreateView.vue'
import DeviceTypesDetailView from '@/views/AdministrationView/DeviceConfig/Types/DeviceTypesDetailView.vue'
import DeviceFirmwaresCreateView from '../../../views/AdministrationView/DeviceConfig/Firmwares/DeviceFirmwaresCreateView.vue'
import DeviceFirmwaresDetailView from '../../../views/AdministrationView/DeviceConfig/Firmwares/DeviceFirmwaresDetailView.vue'
import {
    EAdminApisRoutes,
    EAdminDeviceConfig,
    EAdminDeviceFirmwares,
    EAdminDeviceTemplate,
    EAdminDeviceTemplateKeys,
    EAdminDevicesRoutes,
    EAdminOrganizations,
    EAdminOrganizationsApis,
    EAdminOrganizationsDevices,
    EAdminOrganizationsUsers,
    EAdminRolesRoutes,
    EAdminRoutes,
    EAdminUsersRoutes
} from './AdminRoutesEnum'

export const allAdminPermissions = getValuesFromEnum({
    ...EAdminPerm,
    ...EAdminPerm,
    ...EAdminPerm,
    ...EAdminPerm,

    ...EAdminPerm,
    ...EAdminPerm,
    ...EAdminPerm,
    ...EAdminPerm
})

const routes: RouteType[] = [
    {
        path: ADMIN_ROUTE,
        name: EAdminRoutes.ADMIN_HOME,
        component: AdministrationView,
        meta: {
            title: ref(i18n.global.t('menu.administration.title')),
            permissions: allAdminPermissions
        }
    },
    {
        path: ADMIN_DEVICES_ROUTES.baseUrl,
        name: EAdminDevicesRoutes.ADMIN_DEVICES_TABLE,
        component: AdministrationDevicesTableView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devices')),
            permission: EAdminPerm.ADMIN_DEVICES
        }
    },
    {
        path: ADMIN_DEVICES_ROUTES.detailUrl,
        name: EAdminDevicesRoutes.ADMIN_DEVICES_DETAIL,
        component: AdministrationDeviceDetailView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devices')),
            permission: EAdminPerm.ADMIN_DEVICES
        }
    },
    {
        path: ADMIN_DEVICES_ROUTES.createUrl,
        name: EAdminDevicesRoutes.ADMIN_DEVICES_CREATE,
        component: AdministrationDeviceCreateView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devices')),
            permission: EAdminPerm.ADMIN_DEVICES
        }
    },
    {
        path: ADMIN_USERS_ROUTES.baseUrl,
        name: EAdminUsersRoutes.ADMIN_USERS_TABLE,
        component: AdministrationUsersTableView,
        meta: {
            title: ref(i18n.global.t('menu.administration.users')),
            permission: EAdminPerm.ADMIN_USERS
        }
    },
    {
        path: ADMIN_USERS_ROUTES.detailUrl,
        name: EAdminUsersRoutes.ADMIN_USERS_DETAIL,
        component: AdministrationUpdateUser,
        meta: {
            title: ref(i18n.global.t('menu.administration.users')),
            permission: EAdminPerm.ADMIN_USERS
        }
    },
    {
        path: ADMIN_USERS_ROUTES.createUrl,
        name: EAdminUsersRoutes.ADMIN_USERS_CREATE,
        component: AdministrationCreateUser,
        meta: {
            title: ref(i18n.global.t('menu.administration.users')),
            permission: EAdminPerm.ADMIN_USERS
        }
    },
    {
        path: ADMIN_APIS_ROUTES.baseUrl,
        name: EAdminApisRoutes.ADMIN_APIS_TABLE,
        component: AdministrationApiTableView,
        meta: {
            title: ref(i18n.global.t('menu.administration.api')),
            permission: EAdminPerm.ADMIN_APIS
        }
    },
    {
        path: ADMIN_APIS_ROUTES.detailUrl,
        name: EAdminApisRoutes.ADMIN_APIS_DETAIL,
        component: AdministrationApiEdit,
        meta: {
            title: ref(i18n.global.t('menu.administration.api')),
            permission: EAdminPerm.ADMIN_APIS
        }
    },
    {
        path: ADMIN_APIS_ROUTES.createUrl,
        name: EAdminApisRoutes.ADMIN_APIS_CREATE,
        component: AdministrationApiCreate,
        meta: {
            title: ref(i18n.global.t('menu.administration.api')),
            permission: EAdminPerm.ADMIN_APIS
        }
    },
    {
        path: ADMIN_ROLES_ROUTES.baseUrl,
        name: EAdminRolesRoutes.ADMIN_ROLES_TABLE,
        component: AdministrationRolesTableView,
        meta: {
            title: ref(i18n.global.t('menu.administration.roles')),
            permission: EAdminPerm.ADMIN_ROLES
        }
    },
    {
        path: ADMIN_ROLES_ROUTES.detailUrl,
        name: EAdminRolesRoutes.ADMIN_ROLES_DETAIL,
        component: AdministrationRoleEdit,
        meta: {
            title: ref(i18n.global.t('menu.administration.roles')),
            permission: EAdminPerm.ADMIN_ROLES
        }
    },
    {
        path: ADMIN_ROLES_ROUTES.createUrl,
        name: EAdminRolesRoutes.ADMIN_ROLES_CREATE,
        component: AdministrationRoleCreate,
        meta: {
            title: ref(i18n.global.t('menu.administration.roles')),
            permission: EAdminPerm.ADMIN_ROLES
        }
    },
    {
        path: ADMIN_ORGANIZATIONS_ROUTES.baseUrl,
        name: EAdminOrganizations.ADMIN_ORGANIZATIONS_TABLE,
        component: AdministrationOrganizationsTableView,
        meta: {
            title: ref(i18n.global.t('menu.administration.organizations'))
            /* permission: EAdminPerm.ADMIN_ORGANIZATIONS */
        }
    },
    {
        path: ADMIN_ORGANIZATIONS_ROUTES.detailUrl,
        name: EAdminOrganizations.ADMIN_ORGANIZATIONS_DETAIL,
        component: AdministrationUpdateOrganization,
        meta: {
            title: ref(i18n.global.t('menu.administration.organizations'))
            /* permission: EAdminPerm.ADMIN_ORGANIZATIONS */
        }
    },
    {
        path: ADMIN_ORGANIZATIONS_ROUTES.createUrl,
        name: EAdminOrganizations.ADMIN_ORGANIZATIONS_CREATE,
        component: AdministrationCreateOrganization,
        meta: {
            title: ref(i18n.global.t('menu.administration.organizations'))
            /* permission: EAdminPerm.ADMIN_ORGANIZATIONS */
        }
    },
    {
        path: ADMIN_ORGANIZATION_USER_ROUTES().createUrl,
        name: EAdminOrganizationsUsers.ADMIN_ORGANIZATIONS_USER_CREATE,
        component: AdministrationOrganizationUsersCreate,
        meta: {
            title: ref(i18n.global.t('menu.administration.organizations')),
            permission: EAdminPerm.ADMIN_ORGANIZATIONS
        }
    },
    {
        path: ADMIN_ORGANIZATION_USER_ROUTES().detailUrl,
        name: EAdminOrganizationsUsers.ADMIN_ORGANIZATIONS_USER_DETAIL,
        component: AdministrationOrganizationUsersDetail,
        meta: {
            title: ref(i18n.global.t('menu.administration.organizations')),
            permission: EAdminPerm.ADMIN_ORGANIZATIONS
        }
    },
    {
        path: ADMIN_ORGANIZATION_API_ROUTES().createUrl,
        name: EAdminOrganizationsApis.ADMIN_ORGANIZATIONS_API_CREATE,
        component: AdministrationOrganizationApisCreate,
        meta: {
            title: ref(i18n.global.t('menu.administration.organizations')),
            permission: EAdminPerm.ADMIN_ORGANIZATIONS
        }
    },
    {
        path: ADMIN_ORGANIZATION_API_ROUTES().detailUrl,
        name: EAdminOrganizationsApis.ADMIN_ORGANIZATIONS_API_DETAIL,
        component: AdministrationOrganizationApisDetail,
        meta: {
            title: ref(i18n.global.t('menu.administration.organizations')),
            permission: EAdminPerm.ADMIN_ORGANIZATIONS
        }
    },
    {
        path: ADMIN_ORGANIZATION_DEVICES_ROUTES().createUrl,
        name: EAdminOrganizationsDevices.ADMIN_ORGANIZATIONS_DEVICES_CREATE,
        component: AdministrationDeviceCreateView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devices')),
            permission: EAdminPerm.ADMIN_ORGANIZATIONS
        }
    },
    {
        path: ADMIN_ORGANIZATION_DEVICES_ROUTES().detailUrl,
        name: EAdminOrganizationsDevices.ADMIN_ORGANIZATIONS_DEVICES_DETAIL,
        component: AdministrationDeviceDetailView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devices')),
            permission: EAdminPerm.ADMIN_ORGANIZATIONS
        }
    },
    {
        path: ADMIN_DEVICE_CONFIGURATION_ROUTE,
        name: EAdminDeviceConfig.ADMIN_DEVICE_CONFIG_TABLE,
        component: DeviceConfigTableView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesConfig')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    },
    {
        path: ADMIN_DEVICE_TYPES_ROUTES.detailUrl,
        name: EAdminDeviceConfig.ADMIN_DEVICE_CONFIG_DETAIL,
        component: DeviceTypesDetailView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesTypes')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    },
    {
        path: ADMIN_DEVICE_TYPES_ROUTES.createUrl,
        name: EAdminDeviceConfig.ADMIN_DEVICE_CONFIG_CREATE,
        component: DeviceTypesCreateView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesTypes')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    },
    {
        path: ADMIN_DEVICE_TEMPLATES_ROUTES.detailUrl,
        name: EAdminDeviceTemplate.ADMIN_DEVICE_TEMPLATE_DETAIL,
        component: DeviceTemplatesDetailView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesTemplate')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    },
    {
        path: ADMIN_DEVICE_TEMPLATES_ROUTES.createUrl,
        name: EAdminDeviceTemplate.ADMIN_DEVICE_TEMPLATE_CREATE,
        component: DeviceTemplatesCreateView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesTemplate')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    },
    {
        path: ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES.detailUrl,
        name: EAdminDeviceTemplateKeys.ADMIN_DEVICE_TEMPLATE_KEYS_DETAIL,
        component: DeviceTemplateKeysDetailView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesTemplateKeys')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    },
    {
        path: ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES.createUrl,
        name: EAdminDeviceTemplateKeys.ADMIN_DEVICE_TEMPLATE_KEYS_CREATE,
        component: DeviceTemplateKeysCreateView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesTemplateKeys')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    },
    {
        path: ADMIN_FIRMWARES_ROUTES.detailUrl,
        name: EAdminDeviceFirmwares.ADMIN_DEVICE_FIRMWARES_DETAIL,
        component: DeviceFirmwaresDetailView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesFirmware')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    },
    {
        path: ADMIN_FIRMWARES_ROUTES.createUrl,
        name: EAdminDeviceFirmwares.ADMIN_DEVICE_FIRMWARES_CREATE,
        component: DeviceFirmwaresCreateView,
        meta: {
            title: ref(i18n.global.t('menu.administration.devicesFirmware')),
            permission: EAdminPerm.ADMIN_DEVICES_CONFIG
        }
    }
]

export default routes
