import type { TabHref } from '@/types/components/TabHref'

/* ORGANIZATION ROUTES */
export const ORGANIZATIONS_ROUTE = '/organizations'
export const ORGANIZATIONS_ROUTES = {
    baseUrl: ORGANIZATIONS_ROUTE,
    createUrl: `${ORGANIZATIONS_ROUTE}/create`,
    generateUrl: `${ORGANIZATIONS_ROUTE}/generate`,
    detailUrl: `${ORGANIZATIONS_ROUTE}/:id`,
    buildUrl: (organizationId: number) => `${ORGANIZATIONS_ROUTE}/${organizationId}`
}

export const ORGANIZATIONS_USERS_ROUTE = `${ORGANIZATIONS_ROUTE}/users`
export const ORGANIZATIONS_USERS_ROUTES = {
    baseUrl: ORGANIZATIONS_USERS_ROUTE,
    createUrl: `${ORGANIZATIONS_USERS_ROUTE}/create`,
    detailUrl: `${ORGANIZATIONS_USERS_ROUTE}/:id`,
    buildUrl: (organizationId: number) => `${ORGANIZATIONS_USERS_ROUTE}/${organizationId}`
}
export const ORGANIZATIONS_USERS_HREF: TabHref = {
    editButtonHref: ORGANIZATIONS_USERS_ROUTES.baseUrl,
    createButtonHref: ORGANIZATIONS_USERS_ROUTES.createUrl
}

export const ORGANIZATIONS_NETWORKS_ROUTE = `${ORGANIZATIONS_ROUTE}/networks`
export const ORGANIZATIONS_NETWORKS_ROUTES = {
    baseUrl: ORGANIZATIONS_NETWORKS_ROUTE,
    createUrl: `${ORGANIZATIONS_NETWORKS_ROUTE}/create`,
    generateUrl: `${ORGANIZATIONS_NETWORKS_ROUTE}/generate`,
    inviteUrl: `${ORGANIZATIONS_NETWORKS_ROUTE}/invitations`,
    detailUrl: `${ORGANIZATIONS_NETWORKS_ROUTE}/:id`,
    buildUrl: (organizationId: number) => `${ORGANIZATIONS_NETWORKS_ROUTE}/${organizationId}`
}
export const ORGANIZATIONS_NETWORKS_HREF: TabHref = {
    editButtonHref: ORGANIZATIONS_NETWORKS_ROUTES.baseUrl,
    createButtonHref: ORGANIZATIONS_NETWORKS_ROUTES.createUrl
}

export const ORGANIZATIONS_ROLES_ROUTE = `${ORGANIZATIONS_ROUTE}/roles`
export const ORGANIZATIONS_ROLES_ROUTES = {
    baseUrl: ORGANIZATIONS_ROLES_ROUTE,
    createUrl: `${ORGANIZATIONS_ROLES_ROUTE}/create`,
    detailUrl: `${ORGANIZATIONS_ROLES_ROUTE}/:id`,
    buildUrl: (organizationId: number) => `${ORGANIZATIONS_ROLES_ROUTE}/${organizationId}`
}
export const ORGANIZATIONS_ROLES_HREF: TabHref = {
    editButtonHref: ORGANIZATIONS_ROLES_ROUTES.baseUrl,
    createButtonHref: ORGANIZATIONS_ROLES_ROUTES.createUrl
}

export const ORGANIZATIONS_APIS_ROUTE = `${ORGANIZATIONS_ROUTE}/apis`
export const ORGANIZATIONS_APIS_ROUTES = {
    baseUrl: ORGANIZATIONS_APIS_ROUTE,
    createUrl: `${ORGANIZATIONS_APIS_ROUTE}/create`,
    detailUrl: `${ORGANIZATIONS_APIS_ROUTE}/:id`,
    buildUrl: (organizationId: number) => `${ORGANIZATIONS_APIS_ROUTE}/${organizationId}`
}
export const ORGANIZATIONS_APIS_HREF: TabHref = {
    editButtonHref: ORGANIZATIONS_APIS_ROUTES.baseUrl,
    createButtonHref: ORGANIZATIONS_APIS_ROUTES.createUrl
}
