<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import store from '@/store'
import { ECheckpointActions } from '@/store/checkpointStore/CheckpointStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ref } from 'vue'
import IconButton from '../../../../../components/Buttons/IconButton.vue'
import type { CheckpointModel } from '../../../../../types/models/CheckpointModel'

type Props = {
    asIcon?: boolean
    data: CheckpointModel
}
const props = defineProps<Props>()

type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const open = ref(false)

const handleConfirm = () =>
    store
        .dispatch(`${EStoreModules.CHECKPOINT}/${ECheckpointActions.ERASE_DETECTIONS}`, {
            eventId: props.data.event_id,
            checkpointId: props.data.id,
            name: props.data.name
        })
        .then(() => {
            emit('refresh')
            open.value = false
        })

const handleClick = (clickValue: boolean, event?: MouseEvent) => {
    event?.preventDefault()
    event?.stopImmediatePropagation()
    event?.stopPropagation()
    open.value = clickValue
}
</script>

<template>
    <IconButton
        v-if="asIcon"
        icon="clock-rotate-left"
        @click="handleClick(true, $event)"
        class="erase-button" />
    <PrimaryButton
        v-else
        :label="$t('checkpoint.actions.eraseDetectionsTitle')"
        @click="handleClick(true, $event)"
        class="erase-button" />
    <BaseDialog
        :title="$t('checkpoint.actions.eraseDetectionsTitle')"
        :open="open"
        @confirm="handleConfirm"
        @close="handleClick(false, $event)"
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <TypographyItem :label="$t('checkpoint.actions.eraseDetections')" />
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.erase-button {
    border-radius: 30px;
    &:hover {
        background-color: $primary;
    }
}
</style>
