<script setup lang="ts">
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import networkApi from '@/services/network.service'
import type { NetworkModel } from '@/types/models/NetworkModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

type Props = {
    organizationId?: number
    label?: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'select', organizationValue: NetworkModel): void
}
defineEmits<Emits>()

const getOrganizations = (party_name?: string) =>
    networkApi
        .getMyNetworks(party_name ? { filters: { party_name } } : {}, true)
        .then(res => (organizationsOptions.value = res.data))
        .finally(() => (isLoadingOrganization.value = false))
onMounted(() => getOrganizations())

const handleTyping = (value: string) => {
    isLoadingOrganization.value = true
    debouncedOrganization(value)
}
const isLoadingOrganization = ref(false)
const debouncedOrganization = useDebounceFn(
    (value: string) => getOrganizations(value),
    EDebounceTime.DEFAULT
)

const organizationsOptions = ref<NetworkModel[]>([])
const selectedOrganization = computed(
    () =>
        organizationsOptions.value.find(item => item.party_id === props.organizationId)
            ?.party_name ?? ''
)
</script>

<template>
    <AutocompleteField
        :label="label ?? $t('user.create.existingOrganization')"
        :loading="isLoadingOrganization"
        :modelValue="selectedOrganization"
        :options="organizationsOptions"
        field="party_name"
        @typing="handleTyping"
        @select="$emit('select', $event as NetworkModel)"
        :clearable="false" />
</template>
