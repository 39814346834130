import store from '@/store'
import {
    EOrganizationsNetworksPerm,
    EOrganizationsPerm,
    EOrganizationsRolesPerm,
    EOrganizationsUsersPerm
} from '@/types/permissions/OrganizationsPermissionsEnum'
import { userHasPermission } from '@/utils/permissionsUtils'
import { computed } from 'vue'
import { ORGANIZATIONS_ROUTE } from './organizationRoutes'
import {
    EOrganizationApisRoutesValues,
    EOrganizationNetworksRoutesValues,
    EOrganizationRolesRoutesValues,
    EOrganizationRoutesValues,
    EOrganizationUsersRoutesValues
} from './OrganizationRoutesEnum'

export const organizationMenu = computed(() => [
    {
        icon: 'building',
        label: 'menu.organization',
        to: ORGANIZATIONS_ROUTE,
        permissions:
            !!store.state.authentication.me.organization_id &&
            (userHasPermission(EOrganizationsPerm.ORGANIZATIONS_VIEW) ||
                userHasPermission(EOrganizationsNetworksPerm.ORGANIZATIONS_NETWORKS_VIEW) ||
                userHasPermission(EOrganizationsUsersPerm.ORGANIZATIONS_USERS_VIEW) ||
                userHasPermission(EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_VIEW)),
        names: [
            ...EOrganizationRoutesValues,
            ...EOrganizationUsersRoutesValues,
            ...EOrganizationNetworksRoutesValues,
            ...EOrganizationRolesRoutesValues,
            ...EOrganizationApisRoutesValues
        ]
    }
])
