import i18n from '@/locales/i18n'
import networkService from '@/services/network.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { NetworkModel } from '@/types/models/NetworkModel'
import { getPageSize } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { ENetworkActions, ENetworkMutations, type NetworkState } from './NetworkStateType'

const state: Ref<PaginationDataType<NetworkState>> = ref({
    data: { networks: [] },

    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
    isLoading: false
})

const getters = {
    getNetworks: () => state.value.data.networks,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [ENetworkActions.FETCH_NETWORKS](
        { commit }: { commit: Commit },
        params: Partial<PaginationType>
    ) {
        commit(ENetworkMutations.SET_LOADING, true)
        return await networkService
            .getMyNetworks(params)
            .then(data => {
                commit(ENetworkMutations.SET_NETWORKS, data.data)
                commit(ENetworkMutations.SET_TOTAL, data)
                return data
            })
            .finally(() => {
                commit(ENetworkMutations.SET_LOADING, false)
            })
    },
    async [ENetworkActions.FETCH_PENDING_INVITES](
        { commit }: { commit: Commit },
        params: Partial<PaginationType>
    ) {
        commit(ENetworkMutations.SET_LOADING, true)
        return await networkService
            .getPendingInvites(params)
            .then(data => data)
            .finally(() => {
                commit(ENetworkMutations.SET_LOADING, false)
            })
    },
    async [ENetworkActions.FETCH_RECEIVED_INVITES](
        { commit }: { commit: Commit },
        params: Partial<PaginationType>
    ) {
        commit(ENetworkMutations.SET_LOADING, true)
        return await networkService
            .getReceivedInvites(params)
            .then(data => data)
            .finally(() => {
                commit(ENetworkMutations.SET_LOADING, false)
            })
    },
    async [ENetworkActions.SEND_NETWORK_INVITE]({ commit }: { commit: Commit }, email: string) {
        commit(ENetworkMutations.SET_LOADING, true)
        return await networkService
            .sendNetworkInvite(email)
            .then(data => {
                commit(ENetworkMutations.SET_NETWORKS, data.data)
                commit(ENetworkMutations.SET_TOTAL, data)
            })
            .finally(() => {
                commit(ENetworkMutations.SET_LOADING, false)
            })
    },
    async [ENetworkActions.DECLINE_NETWORK_INVITE](
        { commit }: { commit: Commit },
        { email, uuid }: { email: string; uuid: string }
    ) {
        commit(ENetworkMutations.SET_LOADING, true)
        return await networkService
            .declineNetworkInvite(email, uuid)
            .then(data => {
                commit(ENetworkMutations.SET_NETWORKS, data.data)
                commit(ENetworkMutations.SET_TOTAL, data)
            })
            .finally(() => {
                commit(ENetworkMutations.SET_LOADING, false)
            })
    },
    async [ENetworkActions.VALIDATE_NETWORK_INVITE](
        { commit }: { commit: Commit },
        { email, uuid }: { email: string; uuid: string }
    ) {
        commit(ENetworkMutations.SET_LOADING, true)
        return await networkService
            .validateNetworkInvite(email, uuid)
            .then(data => {
                commit(ENetworkMutations.SET_NETWORKS, data.data)
                commit(ENetworkMutations.SET_TOTAL, data)
            })
            .finally(() => {
                commit(ENetworkMutations.SET_LOADING, false)
            })
    },

    async [ENetworkActions.FETCH_INVITE]({ commit }: { commit: Commit }, uuid: string) {
        commit(ENetworkMutations.SET_LOADING, true)
        return await networkService
            .getInvite(uuid)
            .then(data => data)
            .finally(() => {
                commit(ENetworkMutations.SET_LOADING, false)
            })
    },

    async [ENetworkActions.DELETE_NETWORK](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, name }: { id: number; name: string }
    ) {
        commit(ENetworkMutations.SET_LOADING, true)
        return await networkService
            .deleteNetwork(id)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: ENetworkActions.DELETE_NETWORK
                })
                return res
            })
            .finally(() => {
                commit(ENetworkMutations.SET_LOADING, false)
            })
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`network.network.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`network.network.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [ENetworkMutations.SET_NETWORKS](_: NetworkState, networks: NetworkModel[]) {
        state.value.data.networks = networks
    },
    [ENetworkMutations.SET_NETWORK](_: NetworkState, network: NetworkModel) {
        state.value.data.network = network
    },
    [ENetworkMutations.SET_TOTAL](_: NetworkState, data: PaginationDataType<NetworkModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },
    [ENetworkMutations.SET_LOADING](_: NetworkState, isLoading: any) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
