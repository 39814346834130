import ApiCreate from '@/components/Organization/Apis/Detail/ApiCreate.vue'
import ApiEdit from '@/components/Organization/Apis/Detail/ApiEdit.vue'
import OrganizationNetworkLink from '@/components/Organization/Networks/Detail/OrganizationNetworkLink.vue'
import OrganizationInvitations from '@/components/Organization/Networks/OrganizationInvitations.vue'
import OrganizationUsersCreateVue from '@/components/Organization/Users/Create/OrganizationUsersCreate.vue'
import OrganizationUsersDetailVue from '@/components/Organization/Users/Detail/OrganizationUsersDetail.vue'
import RolesCreate from '@/components/Roles/RolesCreate.vue'
import RolesUpdate from '@/components/Roles/RolesUpdate.vue'
import i18n from '@/locales/i18n'
import {
    EOrganizationsApisPerm,
    EOrganizationsNetworksPerm,
    EOrganizationsPerm,
    EOrganizationsRolesPerm,
    EOrganizationsUsersPerm
} from '@/types/permissions/OrganizationsPermissionsEnum'
import type { RouteType } from '@/types/Route'
import { getValuesFromEnum } from '@/utils/enumUtils'
import OrganizationView from '@/views/OrganizationView/OrganizationView.vue'
import { computed, ref } from 'vue'
import {
    ORGANIZATIONS_APIS_ROUTES,
    ORGANIZATIONS_NETWORKS_ROUTES,
    ORGANIZATIONS_ROLES_ROUTES,
    ORGANIZATIONS_ROUTE,
    ORGANIZATIONS_USERS_ROUTES
} from './organizationRoutes'
import {
    EOrganizationApisRoutes,
    EOrganizationNetworksRoutes,
    EOrganizationRolesRoutes,
    EOrganizationRoutes,
    EOrganizationUsersRoutes
} from './OrganizationRoutesEnum'

export const allOrganizationsPermissions = getValuesFromEnum({
    ...EOrganizationsPerm,
    ...EOrganizationsNetworksPerm,
    ...EOrganizationsRolesPerm,
    ...EOrganizationsUsersPerm
})

const organizationRouting = computed<RouteType[]>(() => [
    {
        path: ORGANIZATIONS_ROUTE,
        name: EOrganizationRoutes.ORGANIZATION_HOME,
        component: OrganizationView,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permissions: [
                EOrganizationsPerm.ORGANIZATIONS_VIEW,
                EOrganizationsUsersPerm.ORGANIZATIONS_USERS_VIEW,
                EOrganizationsNetworksPerm.ORGANIZATIONS_NETWORKS_VIEW,
                EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_EDIT
            ]
        }
    },

    {
        path: ORGANIZATIONS_USERS_ROUTES.detailUrl,
        name: EOrganizationUsersRoutes.ORGANIZATION_USERS_DETAIL,
        component: OrganizationUsersDetailVue,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_VIEW
        }
    },
    {
        path: ORGANIZATIONS_USERS_ROUTES.createUrl,
        name: EOrganizationUsersRoutes.ORGANIZATION_USERS_CREATE,
        component: OrganizationUsersCreateVue,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_EDIT
        }
    },
    {
        path: ORGANIZATIONS_NETWORKS_ROUTES.inviteUrl,
        name: EOrganizationNetworksRoutes.ORGANIZATION_NETWORKS_DETAIL,
        component: OrganizationNetworkLink,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization'))
        }
    },
    {
        path: ORGANIZATIONS_NETWORKS_ROUTES.generateUrl,
        name: EOrganizationNetworksRoutes.ORGANIZATION_NETWORKS_GENERATE,
        component: OrganizationInvitations,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization'))
        }
    },

    {
        path: ORGANIZATIONS_ROLES_ROUTES.createUrl,
        name: EOrganizationRolesRoutes.ORGANIZATION_ROLES_CREATE,
        component: RolesCreate,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_EDIT
        }
    },
    {
        path: ORGANIZATIONS_ROLES_ROUTES.detailUrl,
        name: EOrganizationRolesRoutes.ORGANIZATION_ROLES_DETAIL,
        component: RolesUpdate,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_EDIT
        }
    },

    {
        path: ORGANIZATIONS_APIS_ROUTES.createUrl,
        name: EOrganizationApisRoutes.ORGANIZATION_APIS_CREATE,
        component: ApiCreate,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsApisPerm.ORGANIZATIONS_APIS_EDIT
        }
    },
    {
        path: ORGANIZATIONS_APIS_ROUTES.detailUrl,
        name: EOrganizationApisRoutes.ORGANIZATION_APIS_DETAIL,
        component: ApiEdit,
        meta: {
            title: ref(i18n.global.t('organization.title.myOrganization')),
            permission: EOrganizationsApisPerm.ORGANIZATIONS_APIS_VIEW
        }
    }
])

export default organizationRouting
