<script setup lang="ts">
import { BaseCard } from '@/components/Cards'
import { StackLayout } from '@/components/Layouts'
import TitleTypography from '@/components/Typography/TitleTypography.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import { EColors } from '@/types/constants/ColorValues'
import { type NetworkInviteModel } from '@/types/models/NetworkModel'
import { computed, onMounted } from 'vue'
import store from '../../../../store'
import { ENetworkActions } from '../../../../store/networksStore/NetworkStateType'
import { EStoreModules } from '../../../../store/storeType'
import BaseButton from '../../../Buttons/BaseButton.vue'
import OrganizationCustomerCard from './components/OrganizationNetworkCard.vue'

type Props = {
    data: NetworkInviteModel[]
    total?: number
    currentPage: number
}
const props = defineProps<Props>()
type Emits = {
    (e: 'getSentInvites', page: number): void
}
const emit = defineEmits<Emits>()

const handleShowMore = () => emit('getSentInvites', props.currentPage + 1)

onMounted(() => emit('getSentInvites', props.currentPage))

const me = computed(() => store.state.authentication.me)

const handleDecline = (uuid: string) =>
    store
        .dispatch(`${EStoreModules.NETWORK}/${ENetworkActions.DECLINE_NETWORK_INVITE}`, {
            uuid,
            email: me.value.email
        })
        .then(() => emit('getSentInvites', 1))
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <TitleTypography
            :title="$t('organization.networks.invites.sentInvites')"
            :type="EColors.PRIMARY" />
        <StackLayout v-if="data?.length" :gap="8" class="networks-list">
            <OrganizationCustomerCard
                v-for="(item, key) in data"
                :key="key"
                :prefix="$t('common.to')"
                :label="item.recipient_name"
                class="user-card">
                <template #footer>
                    <BaseButton
                        :label="$t('organization.networks.invites.removeInvite')"
                        :type="EColors.DANGER"
                        class="full-width"
                        @click="handleDecline(item.uuid)" />
                </template>
            </OrganizationCustomerCard>
            <BaseCard
                v-if="data.length < (total ?? 0)"
                @click="handleShowMore"
                class="network-card">
                <template #content>
                    <TypographyItem :label="$t('common.viewMore')" />
                </template>
            </BaseCard>
        </StackLayout>
        <TypographyItem v-else :label="$t('organization.networks.invites.noSentInviteCurrently')" />
    </StackLayout>
</template>

<style scoped lang="scss">
.network-card {
    border-radius: 100px;
    align-self: center;
    height: 100px;
    width: 100px;
    cursor: pointer;
    & :deep(.card-content) {
        text-align: center;
    }
}
.user-avatar {
    margin: auto;
}
.networks-list {
    flex-flow: wrap;
}
.user-card {
    & :deep(.card-header) {
        height: 36px;
    }
    & :deep(.card-content) {
        margin-top: 8px;
    }
}
</style>
