<script setup lang="ts">
import HrefCreateButton from '@/components/Buttons/HrefCreateButton.vue'
import { FullTableLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import { moment } from '@/locales/langUtils'
import {
    ADMIN_APIS_ROUTES,
    ADMIN_ORGANIZATIONS_ROUTES
} from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EApiActions } from '@/store/apiStore/ApiStateType'
import { EStoreModules } from '@/store/storeType'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import { EStatus, EStatusLabels } from '@/types/enum/StatusEnum'
import { defaultAdminApiFiltersType, type AdminApiFiltersType } from '@/types/filters/AdminFilters'
import type { ApiModel } from '@/types/models/ApiModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { DATE_FORMAT } from '@/utils/dateUtils'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import ClickableTypography from '../../../components/Typography/ClickableTypography.vue'
import AdminApiFilters from './components/AdminApiFilters.vue'

const { t } = useI18n()
const router = useRouter()

const columns = computed(() => ({
    id: { field: 'id', label: t('api.model.id') },
    name: { field: 'name', label: t('api.model.name') },
    active: { field: 'active', label: t('api.model.active') },
    expiration_date: { field: 'expiration_date', label: t('api.model.expirationDate') },
    organization_id: { field: 'organization_id', label: t('api.model.organizationName') }
}))

const data = computed(() => store.state.api)
const filters = ref<AdminApiFiltersType>({ ...defaultAdminApiFiltersType, ...getQueryFilters() })
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getApis = () =>
    store.dispatch(`${EStoreModules.API}/${EApiActions.FETCH_APIS}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: data.value.per_page,
        page: data.value.current_page
    })

const goToApiCreate = () => router.push(ADMIN_APIS_ROUTES.createUrl)
const goToApiDetail = (api: ApiModel) => router.push(ADMIN_APIS_ROUTES.buildUrl(api.id))
const deleteApi = (api: ApiModel) =>
    store
        .dispatch(`${EStoreModules.API}/${EApiActions.DELETE_API}`, { id: api.id, name: api.name })
        .then(() => getApis())

const handleChangePageSize = (value: number) => {
    data.value.current_page = 1
    data.value.per_page = value
    getApis()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getApis()
}
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getApis()
}
const handleChangeFilters = (value: Partial<AdminApiFiltersType>) => {
    filters.value = { ...filters.value, ...value }
    data.value.current_page = 1
    getApis()
}

const table = ref<any>([])
</script>

<template>
    <PermissionWrapper :permission="EAdminPerm.ADMIN_APIS">
        <HrefCreateButton
            :href="ADMIN_APIS_ROUTES.createUrl"
            :label="$t('api.buttons.createApi')"
            @click="goToApiCreate" />
    </PermissionWrapper>
    <FullTableLayout :sortBy="sort" @changeSortBy="handleChangeSortBy">
        <template #filters>
            <AdminApiFilters :filters="filters" @change="handleChangeFilters" />
        </template>

        <template #table>
            <BaseTable v-bind:ref="table" :data="data.data.apis" :loading="data.isLoading">
                <template #columns>
                    <TableColumn field="id" :label="columns['id'].label" />
                    <TableColumn field="name" :label="columns['name'].label" />
                    <TableColumn :label="columns['active'].label" v-slot="props">
                        {{
                            $t(EStatusLabels[props.row.active ? EStatus.ENABLED : EStatus.DISABLED])
                        }}
                    </TableColumn>
                    <TableColumn :label="columns['expiration_date'].label" v-slot="props">
                        {{
                            props.row.expiration_date
                                ? moment(props.row.expiration_date).format(DATE_FORMAT).toString()
                                : $t('common.undefined')
                        }}
                    </TableColumn>
                    <TableColumn :label="columns['organization_id']?.label" v-slot="props">
                        <ClickableTypography
                            v-if="props.row.organization_id"
                            :label="props.row.organization_name ?? props.row.organization_id"
                            :href="
                                ADMIN_ORGANIZATIONS_ROUTES.buildUrl(props.row.organization_id)
                            " />
                    </TableColumn>
                    <TableActionColumn
                        @goTo="goToApiDetail($event)"
                        @delete="deleteApi"
                        :editPermission="EAdminPerm.ADMIN_APIS"
                        :deletePermission="EAdminPerm.ADMIN_APIS"
                        :editButtonHref="ADMIN_APIS_ROUTES.baseUrl" />
                </template>
            </BaseTable>
        </template>
        <template #pagination>
            <TablePagination
                :perPage="data.per_page"
                :currentPage="data.current_page"
                :lastPage="data.last_page"
                :total="data.total"
                @change:pageSize="handleChangePageSize"
                @change:pageNumber="handleChangePageNumber" />
        </template>
    </FullTableLayout>
</template>
