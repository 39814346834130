import type { PaginationDataType } from '@/types/PaginationType'
import type { ApiModel } from '@/types/models/ApiModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import type { RoleModel } from '@/types/models/RoleModel'
import type { UserModel } from '@/types/models/UserModel'
import type { NetworkModel } from '../../types/models/NetworkModel'

export type OrganizationState = {
    organization: OrganizationModel
    myOrganization: OrganizationModel
    organizations: PaginationDataType<OrganizationModel[]>
    users: PaginationDataType<UserModel[]>
    devices: PaginationDataType<DeviceModel[]>
    roles: PaginationDataType<RoleModel[]>
    apis: PaginationDataType<ApiModel[]>
    networks: PaginationDataType<NetworkModel[]>
    isLoading: boolean
}

export enum EOrganizationActions {
    FETCH_ORGANIZATION = 'fetchOrganization',
    FETCH_ORGANIZATIONS = 'fetchOrganizations',
    CREATE_ORGANIZATION = 'createOrganization',
    UPDATE_ORGANIZATION = 'updateOrganization',
    DELETE_ORGANIZATION = 'deleteOrganization',

    CREATE_USER = 'createUser',
    CREATE_API = 'createApi',
    INIT_ORGANIZATION_WITH_USER = 'initOrganizationWithUser',

    ONBOARDING = 'onboarding',
    GET_ONBOARDING = 'getOnboarding',
    VALIDATE_ONBOARDING = 'validateOnboarding',

    FETCH_MY_ORGANIZATION = 'fetchMyOrganization',
    FETCH_MY_DEVICES = 'fetchMyDevices',

    FETCH_ORGANIZATION_USERS = 'fetchOrganizationUsers',

    FETCH_ORGANIZATION_DEVICES = 'fetchOrganizationDevices',
    SET_DEVICE_IN_DEVICES = 'setDeviceInDevices',

    FETCH_ORGANIZATION_ROLES = 'fetchOrganizationRoles',
    FETCH_ORGANIZATION_APIS = 'fetchOrganizationApis',

    ATTACH_USER_TO_ORGANIZATION = 'attachUserToOrganization',
    DETACH_USER_TO_ORGANIZATION = 'detachUserFromOrganization',

    ATTACH_DEVICE_TO_ORGANIZATION = 'attachDeviceToOrganization',
    DETACH_DEVICE_TO_ORGANIZATION = 'detachDeviceFromOrganization'
}

export enum EOrganizationMutations {
    SET_ORGANIZATION = 'setOrganization',
    SET_MY_ORGANIZATION = 'setMyOrganization',
    SET_ORGANIZATIONS = 'setOrganizations',
    SET_USERS = 'setUsers',

    SET_DEVICES = 'setDevices',
    SET_DEVICE_IN_DEVICES = 'setDeviceInDevices',

    SET_ROLES = 'setRoles',
    SET_APIS = 'setApis',
    SET_LOADING = 'setLoading'
}
