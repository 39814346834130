import type { DevicesLocales } from '@/types/lang/locales'

export const devicesEn: DevicesLocales = {
    actions: {
        reassignCheckpoint: 'Assign to a checkpoint',
        attachDeviceToOrganization: 'Attach to a client',
        detachDeviceToOrganization: 'Detach from a client',
        changeDeviceType: 'Change device type',
        commands: {
            arguments: 'Arguments',
            availableCommands: 'Available commands',
            commandConfig: "Command's arguments {command}",
            commandTitle: 'Send a command',
            createdAt: 'Create at',
            lastUpdate: 'Last update',
            from: 'From',
            historyText: 'Commands history',
            noAvailableCommand: 'Aucune commande disponible',
            sendCommand: 'Send command {command}'
        },
        rdrChannels: {
            rdrChannelsText:
                "This device's RFID channel is already used on this checkpoint. Would youalso like to switch channel ?"
        }
    },
    api: {
        error: {
            createDevice: 'An error occured while creating the device {name}',
            deleteDevice: 'An error occured while deleting the device {name}',
            fetchDevice: 'An error occured while fetching the device',
            fetchDevices: 'An error occured while fetching the devices',
            updateDevice: 'An error occured while updating the device {name}'
        },
        success: {
            createDevice: 'Device {name} successfully created !',
            deleteDevice: 'Device {name} successfully deleted !',
            fetchDevice: 'Device successfully fetched',
            fetchDevices: 'Devices successfully fetched',
            updateDevice: 'Device {name} successfully updated !'
        }
    },
    buttons: {
        hideAdvanced: 'Hide advanced configuration',
        showAdvanced: 'Show advanced configuration'
    },
    configuration: {
        errors: {
            rangeError: 'Value should be between {min} and {max}',
            excludedError: "Value can't be {excluded}"
        },
        name: 'Device name',

        unknownKey: 'Unknown key',
        undefinedDescription: 'Undefined description',

        hasModificationsContent: 'Configuration has modifications :',
        hasModificationsConfirmation: 'Do you want to save ?',
        hasErrorContent: 'These fields have errors :',
        hasErrorConfirmation: 'Please correct the errors before saving'
    },
    createDevice: 'Create a device',
    detail: {
        informations: {
            actions: 'Actions',
            informationsTitle: 'Information',
            latestDetections: 'Reads',
            localization: 'Localization'
        },
        configuration: {
            configurationTitle: 'Configuration',
            connectivityTitle: 'Connectivity',
            miscTitle: 'Misc'
        },
        detections: {
            detectionsTitle: 'Reads',
            table: {
                bib: 'Bib',
                checkpoint: 'Checkpoint',
                timestamp: 'Date',
                device: 'Device',
                event: 'Event',
                event_filter: 'Event filter',
                timer_filter: 'Timer filter'
            },
            liveDetections: 'Live reads',
            pastDetections: 'Previous reads'
        },
        documents: {
            documentsTitle: 'Documents'
        },
        history: {
            historyTitle: 'Configuration history',
            filters: {
                name: 'Key name'
            }
        },
        informationsTitle: 'Information'
    },
    filters: {
        checkpoint: 'Checkpoint',
        event: 'Event',
        lowBattery: 'Low battery',
        lowSignal: 'Low signal',
        name: 'Name',
        nameOrSerial: 'Name or serial number'
    },
    latestDetections: 'Reads',
    model: {
        apiKey: 'API key',
        checkpointName: 'Checkpoint associated',
        description: 'Description',
        deviceName: 'Device name',
        deviceType: 'Device type',
        eventName: 'Event associated',
        firmwareUpdated: 'Firmware updated',
        firmwareVersion: 'Firmware version',
        imeiSAT: 'Imei SAT',
        lastMaintenanceDate: 'Last maintenance date',
        manufactureDate: 'Manufacture date',
        organizationName: 'Owner',
        serialNumber: 'Serial number',
        simIccid: 'Sim ICCID',

        config_status: 'Configuration awaiting synchronization',
        config_synced_at: 'Last configuration synchronization'
    },
    noDetectionFound: 'No detection found',
    rental: {
        rentalApiSuccess: 'Rental successfully created !',
        rentalApiError: 'Error while creating a rental',
        action: 'Rent device',
        renterName: 'Rent to',
        title: 'Rent device to an other organization',
        rentToOrganization: 'Rent to this organization',
        startAt: 'Rental start',
        endAt: 'Rental end'
    },
    status: {
        online: 'Online',
        offline: 'Offline',
        lowBattery: 'Low battery',
        lowSignal: 'Medium signal',
        warning: 'Warning',

        lastBatteryLevel: 'Last battery level known : {battery}',
        lastBatteryLevelUnknown: 'Dernier niveau de batterie inconnu'
    },
    table: {
        alerts: 'Alertes',
        createDevice: 'Create a device',
        deviceType: 'Device type',
        name: 'Nom',
        currentEventTitle: 'Event associated'
    },
    title: {
        checkpointTitle: 'Checkpoint',
        eventTitle: 'Event',
        checkpointAssociatedTitle: 'Checkpoint associated',
        currentEventTitle: 'Event associated',
        noEventAssociated: 'No associated event'
    }
}
