export const deviceConfigFr = {
    api: {
        error: {
            createDeviceConfig:
                'Erreur lors de la création de la configuration des appareils {name} ',
            deleteDeviceConfig:
                'Erreur lors de la suppression de la configuration des appareils {name} ',
            fetchDeviceConfig: 'Erreur lors de la récupération de la configuration des appareils',
            fetchDeviceConfigs: 'Erreur lors de la récupération des configurations des appareilss',
            updateDeviceConfig:
                'Erreur lors de la modification de la configuration des appareils {name}',

            createDeviceTemplateKey:
                'Erreur lors de la création de la clé de configuration {name} ',
            deleteDeviceTemplateKey:
                'Erreur lors de la suppression de la clé de configuration {name} ',
            fetchDeviceTemplateKey: 'Erreur lors de la récupération de la clé de configuration',
            fetchDeviceTemplateKeys: 'Erreur lors de la récupération des clés de configuration',
            updateDeviceTemplateKey:
                'Erreur lors de la modification de la clé de configuration {name}'
        },
        success: {
            createDeviceConfig:
                'Création de la configuration des appareils {name} effectuée avec succès !',
            deleteDeviceConfig:
                'Suppression de la configuration des appareils {name} effectuée avec succès !',
            fetchDeviceConfig:
                'Récupération de la configuration des appareils effectuée avec succès !',
            fetchDeviceConfigs:
                'Récupération des configurations des appareils effectuée avec succès !',
            updateDeviceConfig:
                'Modification(s) de la configuration des appareils {name} effectuée(s) avec succès !',

            createDeviceTemplateKey:
                'Création de la clé de configuration {name} effectué avec succès',
            deleteDeviceTemplateKey:
                'Suppression de la clé de configuration {name} effectué avec succès',
            fetchDeviceTemplateKey: 'Récupération de la clé de configuration effectué avec succès',
            fetchDeviceTemplateKeys: 'Récupération des clés de configuration effectué avec succès',
            updateDeviceTemplateKey:
                'Modification de la clé de configuration {name} effectué avec succès'
        }
    },
    tabs: {
        configurations: 'Configurations',
        templateKeys: 'Clés de configuration',
        types: "Types d'appareils",
        firmwares: 'Firmwares'
    },
    firmware: {
        buttons: {
            createFirmware: 'Créer un firmware',
            uploadFile: 'Uploader un fichier',
            replaceFile: 'Remplacer le fichier'
        },
        model: {
            now: 'Maintenant',
            later: 'Plus tard',
            undefined: 'Non défini',
            name: 'Nom',
            description: 'Description',
            channel: 'Canal',
            canDowngrade: 'Peut être downgradée',
            releaseAt: 'Date de publication'
        },
        title: {
            informations: 'Informations'
        },
        table: {
            channel: 'Canal',
            deviceTypeName: "Type d'appareil",
            download: 'Téléchargement',
            name: 'Nom',
            releaseAt: 'Date de publication'
        }
    },
    types: {
        buttons: {
            createType: "Créer un type d'appareil",
            createNewType: "Créer ce type d'appareil",
            updateType: 'Enregistrer les modifications'
        },
        model: {
            name: 'Nom'
        },
        table: {
            name: 'Nom'
        },
        title: {
            informations: 'Informations'
        }
    },
    template: {
        buttons: {
            createTemplate: 'Créer un template',
            updateTemplate: 'Enregistrer les modifications'
        },
        model: {
            deviceType: "Type d'appareil",
            version: 'N° de version'
        },
        table: {
            deviceTypeName: "Type d'appareil",
            version: 'Version'
        },
        title: {
            informations: 'Informations',
            newKey: 'Nouvelle clé',
            templateKeys: 'Clés et configuration'
        }
    },
    templateKeys: {
        buttons: {
            addNewExcluded: 'Ajouter une valeur',
            addNewValue: 'Ajouter une valeur',
            addNewPair: 'Ajouter une paire',
            createKey: 'Créer une clé',
            createNewKey: 'Créer et ajouter cette nouvelle clé',
            updateKey: 'Mettre à jour la clé'
        },
        detail: {
            addKey: 'Ajouter une clé existante'
        },
        model: {
            category: 'Catégorie',
            default: 'Valeur par défaut',
            description: 'Description',
            excluded: 'Valeurs exclues',
            isAdmin: 'Uniquement pour les administrateurs',
            isAdvanced: 'Configuration avancée',
            key: 'Clé',
            masked: 'Champ afficher/cacher',
            range: 'Plage de valeurs',
            readOnly: 'Lecture seule',
            translation: "Nom d'affichage",
            type: 'Type',
            unit: 'Unité',
            values: 'Valeurs',
            enumValues: "Valeurs de l'enum",
            enumLabels: 'Labels associés'
        },
        table: {
            key: 'Nom de la clé',
            description: 'Description',
            isAdmin: 'Uniquement pour les admins'
        },
        title: {
            newKey: 'Nouvelle clé',
            editKey: 'Editer la clé'
        }
    }
}
