<script setup lang="ts">
import { FullTableLayout, StackLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import type { PaginationDataType } from '@/types/PaginationType'
import type { TableEmits } from '@/types/components/CommonTableTypes'
import { ESortBy } from '@/types/enum/SortByEnum'
import type { RoleModel } from '@/types/models/RoleModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import HrefCreateButton from '../Buttons/HrefCreateButton.vue'

type Props = {
    data: PaginationDataType<RoleModel[]>
    loading?: boolean
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums
    createButtonHref?: string
    editButtonHref?: string
    forcePropsCurrentPage?: boolean
}
type Emits = TableEmits<RoleModel>

defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const columns = computed(() => ({
    id: { field: 'id', label: t('role.table.id') },
    name: { field: 'name', label: t('role.table.name') }
}))

const sort = ref(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const goToCreate = () => emit('goToCreate')
const goToDetail = (role: RoleModel) => emit('goToDetail', role)

const handleChangePageSize = (pageSize: number) => emit('changePageSize', pageSize)
const handleChangePageNumber = (pageNumber: number) => emit('changePageNumber', pageNumber)

const table = ref<any>([])
</script>

<template>
    <StackLayout direction="column" :gap="12">
        <PermissionWrapper v-if="createButtonHref" :permission="editPermission">
            <HrefCreateButton
                :href="createButtonHref"
                :label="$t('role.buttons.createRole')"
                @click="goToCreate" />
        </PermissionWrapper>
        <FullTableLayout :sortBy="sort" @changeSortBy="$emit('changeSortBy', $event)">
            <template #filters>
                <slot name="filters" />
            </template>

            <template #table>
                <BaseTable v-bind:ref="table" :data="data.data" :loading="data.isLoading">
                    <template #columns>
                        <TableColumn field="id" :label="columns['id'].label" />
                        <TableColumn field="name" :label="columns['name'].label" />
                        <TableActionColumn
                            @goTo="goToDetail"
                            :editPermission="editPermission"
                            :editButtonHref="editButtonHref"
                            @delete="$emit('delete', $event)"
                            :deletePermission="deletePermission" />
                    </template>
                </BaseTable>
            </template>
            <template #pagination>
                <TablePagination
                    :perPage="data.per_page"
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :total="data.total"
                    :forcePropsCurrentPage="forcePropsCurrentPage"
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
