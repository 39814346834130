<script setup lang="ts">
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DropZoneData } from '@/types/components/DropZoneData'
import {
    ERdrChannel,
    ERdrChannelLabels,
    hasAvailableRdrChannel,
    RdrChannelKey
} from '@/types/enum/RdrChannelEnum'
import type { FFDragEvent } from '@/types/Firefox/DragEvent'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import { computed, ref, watch } from 'vue'
import RadioButton from '../../../../../components/Buttons/RadioButton.vue'
import StackLayout from '../../../../../components/Layouts/StackLayout.vue'

type Props = {
    dropZoneData?: DropZoneData
    checkpoint?: CheckpointModel
}
const props = defineProps<Props>()
type Emits = {
    (e: 'close'): void
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const dropZoneRef = ref<HTMLDivElement>()

const localDevice = ref<AssociatedDeviceModel>()

watch(
    () => props.dropZoneData?.device,
    newValue => (newValue ? (localDevice.value = newValue) : undefined)
)

watch(
    () => props.dropZoneData?.position,
    newPosition => {
        hovering.value = ''
        if (!newPosition) return
        if (props.dropZoneData?.checkpoint?.id === props.checkpoint?.id) return emit('close')
        const rect = dropZoneRef.value?.getBoundingClientRect()
        if (
            rect &&
            newPosition.x > rect.left &&
            newPosition.x < rect.right &&
            newPosition.y > rect.top &&
            newPosition.y < rect.bottom
        )
            return (open.value = true)
        emit('close')
    }
)

const handlePointerEnter = (event: DragEvent) => {
    if (event.dataTransfer) event.dataTransfer.dropEffect = 'move'
    hovering.value = 'dropzone-success'
}
const handlePointerLeave = (event: FFDragEvent) => {
    const rect = dropZoneRef.value?.getBoundingClientRect()
    if (!rect) return
    if (
        (event._ffix_cx ?? event.x) > rect?.left &&
        (event._ffix_cx ?? event.x) < rect.right &&
        (event._ffix_cy ?? event.y) > rect.top &&
        (event._ffix_cy ?? event.y) < rect.bottom
    )
        return
    hovering.value = ''
}

const open = ref(false)
const handleConfirm = () => {
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.UPDATE_API_DEVICE}`, {
            deviceId: localDevice.value?.device_id ?? localDevice.value?.id,
            deviceData: {
                checkpoint_id: props.checkpoint?.id,
                ...(selectedRdrChannel.value
                    ? { config: { [RdrChannelKey]: selectedRdrChannel.value } }
                    : {})
            }
        })
        .then(() => {
            emit('refresh')
            handleClose()
        })
}

const handleClose = () => {
    open.value = false
    localDevice.value = undefined
    hovering.value = ''
    emit('close')
}
const hovering = ref('')

const selectedRdrChannel = ref<ERdrChannel | undefined>()
const availableRdrChannels = computed(() =>
    localDevice.value && props.checkpoint?.associates
        ? hasAvailableRdrChannel(localDevice.value, props.checkpoint?.associates)
        : undefined
)
</script>

<template>
    <div
        ref="dropZoneRef"
        @dragover.prevent
        @dragenter="handlePointerEnter"
        @dragleave="handlePointerLeave"
        @dragend="handlePointerLeave"
        class="flex full-height full-width color-text checkpoint-dropdown drop-zone"
        v-bind="$attrs">
        <slot :class="`${!!dropZoneData?.device ? 'dropzone-card' : ''} ${hovering}`" />
    </div>
    <BaseDialog
        :title="$t('checkpoint.actions.reassignDeviceTitle')"
        :open="open"
        @confirm="handleConfirm"
        @close="handleClose"
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <StackLayout direction="column" :gap="8">
                <TypographyItem
                    :label="
                        $t('checkpoint.actions.reassignDevice', {
                            device: localDevice?.name,
                            checkpoint: checkpoint?.name
                        })
                    " />
                <template v-if="availableRdrChannels && availableRdrChannels?.length">
                    <StackLayout direction="column" :gap="8">
                        <TypographyItem :label="$t('device.actions.rdrChannels.rdrChannelsText')" />
                        <RadioButton
                            :modelValue="selectedRdrChannel"
                            @change="selectedRdrChannel = $event"
                            :options="
                                availableRdrChannels?.map(value => ({
                                    name: $t(ERdrChannelLabels[value]?.label, { channel: value }),
                                    value
                                }))
                            " />
                    </StackLayout>
                </template>
            </StackLayout>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.dropzone-card {
    & :deep(.card-header) {
        pointer-events: none;
    }
    & :deep(.card-content) {
        pointer-events: none;
    }
    & :deep(.card-footer) {
        pointer-events: none;
    }
}
</style>
