<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import { localFallback } from '@/locales/langUtils'
import partnerApi from '@/services/partners.service'
import { type EventModel } from '@/types/models/EventModel'
import type { ConfigType } from '@/types/models/PartnerConfigModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core'
import { onMounted, ref } from 'vue'
import TypedInput from '../../Fields/TypedInput.vue'

type Props = {
    partnerId?: number
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', value: Partial<EventModel>): void
}
const emit = defineEmits<Emits>()

const getPartners = (name?: string) =>
    partnerApi
        .getPartners(name ? { filters: { name } } : undefined)
        .then(res => (partnersOptions.value = res.data))
        .finally(() => (isLoadingEvents.value = false))
onMounted(() => getPartners())

const selectedPartner = ref<PartnerModel>()
const partnersOptions = ref<PartnerModel[]>([])
const handleSelect = (partner: PartnerModel) => {
    if (!partner || !partner?.id) return
    selectedPartner.value = partner
    if (props.partnerId === partner?.id) return emit('update', { partner_id: partner?.id })
    emit('update', { partner_id: partner?.id, partner_config: null })
}

const handleChange = (value: string) => {
    isLoadingEvents.value = true
    debouncedQuestion(value)
}
const isLoadingEvents = ref(false)
const debouncedQuestion = useDebounceFn(
    (value: string) => getPartners(value),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <StackLayout direction="column" :gap="12">
        <AutocompleteField
            :label="$t('event.model.partnerName')"
            :options="partnersOptions"
            @typing="handleChange"
            @select="handleSelect($event as PartnerModel)"
            :clearable="false"
            class="full-width" />
        <StackLayout v-if="selectedPartner?.config && !Array.isArray(selectedPartner?.config)">
            <TypedInput
                v-for="([itemKey, itemValue], key) in Object.entries(selectedPartner.config?.event)"
                :key="key"
                :label="
                    (itemValue as ConfigType)?.label
                        ? localFallback((itemValue as ConfigType)?.label) ?? itemKey
                        : itemKey
                "
                :dataType="(itemValue as ConfigType)?.type ?? (itemValue as string)"
                @change="$emit('update', { partner_config: { [itemKey]: $event } })"
                class="full-width" />
        </StackLayout>
    </StackLayout>
</template>
