import { CRM_PREFIX } from '@/config/api'
import crm from '@/helpers/crm'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { FilterType } from '@/types/filters/FilterType'
import type { NetworkInviteModel, NetworkModel } from '@/types/models/NetworkModel'
import { buildQueryUrl } from '@/utils/queryUtils'

const API_EVENT_PREFIX = `${CRM_PREFIX}/networks`
const API_PENDING_PREFIX = `${CRM_PREFIX}/pending-networks`

const networkApi = {
    getMyNetworks: (
        params?: Partial<PaginationType & FilterType<Partial<NetworkModel>>>,
        noQueryParams?: boolean
    ): Promise<PaginationDataType<NetworkModel[]>> =>
        crm
            .get(`${API_EVENT_PREFIX}${buildQueryUrl(params, noQueryParams)}`)
            .then(response => response.data),

    getPendingInvites: (
        params: Partial<PaginationType>
    ): Promise<PaginationDataType<NetworkInviteModel[]>> =>
        crm
            .get(`${API_PENDING_PREFIX}/sent${buildQueryUrl(params)}`)
            .then(response => response.data),
    getReceivedInvites: (
        params: Partial<PaginationType>
    ): Promise<PaginationDataType<NetworkInviteModel[]>> =>
        crm
            .get(`${API_PENDING_PREFIX}/received${buildQueryUrl(params)}`)
            .then(response => response.data),

    sendNetworkInvite: (email: string) =>
        crm.post(`${API_EVENT_PREFIX}/invite`, { email }).then(response => response.data),

    declineNetworkInvite: (email: string, uuid: string) =>
        crm
            .post(`${API_EVENT_PREFIX}/reject${buildQueryUrl({ uuid })}`, { email })
            .then(response => response.data),
    validateNetworkInvite: (email: string, uuid: string) =>
        crm
            .post(`${API_EVENT_PREFIX}/validate${buildQueryUrl({ uuid })}`, { email })
            .then(response => response.data),

    getInvite: (uuid: string) =>
        crm.get(`${API_PENDING_PREFIX}${buildQueryUrl({ uuid })}`).then(response => response.data),

    deleteNetwork: (id: number) => crm.delete(`${API_EVENT_PREFIX}/${id}`)
}

export default networkApi
