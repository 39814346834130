import { DEVICE_PREFIX, MULTIPART_FORMDATA_REQUEST } from '@/config/api'
import device from '@/helpers/device'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { FilterType } from '@/types/filters/FilterType'
import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { DeviceTypesModel } from '@/types/models/DeviceTypesModel'
import { buildQueryUrl } from '@/utils/queryUtils'
import type { AxiosResponse } from 'axios'
import type { DeviceFirmwaresModel } from '../types/models/DeviceFirmwaresModel'

const DEVICE_CONFIG_TYPES_PREFIX = `${DEVICE_PREFIX}/device-config-types`
const DEVICE_CONFIG_TEMPLATE_PREFIX = `${DEVICE_PREFIX}/template-keys`
const DEVICE_TYPES_PREFIX = `${DEVICE_PREFIX}/device-types`
const DEVICE_SERVICE_PREFIX = `${DEVICE_PREFIX}/devices`
const FIRMWARES_PREFIX = `${DEVICE_PREFIX}/versions`

const deviceConfigApi = {
    // DEVICE CONFIG TYPE
    getDeviceConfig: (id: string): Promise<AxiosResponse<DeviceConfigModel>> =>
        device.get(`${DEVICE_CONFIG_TYPES_PREFIX}/${id}`),
    getDeviceConfigs: (
        params: Partial<PaginationType & FilterType<any>>
    ): Promise<PaginationDataType<DeviceConfigModel[]>> =>
        device
            .get(`${DEVICE_CONFIG_TYPES_PREFIX}${buildQueryUrl(params)}`)
            .then(response => response.data),
    createDeviceConfig: (deviceConfigData: any) =>
        device.post(DEVICE_CONFIG_TYPES_PREFIX, deviceConfigData),
    updateDeviceConfig: (
        id: string,
        deviceConfigData: Partial<DeviceConfigModel>
    ): Promise<AxiosResponse<DeviceConfigModel>> =>
        device.patch(`${DEVICE_CONFIG_TYPES_PREFIX}/${id}`, deviceConfigData),
    deleteDeviceConfig: (id: number) => device.delete(`${DEVICE_CONFIG_TYPES_PREFIX}/${id}`),

    // DEVICE TEMPLATE KEYS
    getDeviceTemplateKey: (id: string): Promise<AxiosResponse<DeviceTemplateKeysModel>> =>
        device.get(`${DEVICE_CONFIG_TEMPLATE_PREFIX}/${id}`),
    getAllDeviceTemplateKeys: (
        params?: Partial<PaginationType & FilterType<any>>
    ): Promise<AxiosResponse<DeviceTemplateKeysModel[]>> =>
        device
            .get(`${DEVICE_CONFIG_TEMPLATE_PREFIX}${buildQueryUrl({ per_page: -1, ...params })}`)
            .then(response => response),
    getDeviceTemplateKeys: (
        params?: Partial<PaginationType & FilterType<any>>
    ): Promise<PaginationDataType<DeviceTemplateKeysModel[]>> =>
        device
            .get(`${DEVICE_CONFIG_TEMPLATE_PREFIX}${buildQueryUrl(params)}`)
            .then(response => response.data),
    createDeviceTemplateKeys: (deviceTemplateKeysData: any) =>
        device.post(DEVICE_CONFIG_TEMPLATE_PREFIX, deviceTemplateKeysData),
    updateDeviceTemplateKeys: (
        id: string,
        deviceTemplateKeysData: DeviceTemplateKeysModel
    ): Promise<AxiosResponse<DeviceTemplateKeysModel>> =>
        device.patch(`${DEVICE_CONFIG_TEMPLATE_PREFIX}/${id}`, deviceTemplateKeysData),
    deleteDeviceTemplateKeys: (id: number) =>
        device.delete(`${DEVICE_CONFIG_TEMPLATE_PREFIX}/${id}`),

    // DEVICE TYPES
    getDeviceType: (id: string): Promise<AxiosResponse<DeviceTypesModel>> =>
        device.get(`${DEVICE_TYPES_PREFIX}/${id}`),
    getDeviceTypes: (
        params: Partial<PaginationType & FilterType<any>>
    ): Promise<PaginationDataType<DeviceTypesModel[]> | DeviceTypesModel[]> =>
        device
            .get(`${DEVICE_TYPES_PREFIX}${buildQueryUrl(params)}`)
            .then(response => response.data),
    createDeviceTypes: (deviceTypesData: any) => device.post(DEVICE_TYPES_PREFIX, deviceTypesData),
    updateDeviceTypes: (
        id: string,
        deviceTypesData: Partial<DeviceTypesModel>
    ): Promise<AxiosResponse<DeviceTypesModel>> =>
        device.patch(`${DEVICE_TYPES_PREFIX}/${id}`, deviceTypesData),
    deleteDeviceTypes: (id: number) => device.delete(`${DEVICE_TYPES_PREFIX}/${id}`),

    // FIRMWARES
    getDeviceFirmware: (id: string): Promise<AxiosResponse<DeviceFirmwaresModel>> =>
        device.get(`${FIRMWARES_PREFIX}/${id}`),
    getDeviceFirmwares: (
        params: Partial<PaginationType & FilterType<any>>
    ): Promise<PaginationDataType<DeviceFirmwaresModel[]>> =>
        device.get(`${FIRMWARES_PREFIX}${buildQueryUrl(params)}`).then(response => response.data),
    createDeviceFirmwares: (firmwaresData: any) =>
        device.post(FIRMWARES_PREFIX, firmwaresData, MULTIPART_FORMDATA_REQUEST),
    updateDeviceFirmwares: (
        id: string,
        firmwaresData: Partial<DeviceFirmwaresModel>
    ): Promise<AxiosResponse<DeviceFirmwaresModel>> =>
        device.post(`${FIRMWARES_PREFIX}/${id}`, firmwaresData),
    deleteDeviceFirmwares: (id: number) => device.delete(`${FIRMWARES_PREFIX}/${id}`),

    ///device/api/v1/device-types/4/config
    getDeviceTypeConfig: (id: number): Promise<AxiosResponse<DeviceConfigModel>> =>
        device.get(`${DEVICE_TYPES_PREFIX}/${id}/config`),
    getDeviceTypeConfigByVersion: (id: number): Promise<AxiosResponse<DeviceConfigModel>> =>
        device.get(`${DEVICE_SERVICE_PREFIX}/${id}/configType`)
}

export default deviceConfigApi
