<script setup lang="ts">
import { DeviceTablePage } from '@/components/DeviceComponents'
import { DEVICE_ROUTES } from '@/router/routes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { FilterTagModel } from '@/types/components/FilterTagModel'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import {
    type AdvancedDeviceFilters,
    type DeviceFiltersType,
    type LocalStorageFilter
} from '@/types/filters/DeviceFilters'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import type { DeviceModel } from '../../types/models/DeviceModel'
import DevicesFilters from './components/DevicesFilters.vue'
import DeviceDetailView from './DeviceDetailView.vue'

const data = computed(() => store.state.organization.devices)

const getCurrentEventId = getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event?.id
const filters = ref<DeviceFiltersType & AdvancedDeviceFilters>({
    event_id: getCurrentEventId ? parseInt(getCurrentEventId.toString()) : undefined,
    ...getQueryFilters()
})
const advancedFilters = ref<AdvancedDeviceFilters>({})

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getDevices = () =>
    store.dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_MY_DEVICES}`, {
        filters: {
            event_id: getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event?.id,
            ...filters.value,
            ...advancedFilters.value
        },
        sort: getSortByKey(sort.value),
        page: data.value.current_page,
        per_page: data.value.per_page
    })

const handleChangeFilters = (value: Partial<DeviceFiltersType & AdvancedDeviceFilters>) => {
    filters.value = { ...filters.value, ...value }
    data.value.current_page = 1
    getDevices()
}
const handleAdvancedFilters = (value: Partial<DeviceFiltersType & AdvancedDeviceFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getDevices()
}
const handleChangePageNumber = (pageNumber: number) => {
    data.value.current_page = pageNumber
    getDevices()
}

const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDevices()
}

const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    data.value.current_page = 1
    getDevices()
}

const handleRemoveTag = (value: FilterTagModel) => {
    filters.value = { ...filters.value, [value.name]: undefined }
    advancedFilters.value = { ...advancedFilters.value, [value.name]: undefined }
    data.value.current_page = 1
}

const handleDeviceInTable = (value: DeviceModel) =>
    store.dispatch(`${EStoreModules.DEVICE}/${EOrganizationActions.SET_DEVICE_IN_DEVICES}`, value)
</script>

<template>
    <DeviceTablePage
        :data="data"
        :filters="filters"
        :advancedFilters="advancedFilters"
        :sortBy="sort"
        :loading="data.isLoading"
        showNextEvent
        showNextCheckpoint
        :editPermission="EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW"
        :deletePermission="EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_DELETE"
        :editButtonHref="DEVICE_ROUTES.baseUrl"
        hrefKey="device_id"
        detailKey="device_id"
        @changePageSize="handleChangePageSize"
        @changePageNumber="handleChangePageNumber"
        @changeFilters="handleChangeFilters"
        @changeAdvancedFilters="handleAdvancedFilters"
        @removeTag="handleRemoveTag"
        @changeSortBy="handleChangeSortBy"
        @search="handleSearch"
        @refresh="handleDeviceInTable">
        <template #filters>
            <DevicesFilters :filters="filters" @change="handleChangeFilters" />
        </template>
        <template #outlookMode="props">
            <DeviceDetailView :device="props.device" isOutlookMode class="full-width" />
        </template>
    </DeviceTablePage>
</template>
