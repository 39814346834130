<script setup lang="ts">
import { ADMIN_FIRMWARES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceFirmwaresCreateModel } from '@/types/models/DeviceFirmwaresCreateModel'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { EAdminPerm } from '../../../../types/permissions/AdminPermissionsEnum'
import DeviceFirmwareView from './components/DeviceFirmwareView.vue'

const router = useRouter()
const { t } = useI18n()

const data = ref<Partial<DeviceFirmwaresCreateModel>>()
const file = ref<File | undefined>()

const updateDevice = (value: Partial<DeviceFirmwaresCreateModel>) =>
    (data.value = { ...data.value, ...value })

const create = () => {
    const formData = new FormData()
    formData.append('data', JSON.stringify(data.value))

    if (file.value) {
        const blobData = new Blob([file.value], { type: file.value.type })
        formData.append('file', blobData)
    }

    store
        .dispatch(
            `${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.CREATE_DEVICE_FIRMWARE}`,
            formData
        )
        .then(res => router.replace(ADMIN_FIRMWARES_ROUTES.buildUrl(res.id)))
}
</script>

<template>
    <DeviceFirmwareView
        :data="data"
        @change="updateDevice"
        @uploadFile="file = $event as File"
        :buttonLabel="$t('common.create')"
        :uploadLabel="$t('deviceConfig.firmware.buttons.uploadFile')"
        :file="file"
        @confirm="create"
        :permission="EAdminPerm.ADMIN_DEVICES_CONFIG"
        hasPermission />
</template>
