import api from '@/helpers/api'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import { EVENT_CHECKPOINT_ROUTES } from '../router/routes'
import type { FilterType } from '../types/filters/FilterType'
import type { PaginationType } from '../types/PaginationType'
import { buildQueryUrl } from '../utils/queryUtils'

const API_EVENT_PREFIX = (eventId: number) => EVENT_CHECKPOINT_ROUTES.buildUrl(eventId)

const checkpointApi = {
    addCheckpointToEvent: (eventId: number, checkpointData: Partial<CheckpointModel>) =>
        api.post(`${API_EVENT_PREFIX(eventId)}`, checkpointData),
    deleteCheckpointFromEvent: (eventId: number, checkpointId: number) =>
        api.delete(`${API_EVENT_PREFIX(eventId)}/${checkpointId}`),
    updateCheckpointFromEvent: (
        eventId: number,
        checkpointId: number,
        checkpointData: Partial<CheckpointModel>
    ) => api.patch(`${API_EVENT_PREFIX(eventId)}/${checkpointId}`, checkpointData),

    getCheckpointsFromEvent: (
        eventId: number,
        params?: Partial<PaginationType> & Partial<FilterType<Partial<CheckpointModel>>>,
        noQueryParam?: boolean
    ) =>
        api
            .get(`${API_EVENT_PREFIX(eventId)}${buildQueryUrl(params, noQueryParam)}`)
            .then(res => res.data),
    getSpecificCheckpointFromEvent: (eventId: number, checkpointId: number) =>
        api.get(`${API_EVENT_PREFIX(eventId)}/${checkpointId}`),
    associateDeviceToCheckpoint: (eventId: number, checkpointId: number, device_id: number) =>
        api.post(`${API_EVENT_PREFIX(eventId)}/${checkpointId}/associate`, { device_id }),
    unassociateDeviceFromCheckpoint: (eventId: number, checkpointId: number, device_id: number) =>
        api.post(`${API_EVENT_PREFIX(eventId)}/${checkpointId}/unassociate`, { device_id }),

    eraseDetectionsFromCheckpoint: (eventId: number, checkpointId: number) =>
        api.delete(`${API_EVENT_PREFIX(eventId)}/${checkpointId}/detections`)
}

export default checkpointApi
