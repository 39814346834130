<script setup lang="ts">
import ApiDetailView from '@/components/Api/ApiDetailView.vue'
import { ADMIN_ORGANIZATION_API_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultApiModel, type ApiModel } from '@/types/models/ApiModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const submitted = ref(false)
const isLoading = computed(() => store.state.user.isLoading)
const organizationId = ref(route.params.organization_id?.toString() ?? route.params.id?.toString())
const apiValue = ref<ApiModel>({ ...defaultApiModel })

const updateUserValue = (value: Partial<ApiModel>) =>
    (apiValue.value = { ...apiValue.value, ...value })

const createUser = () => {
    submitted.value = true
    if (!apiValue.value.name) return
    store
        .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.CREATE_API}`, {
            data: apiValue.value,
            id: organizationId.value
        })
        .then(res => {
            submitted.value = false
            router.replace(
                ADMIN_ORGANIZATION_API_ROUTES(parseInt(organizationId.value)).buildUrl(res.id)
            )
        })
}
</script>

<template>
    <ApiDetailView
        :data="apiValue"
        :buttonLabel="$t('common.create')"
        :editPermission="EAdminPerm.ADMIN_ORGANIZATIONS"
        hasPermission
        isCreateMode
        :loading="isLoading"
        @update="updateUserValue"
        @click="createUser" />
</template>
