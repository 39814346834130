<script setup lang="ts">
import { EColors } from '@/types/constants/ColorValues'
import { computed } from 'vue'

type Props = {
    value?: boolean
    label?: string
    error?: boolean
}

const props = defineProps<Props>()
type Emits = {
    (e: 'click', value: boolean): void
}
const emit = defineEmits<Emits>()

const localValue = computed({
    get: () => props.value,
    set: (value: boolean) => emit('click', value)
})
</script>

<template>
    <b-checkbox
        v-model="localValue"
        :type="error ? `is-${EColors.DANGER}` : undefined"
        v-bind="$attrs">
        {{ label }}
    </b-checkbox>
</template>
