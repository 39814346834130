<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import { FullTableLayout } from '@/components/Layouts'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import {
    ADMIN_ORGANIZATIONS_ROUTE,
    ADMIN_ORGANIZATIONS_ROUTES
} from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import {
    defaultAdminOrganizationFiltersType,
    type AdminOrganizationFiltersType
} from '@/types/filters/AdminFilters'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import AdminOrganizationFilters from './components/AdminOrganizationFilters.vue'

const { t } = useI18n()
const router = useRouter()

const columns = computed(() => ({
    id: { field: 'id', label: t('organization.admin.table.id') },
    name: { field: 'name', label: t('organization.admin.table.name') }
}))

const data = computed(() => store.state.organization)
const filters = ref<AdminOrganizationFiltersType>({
    ...defaultAdminOrganizationFiltersType,
    ...getQueryFilters()
})
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getOrganizations = () =>
    store.dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_ORGANIZATIONS}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: data.value.organizations.per_page,
        page: data.value.organizations.current_page
    })

const goToOrganizationDetail = (organization: OrganizationModel) =>
    router.push(`${ADMIN_ORGANIZATIONS_ROUTE}/${organization.id}`)
const goToOrganizationCreate = () => router.push(`${ADMIN_ORGANIZATIONS_ROUTE}/create`)
const deleteOrganization = (
    organization: OrganizationModel // TODO correction
) =>
    store
        .dispatch(
            `${EStoreModules.ORGANIZATION}/${EOrganizationActions.DELETE_ORGANIZATION}`,
            organization
        )
        .then(() => getOrganizations())

const handleChangePageSize = () => {
    data.value.organizations.per_page = store.state.pagination.per_page
    data.value.organizations.current_page = 1
    getOrganizations()
}
const handleChangePageNumber = (value: number) => {
    data.value.organizations.current_page = value
    getOrganizations()
}
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getOrganizations()
}
const handleChangeFilters = (value: Partial<AdminOrganizationFiltersType>) => {
    filters.value = { ...filters.value, ...value }
    data.value.organizations.current_page = 1
    getOrganizations()
}

const table = ref<any>([])
</script>

<template>
    <CreateButton
        :loading="!!data.isLoading"
        :label="$t('organization.admin.table.createOrganization')"
        @click="goToOrganizationCreate" />
    <FullTableLayout :sortBy="sort" @changeSortBy="handleChangeSortBy">
        <template #filters>
            <AdminOrganizationFilters :filters="filters" @change="handleChangeFilters" />
        </template>

        <template #table>
            <BaseTable v-bind:ref="table" :data="data.organizations.data" :loading="data.isLoading">
                <template #columns>
                    <TableColumn field="id" :label="columns['id'].label" />
                    <TableColumn field="name" :label="columns['name'].label" />
                    <TableActionColumn
                        titleItemKey="name"
                        @goTo="goToOrganizationDetail"
                        @delete="deleteOrganization"
                        :editButtonHref="ADMIN_ORGANIZATIONS_ROUTES.baseUrl" />
                </template>
            </BaseTable>
        </template>
        <template #pagination>
            <TablePagination
                :perPage="data.organizations.per_page"
                :currentPage="data.organizations.current_page"
                :lastPage="data.organizations.last_page"
                :total="data.organizations.total"
                @change:pageSize="handleChangePageSize"
                @change:pageNumber="handleChangePageNumber" />
        </template>

        <template #dialogFilters:title></template>
        <template #dialogFilters:content></template>
        <template #dialogFilters:footer></template>
    </FullTableLayout>
</template>
