import { EColors } from '../constants/ColorValues'

export enum ECommandStatus {
    TO_SEND = 0, //
    ACCEPTED = 1, // command accepted, will be executed soon
    RUNNING = 2, // command running
    RETRY_LATER = 3, // server must send the command later.
    FINISHED_OK = 4, // command sucessfully executed
    FINISHED_ERROR = 5
}

export const ECommandStatusValues = [
    ECommandStatus.TO_SEND,
    ECommandStatus.ACCEPTED,
    ECommandStatus.RUNNING,
    ECommandStatus.RETRY_LATER,
    ECommandStatus.FINISHED_OK,
    ECommandStatus.FINISHED_ERROR
]

export const ECommandStatusLabels: Record<ECommandStatus, string> = {
    [ECommandStatus.TO_SEND]: 'enum.commandStatus.toSend',
    [ECommandStatus.ACCEPTED]: 'enum.commandStatus.accepted',
    [ECommandStatus.RUNNING]: 'enum.commandStatus.running',
    [ECommandStatus.RETRY_LATER]: 'enum.commandStatus.retryLater',

    [ECommandStatus.FINISHED_OK]: 'enum.commandStatus.finishedOk',
    [ECommandStatus.FINISHED_ERROR]: 'enum.commandStatus.finishedError'
}

export const ECommandStatusColors: Record<ECommandStatus, EColors> = {
    [ECommandStatus.TO_SEND]: EColors.SUCCESS,
    [ECommandStatus.ACCEPTED]: EColors.INFO,
    [ECommandStatus.RUNNING]: EColors.INFO,
    [ECommandStatus.RETRY_LATER]: EColors.WARNING,
    [ECommandStatus.FINISHED_OK]: EColors.SUCCESS,
    [ECommandStatus.FINISHED_ERROR]: EColors.DANGER
}
