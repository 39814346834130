import type { NetworkModel } from '@/types/models/NetworkModel'

export type NetworkState = {
    network?: NetworkModel
    networks: NetworkModel[]
}

export enum ENetworkActions {
    FETCH_NETWORKS = 'fetchNetworks',
    FETCH_PENDING_INVITES = 'fetchPendingInvites',
    FETCH_RECEIVED_INVITES = 'fetchReceivedInvites',
    SEND_NETWORK_INVITE = 'sendNetworkInvite',

    FETCH_INVITE = 'fetchInvite',

    DECLINE_NETWORK_INVITE = 'declineNetworkInvite',
    VALIDATE_NETWORK_INVITE = 'validateNetworkInvite',

    DELETE_NETWORK = 'deleteNetwork'
}

export enum ENetworkMutations {
    SET_NETWORKS = 'setNetworks',
    SET_NETWORK = 'setNetwork',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
