import type { ApiLocales } from '@/types/lang/locales'

export const apiEn: ApiLocales = {
    api: {
        error: {
            createApi: 'An error occured while creating the API {name}',
            deleteApi: 'An error occured while deleting the API {name}',
            fetchApi: 'An error occured while fetching the API',
            fetchApis: 'An error occured while fetching the APIs',
            updateApi: 'An error occured while updating the API {name}'
        },
        success: {
            createApi: 'API {name} successfully created !',
            deleteApi: 'API {name} successfully deleted !',
            updateApi: 'API {name} successfully edited !'
        }
    },
    informationsTitle: 'Information',
    buttons: {
        createApi: 'Create an API'
    },
    filters: {
        name: 'Name',
        expirationDateFrom: 'Expiration date after',
        expirationDateTo: 'Expiration date before',
        onlyDisabled: 'Only disabled',
        onlyEnabled: 'Only enabled'
    },
    model: {
        active: 'Status',
        apiKey: 'API Key',
        expirationDate: 'Expiration date',
        id: 'ID',
        name: 'Name',
        organizationName: 'Organization name'
    }
}
