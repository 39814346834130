<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { BaseTable, TableColumn } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import TableStripableDetailRow from '@/components/Table/TableStripableDetailRow.vue'
import { ClickableTypography, TypographyItem } from '@/components/Typography'
import TypographyWithPrefix from '@/components/Typography/TypographyWithPrefix.vue'
import { moment } from '@/locales/langUtils'
import { EVENTS_ROUTES } from '@/router/routes'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type { SocketDeviceDetectionModel } from '@/types/socket/SocketDetectionModel'
import { DATE_FORMAT } from '@/utils/dateUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DetectionWrapper from '../../Wrappers/DetectionWrapper.vue'
import DeviceLowSignal from '../Typography/DeviceLowSignal.vue'
import DeviceOnlineStatus from '../Typography/DeviceOnlineStatus.vue'

const { t } = useI18n()

type Props = {
    data: DeviceModel[]
    statuses: AlertSocketMessageModel[]
    detections?: SocketDeviceDetectionModel[]
    loading?: boolean
    deletePermission?: PermissionsEnums
    editPermission?: PermissionsEnums

    editButtonHref?: string
    hrefKey?: string
}

const props = defineProps<Props>()
type Emits = {
    (e: 'click', device: DeviceModel): void
    (e: 'delete', device: DeviceModel): void
}
const emit = defineEmits<Emits>()

const columns = computed(() => ({
    name: { field: 'name', label: t('device.table.name') },
    device_type_name: { field: 'device_type_name', label: t('device.table.deviceType') },
    event: { field: 'event.name', label: t('device.table.currentEventTitle') }
}))

const table = ref<any>([])
const goTo = (device: DeviceModel) => emit('click', device)

const devicesData = computed(() =>
    props.data.map(item => ({
        ...item,
        ...(props.statuses?.find(status => status.device_id === item.device_id) ?? {})
    }))
)
</script>

<template>
    <BaseTable
        v-bind:ref="table"
        :mobile-cards="false"
        :data="devicesData"
        :loading="loading"
        showDetails>
        <template #columns>
            <TableColumn :label="columns['name'].label" v-slot="props">
                <StackLayout direction="column" :gap="2">
                    <ClickableTypography
                        v-if="props.row.name"
                        :label="props.row.name"
                        :href="`${editButtonHref}/${props.row[hrefKey ?? 'id']}`" />
                    <TypographyWithPrefix
                        :prefix="$t('device.model.serialNumber')"
                        :label="props.row.serial_number ?? undefined"
                        boldLabel />
                </StackLayout>
            </TableColumn>
            <TableColumn field="device_type_name" :label="columns['device_type_name'].label" />
            <TableColumn :label="columns['event'].label" v-slot="props">
                <StackLayout v-if="props.row.event" direction="column" :gap="2">
                    <ClickableTypography
                        :label="props.row.event.name"
                        :href="`${EVENTS_ROUTES.buildUrl(props.row.event.id)}`" />
                    <TypographyItem :label="moment(props.row.event.start_at).format(DATE_FORMAT)" />
                </StackLayout>
                <TypographyItem v-else label="" />
            </TableColumn>
            <TableColumn :label="$t('device.table.alerts')" v-slot="props">
                <StackLayout direction="column">
                    <DeviceOnlineStatus :status="parseInt(props.row.status)" />
                    <DeviceLowSignal :lowSignal="props.row.lowSignal" />
                </StackLayout>
            </TableColumn>
            <TableActionColumn
                @goTo="goTo"
                @delete="emit('delete', $event)"
                :deletePermission="deletePermission"
                :editPermission="editPermission"
                :editButtonHref="editButtonHref"
                :hrefKey="hrefKey" />
        </template>
        <template #detail="props">
            <TableStripableDetailRow :props="props">
                <DetectionWrapper
                    :detections="
                        detections?.find(
                            item => item.device_id === (props.row.device_id ?? props.row.id)
                        )?.detections ?? []
                    " />
            </TableStripableDetailRow>
        </template>
    </BaseTable>
</template>
