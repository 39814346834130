<script setup lang="ts">
import HrefCreateButton from '@/components/Buttons/HrefCreateButton.vue'
import FullTableLayout from '@/components/Layouts/FullTableLayout.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import BaseTable from '@/components/Table/BaseTable.vue'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import TableColumn from '@/components/Table/TableColumn.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import { ADMIN_FIRMWARES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ESortBy, ESortByAlphabetical, ESortByDate, getSortByKey } from '@/types/enum/SortByEnum'
import {
    defaultAdminDeviceFiltersType,
    type AdminDeviceFiltersType
} from '@/types/filters/AdminFilters'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { isCurrentPageOverTotal } from '@/utils/paginationUtils'
import { addToQuery, getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import moment from 'moment'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import TypographyItem from '../../../../components/Typography/TypographyItem.vue'
import type { FirmwaresModel } from '../../../../types/models/FirmwaresModel'
import { DATETIME_FORMAT } from '../../../../utils/dateUtils'

type Props = {
    isCurrentTab: boolean
    permission?: PermissionsEnums
    createButtonHref: string
    editButtonHref: string
}
const props = defineProps<Props>()

const router = useRouter()
const { t } = useI18n()
const data = computed(() => store.state.deviceConfig.firmwares)
const filters = ref<AdminDeviceFiltersType>({
    ...defaultAdminDeviceFiltersType,
    ...getQueryFilters()
})
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.DATE_RECENT)

const columns = computed(() => ({
    name: {
        field: 'name',
        label: t('deviceConfig.firmware.table.name')
    },
    device_type_name: {
        field: 'device_type_name',
        label: t('deviceConfig.firmware.table.deviceTypeName')
    },

    channel: {
        field: 'channel',
        label: t('deviceConfig.firmware.table.channel')
    },
    release_at: {
        field: 'release_at',
        label: t('deviceConfig.firmware.table.releaseAt')
    },
    download: {
        field: 'download',
        label: t('deviceConfig.firmware.table.download')
    }
}))

const getDeviceConfigs = () =>
    store.dispatch(
        `${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.FETCH_DEVICE_FIRMWARES}`,
        {
            filters: filters.value,
            sort: getSortByKey(sort.value),
            per_page: data.value.per_page ?? getPageSize(),
            page: props.isCurrentTab ? data.value.current_page : 1
        }
    )

const goToCreate = () => router.push(ADMIN_FIRMWARES_ROUTES.createUrl)

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getDeviceConfigs()
}
const handleChangePageNumber = (pageNumber: number) => {
    data.value.current_page = pageNumber
    getDeviceConfigs()
}
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDeviceConfigs()
}

const deleteDeviceConfig = (device: DeviceModel) => {
    store
        .dispatch(`${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.DELETE_DEVICE_FIRMWARE}`, {
            id: device.id,
            name: device.name
        })
        .then(() =>
            isCurrentPageOverTotal(
                data.value.total - 1,
                data.value.per_page,
                data.value.current_page,
                data.value.last_page
            )
                ? handleChangePageNumber(data.value.current_page - 1)
                : getDeviceConfigs()
        )
}

const table = ref()

watch(
    () => props.isCurrentTab,
    isCurrent => (isCurrent ? addToQuery({ page: data.value.current_page }, true) : null)
)
onBeforeUnmount(() => addToQuery({ page: 1 }, true))

const handleFirmwareDownload = (value: FirmwaresModel) => console.log({ value })
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <PermissionWrapper :permission="permission">
            <HrefCreateButton
                :href="createButtonHref"
                :label="$t('deviceConfig.firmware.buttons.createFirmware')"
                @click="goToCreate"
                class="align-self-end" />
        </PermissionWrapper>

        <FullTableLayout
            isResponsiveSmallScreen
            :orderOptions="[...ESortByAlphabetical, ...ESortByDate]"
            :sortBy="sort"
            @changeSortBy="handleChangeSortBy">
            <template #table>
                <BaseTable v-bind:ref="table" :data="data.data" :loading="data.isLoading">
                    <template #columns>
                        <TableColumn field="name" :label="columns['name'].label" />
                        <TableColumn
                            field="device_type_name"
                            :label="columns['device_type_name'].label" />

                        <TableColumn field="channel" :label="columns['channel'].label" />
                        <TableColumn :label="columns['release_at'].label" v-slot="props">
                            <TypographyItem
                                :label="moment(props.release_at).format(DATETIME_FORMAT)" />
                        </TableColumn>
                        <!-- <TableColumn
                            field="download"
                            :label="columns['download'].label" v-slot="props">
                            <IconButton icon="download" @click="handleFirmwareDownload(props)" />
                        </TableColumn> -->
                        <TableActionColumn
                            @delete="deleteDeviceConfig"
                            :editPermission="permission"
                            :deletePermission="permission"
                            :editButtonHref="editButtonHref" />
                    </template>
                </BaseTable>
            </template>
            <template #pagination>
                <TablePagination
                    :perPage="data.per_page"
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :total="data.total"
                    forcePropsCurrentPage
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>

            <template #dialogContent>
                <slot name="dialogContent" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
