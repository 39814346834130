<script setup lang="ts">
import { DeviceTablePage } from '@/components/DeviceComponents'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { FilterTagModel } from '@/types/components/FilterTagModel'
import type { TabHref } from '@/types/components/TabHref'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import {
    defaultAdminDeviceFiltersType,
    type AdminDeviceFiltersType
} from '@/types/filters/AdminFilters'
import type { AdvancedDeviceFilters, DeviceFiltersType } from '@/types/filters/DeviceFilters'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { isCurrentPageOverTotal } from '@/utils/paginationUtils'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import AdministrationDeviceDetailView from '@/views/AdministrationView/Device/AdministrationDeviceDetailView.vue'
import { computed, provide, ref } from 'vue'
import { useRouter } from 'vue-router'

type Props = {
    organizationId: number
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    buttonsHref?: TabHref
}
const props = defineProps<Props>()

const router = useRouter()
const data = computed(() => store.state.organization.devices)
const filters = ref<AdminDeviceFiltersType>({
    ...defaultAdminDeviceFiltersType,
    ...getQueryFilters()
})
const advancedFilters = ref<AdvancedDeviceFilters>({})

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getDevices = () =>
    store.dispatch(
        `${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_ORGANIZATION_DEVICES}`,
        {
            organizationId: props.organizationId,
            params: {
                filters: filters.value,
                sort: getSortByKey(sort.value),
                per_page: data.value.per_page,
                page: data.value.current_page
            }
        }
    )

const goToDetail = (device: DeviceModel) =>
    router.push(`${props.buttonsHref?.editButtonHref}/${device.id}`)

const handleChangeFilters = (value: Partial<DeviceFiltersType>) => {
    filters.value = { ...filters.value, ...value }
    data.value.current_page = 1
    getDevices()
}
const handleAdvancedFilters = (value: Partial<DeviceFiltersType & AdvancedDeviceFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })
const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getDevices()
}
const handleChangePageNumber = (pageNumber: number) => {
    data.value.current_page = pageNumber
    getDevices()
}

const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDevices()
}

const deleteDevice = (device: DeviceModel) => {
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.DELETE_DEVICE}`, {
            deviceId: device.id,
            name: device.name
        })
        .then(() =>
            isCurrentPageOverTotal(
                data.value.total - 1,
                data.value.per_page,
                data.value.current_page,
                data.value.last_page
            )
                ? handleChangePageNumber(data.value.current_page - 1)
                : getDevices()
        )
}

const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    data.value.current_page = 1
    getDevices()
}

const handleRemoveTag = (value: FilterTagModel) => {
    filters.value = { ...filters.value, [value.name]: undefined }
    advancedFilters.value = { ...advancedFilters.value, [value.name]: undefined }
    data.value.current_page = 1
}

const handleDeviceInTable = (value: DeviceModel) =>
    store.dispatch(
        `${EStoreModules.ORGANIZATION}/${EOrganizationActions.SET_DEVICE_IN_DEVICES}`,
        value
    )

provide('noAddToQuery', true)
</script>

<template>
    <DeviceTablePage
        :data="{ ...data, data: data.data }"
        :filters="filters"
        :advancedFilters="advancedFilters"
        :sortBy="sort"
        :loading="data.isLoading"
        :editPermission="editPermission"
        :deletePermission="deletePermission"
        :createButtonHref="buttonsHref?.createButtonHref"
        :editButtonHref="buttonsHref?.editButtonHref"
        hrefKey="device_id"
        showNextEvent
        showNextCheckpoint
        forcePropsCurrentPage
        @delete="deleteDevice"
        @goToDetail="goToDetail"
        @changePageSize="handleChangePageSize"
        @changePageNumber="handleChangePageNumber"
        @changeFilters="handleChangeFilters"
        @changeAdvancedFilters="handleAdvancedFilters"
        @changeSortBy="handleChangeSortBy"
        @removeTag="handleRemoveTag"
        @search="handleSearch"
        @refresh="handleDeviceInTable">
        <template #outlookMode="props">
            <AdministrationDeviceDetailView :device="props.device" isOutlookMode />
        </template>
    </DeviceTablePage>
</template>
