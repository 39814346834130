<script setup lang="ts">
import { TypographyWithPrefix } from '@/components/Typography'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ref, watch } from 'vue'

type Props = {
    deviceId?: number
}
const props = defineProps<Props>()

const apiKey = ref<string | undefined>()
const getDeviceApiKey = (deviceId: number) =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.GET_DEVICE_API_KEY}`, deviceId)
        .then(res => (apiKey.value = res.api_key))

watch(
    () => props.deviceId,
    (newDeviceId, oldDeviceId) =>
        newDeviceId && oldDeviceId !== newDeviceId ? getDeviceApiKey(newDeviceId) : undefined,
    { immediate: true }
)
</script>

<template>
    <TypographyWithPrefix :prefix="$t('device.model.apiKey')" :label="apiKey" />
</template>
