<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import type { EventModel } from '@/types/models/EventModel'
import { inject, ref, type Ref } from 'vue'
import SecondaryButton from '../../../../components/Buttons/SecondaryButton.vue'
import CollapseItem from '../../../../components/Collapse/CollapseItem.vue'
import CheckboxField from '../../../../components/Fields/CheckboxField.vue'
import NumberInputField from '../../../../components/Fields/NumberInputField.vue'
import GridLayout from '../../../../components/Layouts/GridLayout.vue'
import StackLayout from '../../../../components/Layouts/StackLayout.vue'
import { EDeviceActions } from '../../../../store/deviceStore/DeviceStoreTypes'
import { EEventActions } from '../../../../store/eventStore/EventStoreTypes'
import {
    EFiltersDevice,
    EFiltersDeviceKeysValues,
    EFiltersDevicesLabels
} from '../../../../types/enum/FiltersDeviceEnum'
import type { DeviceModel } from '../../../../types/models/DeviceModel'

type Props = {
    data: EventModel
}
const props = defineProps<Props>()

type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const open = ref(false)

const filtersData = ref<Record<string, number>>({})

const handleConfirm = (affectToDevice?: boolean) =>
    store
        .dispatch(`${EStoreModules.EVENT}/${EEventActions.SET_EVENT_FILTERS}`, {
            eventId: props.data.id,
            data: { ...props.data, data: { ...(props.data.data ?? {}), ...filtersData.value } },
            name: props.data.name
        })
        .then(() => {
            affectToDevice
                ? store
                      .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.MASS_UPDATE_DEVICE}`, {
                          deviceIds: selectedDevices.value,
                          config: filtersData.value
                      })
                      .then(() => emit('refresh'))
                : emit('refresh')
            open.value = false
        })

const devices = inject<Ref<DeviceModel[]>>('devices', ref([]))

const selectedDevices = ref<number[]>([])
const handleDeviceSelect = (device: DeviceModel, checked: boolean) => {
    checked
        ? (selectedDevices.value = [...selectedDevices.value, device.device_id ?? device.id])
        : (selectedDevices.value = selectedDevices.value.filter(item =>
              device.device_id ? item !== device.device_id : item !== device.id
          ))
}

const handleAllSelect = (checked: boolean) => {
    checked
        ? (selectedDevices.value = devices.value.map(device => device.device_id ?? device.id))
        : (selectedDevices.value = [])
}
</script>

<template>
    <PrimaryButton :label="$t('event.actions.affectFiltersTitle')" @click="open = true" />
    <BaseDialog
        :title="$t('event.actions.affectFiltersTitle')"
        :open="open"
        @confirm="handleConfirm"
        @close="open = false"
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <StackLayout direction="column" :gap="8">
                <CollapseItem :open="true" withIcon class="collapse-item" index="1">
                    <template #title>
                        <TypographyItem :label="$t('event.actions.affectFilters')" />
                    </template>
                    <template #content>
                        <StackLayout
                            direction="column"
                            v-for="(filterKey, keyItem) in EFiltersDevice"
                            :key="keyItem"
                            :gap="8">
                            <TypographyItem :label="filterKey.toUpperCase()" />
                            <StackLayout :gap="16">
                                <NumberInputField
                                    v-for="(item, key) in EFiltersDeviceKeysValues"
                                    :key="key"
                                    :label="EFiltersDevicesLabels(filterKey, item)"
                                    :modelValue="filtersData[`${filterKey}${item}`]"
                                    @change="
                                        filtersData[`${filterKey}${item}`] = $event ?? undefined
                                    " />
                            </StackLayout>
                        </StackLayout>
                    </template>
                </CollapseItem>
                <CollapseItem withIcon class="collapse-item" index="2">
                    <template #title>
                        <TypographyItem :label="$t('event.actions.affectDevices')" />
                    </template>
                    <template #content>
                        <StackLayout direction="column" :gap="8">
                            <CheckboxField
                                :value="
                                    devices.every(item =>
                                        selectedDevices.find(device =>
                                            item.device_id
                                                ? device === item.device_id
                                                : device === item.id
                                        )
                                    )
                                "
                                label="All"
                                @click="handleAllSelect($event)" />

                            <GridLayout :rowGap="8" class="device-checkbox-grid">
                                <CheckboxField
                                    v-for="(device, index) in devices"
                                    :key="index"
                                    :label="device?.name"
                                    :value="selectedDevices.includes(device.device_id ?? device.id)"
                                    @click="handleDeviceSelect(device, $event)"
                                    class="device-checkbox" />
                            </GridLayout>
                        </StackLayout>
                    </template>
                </CollapseItem>
            </StackLayout>
        </template>
        <template #actions>
            <SecondaryButton
                :label="$t('event.actions.affectFiltersDevice')"
                @click="handleConfirm(true)" />
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.collapse-item {
    & :deep(.collapse-title) {
        width: 100%;
        place-content: space-between;
        box-shadow: 0px 0px 3px 1px $primary;
        padding: 4px;
    }
    & :deep(.collapse-content) {
        padding: 8px;
    }
}

.device-checkbox-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.device-checkbox {
    white-space: nowrap;
}
</style>
