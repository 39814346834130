<script setup lang="ts">
import { BaseTable, TableColumn } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import TagStack from '@/components/Tags/TagStack.vue'
import { TypographyItem } from '@/components/Typography'
import type { UserModel } from '@/types/models/UserModel'
import { EOrganizationsUsersPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type Props = {
    data: UserModel[]
    titleItemKey?: string
    loading?: boolean
    editButtonHref?: string
}
defineProps<Props>()
type Emits = {
    (e: 'click', user: UserModel): void
    (e: 'delete', user: UserModel): void
}
const emit = defineEmits<Emits>()

const columns = computed(() => ({
    id: { field: 'id', label: t('organization.organizations.table.id') },
    name: { field: 'name', label: t('organization.organizations.table.name') },
    email: { field: 'email', label: t('organization.organizations.table.email') },
    roles: { field: 'roles', label: t('organization.organizations.table.roles') }
}))

const goTo = (user: UserModel) => emit('click', user)
const deleteRow = (user: UserModel) => emit('delete', user)

const table = ref<any>([])
</script>

<template>
    <BaseTable v-bind:ref="table" :data="data ?? []" :loading="loading">
        <template #columns>
            <TableColumn :label="columns['id'].label" field="id" />
            <TableColumn :label="columns['name'].label" field="name" />
            <TableColumn :label="columns['email'].label" field="email" />
            <TableColumn :label="columns['roles'].label" field="email" v-slot="props">
                <TagStack
                    v-if="props.row.roles?.length"
                    :data="props.row.roles?.map((item: string) => ({ name: item }))"
                    :closable="false"
                    class="tag-stack" />
                <TypographyItem v-else :label="$t('common.noRole')" />
            </TableColumn>
            <TableActionColumn
                :titleItemKey="titleItemKey"
                @goTo="goTo"
                @delete="deleteRow"
                :deletePermission="EOrganizationsUsersPerm.ORGANIZATIONS_USERS_DELETE"
                :editButtonHref="editButtonHref" />
        </template>
    </BaseTable>
</template>

<style scoped lang="scss">
.tag-stack {
    & :hover {
        background-color: $primary;
    }
}
</style>
