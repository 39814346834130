<script setup lang="ts">
import store from '@/store'
import { ENetworkActions } from '@/store/networksStore/NetworkStateType'
import { EStoreModules } from '@/store/storeType'
import type { NetworkInviteModel } from '@/types/models/NetworkModel'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'
import DividerItem from '../../Divider/DividerItem.vue'
import FullScreenLoader from '../../LoadingSpinner/FullScreenLoader.vue'
import OrganizationCustomersGenerate from './Generate/OrganizationNetworksGenerate.vue'
import OrganizationNetworksReceivedInvites from './Generate/OrganizationNetworksReceivedInvites.vue'
import OrganizationNetworksSentInvites from './Generate/OrganizationNetworksSentInvites.vue'

const isMobileScreen = ref(isMobile())
isMobileEvent(e => (isMobileScreen.value = e.matches))

type PaginatedInvitesData = {
    data: NetworkInviteModel[]
    total?: number
    page: number
}
const receivedInvites = ref<PaginatedInvitesData>({
    data: [],
    total: undefined,
    page: 1
})
const sentInvites = ref<PaginatedInvitesData>({
    data: [],
    total: undefined,
    page: 1
})

const getInvites = (page: number, storeAction: string, dataObject: PaginatedInvitesData) =>
    store
        .dispatch(`${EStoreModules.NETWORK}/${storeAction}`, {
            per_page: 10,
            page
        })
        .then(res => {
            if (res.current_page === 1) dataObject.data = res.data
            else dataObject.data = [...dataObject.data, ...res.data]
            dataObject.total = res.total
            dataObject.page = res.current_page
        })
</script>

<template>
    <FullScreenLoader :isLoading="!!store.state?.network?.isLoading" />
    <OrganizationCustomersGenerate
        @refresh="getInvites(1, ENetworkActions.FETCH_PENDING_INVITES, sentInvites)" />

    <OrganizationNetworksReceivedInvites
        :data="receivedInvites.data"
        :total="receivedInvites.total"
        :currentPage="receivedInvites.page"
        @getReceivedInvites="
            getInvites($event, ENetworkActions.FETCH_RECEIVED_INVITES, receivedInvites)
        " />
    <DividerItem isPrimary />
    <OrganizationNetworksSentInvites
        :data="sentInvites.data"
        :total="sentInvites.total"
        :currentPage="sentInvites.page"
        @getSentInvites="getInvites($event, ENetworkActions.FETCH_PENDING_INVITES, sentInvites)" />
</template>

<style scoped lang="scss">
.qrCode-card {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    max-width: 400px;
}
.friend-code {
    & :deep(.information-card-content) {
        min-height: fit-content;
        & .field.is-floating-label .label::before {
            background-color: var(--color-background-secondary);
        }
    }
    & span {
        overflow-x: auto;
    }
}
.invite-link {
    max-width: 100%;
}
.link-class {
    padding: 4px;
    border-radius: 4px;
    height: fit-content;
    overflow-x: auto;
    &:focus {
        background-color: gray;
    }
    &:focus-within {
        background-color: gray;
    }
}
</style>
