<script setup lang="ts">
import CheckpointAutocomplete from '@/components/Filters/components/CheckpointAutocomplete.vue'
import EventAutocomplete from '@/components/Filters/components/EventAutocomplete.vue'
import checkpointApi from '@/services/checkpoint.service'
import store from '@/store'
import { EFiltersActions } from '@/store/filtersStore/FiltersStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import type { EventModel } from '@/types/models/EventModel'
import { ref, watch } from 'vue'

type Props = EventCheckpointType & {
    keepEventInStorage?: boolean
    filterAllEvents?: boolean
    hideEventSelector?: boolean
    noQueryParam?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Omit<EventCheckpointType, 'event'>, event: PointerEvent): void
    (e: 'selectEvent', value?: EventModel): void
    (e: 'associates', value?: AssociatedDeviceModel[]): void
    (e: 'selectCheckpoint', value?: CheckpointModel): void
}
const emit = defineEmits<Emits>()

const handleSelect = (value: EventCheckpointType, event: PointerEvent) =>
    emit('change', { ...value, device_id: undefined }, event)

const handleSelectEvent = (value: EventModel | undefined, event: PointerEvent) => {
    if (props.keepEventInStorage)
        store.dispatch(
            `${EStoreModules.FILTERS}/${EFiltersActions.SET_FILTER}`,
            value
                ? {
                      event: { id: value.id, name: value.name }
                  }
                : undefined
        )
    handleSelect({ event_id: value?.id, checkpoint_id: undefined }, event)
    checkpointInputValue.value = undefined
    checkpointOptions.value = []
    emit('selectEvent', value)
}
const handleSelectCheckpoint = (value: CheckpointModel | undefined, event: PointerEvent) => {
    handleCheckpointTyping(undefined)
    handleSelect({ checkpoint_id: value?.id }, event)
    emit('selectCheckpoint', value)
    emit('associates', value?.associates)
}

const getCheckpoints = (event_id?: number, name?: string) => {
    if (!event_id) return
    checkpointApi
        .getCheckpointsFromEvent(
            event_id,
            { per_page: -1, filters: name ? { name } : undefined },
            props.noQueryParam
        )
        .then(res => {
            checkpointOptions.value = res.data ?? res
            checkpointInputOptions.value = undefined
            checkpointInputValue.value = undefined
        })
}

const checkpointOptions = ref<CheckpointModel[]>()

const handleCheckpointTyping = (value?: string) => {
    checkpointInputValue.value = value
    checkpointInputOptions.value = checkpointOptions.value?.filter(item =>
        item.name.toLocaleLowerCase().includes(value?.toLowerCase() ?? '')
    )
}
const checkpointInputValue = ref<string>()
const checkpointInputOptions = ref<CheckpointModel[]>()
watch(
    () => props.event,
    newValue => (newValue?.id ? getCheckpoints(newValue?.id) : undefined),
    { immediate: true }
)
</script>

<template>
    <EventAutocomplete
        v-if="!hideEventSelector"
        :event="event"
        :filterAllEvents="filterAllEvents"
        :noQueryParam="noQueryParam"
        @change="(value, event) => handleSelectEvent(value, event)"
        class="full-width" />
    <CheckpointAutocomplete
        :disabled="!event"
        :checkpointOptions="checkpointInputOptions ?? checkpointOptions ?? []"
        :checkpointId="checkpoint?.id"
        :inputValue="checkpointInputValue"
        @typing="handleCheckpointTyping"
        @select="(value, event) => handleSelectCheckpoint(value, event)"
        class="full-width" />
</template>
