export enum EAdminRoutes {
    ADMIN_HOME = 'adminHome'
}
export const EAdminRoutesValues = [EAdminRoutes.ADMIN_HOME]

export enum EAdminDevicesRoutes {
    ADMIN_DEVICES_CREATE = 'adminDevicesCreate',
    ADMIN_DEVICES_DETAIL = 'adminDevicesDetail',
    ADMIN_DEVICES_TABLE = 'adminDevicesTable'
}
export const EAdminDevicesRoutesValues = [
    EAdminDevicesRoutes.ADMIN_DEVICES_CREATE,
    EAdminDevicesRoutes.ADMIN_DEVICES_DETAIL,
    EAdminDevicesRoutes.ADMIN_DEVICES_TABLE
]

export enum EAdminUsersRoutes {
    ADMIN_USERS_CREATE = 'adminUsersCreate',
    ADMIN_USERS_DETAIL = 'adminUsersDetail',
    ADMIN_USERS_TABLE = 'adminUsersTable'
}
export const EAdminUsersRoutesValues = [
    EAdminUsersRoutes.ADMIN_USERS_CREATE,
    EAdminUsersRoutes.ADMIN_USERS_DETAIL,
    EAdminUsersRoutes.ADMIN_USERS_TABLE
]

export enum EAdminApisRoutes {
    ADMIN_APIS_CREATE = 'adminApisCreate',
    ADMIN_APIS_DETAIL = 'adminApisDetail',
    ADMIN_APIS_TABLE = 'adminApisTable'
}
export const EAdminApisRoutesValues = [
    EAdminApisRoutes.ADMIN_APIS_CREATE,
    EAdminApisRoutes.ADMIN_APIS_DETAIL,
    EAdminApisRoutes.ADMIN_APIS_TABLE
]

export enum EAdminRolesRoutes {
    ADMIN_ROLES_CREATE = 'adminRolesCreate',
    ADMIN_ROLES_DETAIL = 'adminRolesDetail',
    ADMIN_ROLES_TABLE = 'adminRolesTable'
}
export const EAdminRolesRoutesValues = [
    EAdminRolesRoutes.ADMIN_ROLES_CREATE,
    EAdminRolesRoutes.ADMIN_ROLES_DETAIL,
    EAdminRolesRoutes.ADMIN_ROLES_TABLE
]

export enum EAdminOrganizations {
    ADMIN_ORGANIZATIONS_CREATE = 'adminOrganizationsCreate',
    ADMIN_ORGANIZATIONS_DETAIL = 'adminOrganizationsDetail',
    ADMIN_ORGANIZATIONS_TABLE = 'adminOrganizationsTable'
}
export const EAdminOrganizationsRoutesValues = [
    EAdminOrganizations.ADMIN_ORGANIZATIONS_CREATE,
    EAdminOrganizations.ADMIN_ORGANIZATIONS_DETAIL,
    EAdminOrganizations.ADMIN_ORGANIZATIONS_TABLE
]

export enum EAdminOrganizationsUsers {
    ADMIN_ORGANIZATIONS_USER_CREATE = 'adminOrganizationsUserCreate',
    ADMIN_ORGANIZATIONS_USER_DETAIL = 'adminOrganizationsUserDetail',
    ADMIN_ORGANIZATIONS_USER_TABLE = 'adminOrganizationsUserTable'
}
export const EAdminOrganizationsUsersRoutesValues = [
    EAdminOrganizationsUsers.ADMIN_ORGANIZATIONS_USER_CREATE,
    EAdminOrganizationsUsers.ADMIN_ORGANIZATIONS_USER_DETAIL,
    EAdminOrganizationsUsers.ADMIN_ORGANIZATIONS_USER_TABLE
]

export enum EAdminOrganizationsApis {
    ADMIN_ORGANIZATIONS_API_CREATE = 'adminOrganizationsApiCreate',
    ADMIN_ORGANIZATIONS_API_DETAIL = 'adminOrganizationsApiDetail',
    ADMIN_ORGANIZATIONS_API_TABLE = 'adminOrganizationsApiTable'
}
export const EAdminOrganizationsApisRoutesValues = [
    EAdminOrganizationsApis.ADMIN_ORGANIZATIONS_API_CREATE,
    EAdminOrganizationsApis.ADMIN_ORGANIZATIONS_API_DETAIL,
    EAdminOrganizationsApis.ADMIN_ORGANIZATIONS_API_TABLE
]

export enum EAdminOrganizationsDevices {
    ADMIN_ORGANIZATIONS_DEVICES_CREATE = 'adminOrganizationsDevicesCreate',
    ADMIN_ORGANIZATIONS_DEVICES_DETAIL = 'adminOrganizationsDevicesDetail',
    ADMIN_ORGANIZATIONS_DEVICES_TABLE = 'adminOrganizationsDevicesTable'
}
export const EAdminOrganizationsDevicesRoutesValues = [
    EAdminOrganizationsDevices.ADMIN_ORGANIZATIONS_DEVICES_CREATE,
    EAdminOrganizationsDevices.ADMIN_ORGANIZATIONS_DEVICES_DETAIL,
    EAdminOrganizationsDevices.ADMIN_ORGANIZATIONS_DEVICES_TABLE
]

export enum EAdminDeviceConfig {
    ADMIN_DEVICE_CONFIG_CREATE = 'adminDeviceConfigCreate',
    ADMIN_DEVICE_CONFIG_DETAIL = 'adminDeviceConfigDetail',
    ADMIN_DEVICE_CONFIG_TABLE = 'adminDeviceConfigTable'
}
export const EAdminDeviceConfigRoutesValues = [
    EAdminDeviceConfig.ADMIN_DEVICE_CONFIG_CREATE,
    EAdminDeviceConfig.ADMIN_DEVICE_CONFIG_DETAIL,
    EAdminDeviceConfig.ADMIN_DEVICE_CONFIG_TABLE
]

export enum EAdminDeviceType {
    ADMIN_DEVICE_TYPE_CREATE = 'adminDeviceTypeCreate',
    ADMIN_DEVICE_TYPE_DETAIL = 'adminDeviceTypeDetail',
    ADMIN_DEVICE_TYPE_TABLE = 'adminDeviceTypeTable'
}
export const EAdminDeviceTypeRoutesValues = [
    EAdminDeviceType.ADMIN_DEVICE_TYPE_CREATE,
    EAdminDeviceType.ADMIN_DEVICE_TYPE_DETAIL,
    EAdminDeviceType.ADMIN_DEVICE_TYPE_TABLE
]

export enum EAdminDeviceTemplate {
    ADMIN_DEVICE_TEMPLATE_CREATE = 'adminDeviceTemplateCreate',
    ADMIN_DEVICE_TEMPLATE_DETAIL = 'adminDeviceTemplateDetail',
    ADMIN_DEVICE_TEMPLATE_TABLE = 'adminDeviceTemplateTable'
}
export const EAdminDeviceTemplateRoutesValues = [
    EAdminDeviceTemplate.ADMIN_DEVICE_TEMPLATE_CREATE,
    EAdminDeviceTemplate.ADMIN_DEVICE_TEMPLATE_DETAIL,
    EAdminDeviceTemplate.ADMIN_DEVICE_TEMPLATE_TABLE
]

export enum EAdminDeviceTemplateKeys {
    ADMIN_DEVICE_TEMPLATE_KEYS_CREATE = 'adminDeviceTemplateKeysCreate',
    ADMIN_DEVICE_TEMPLATE_KEYS_DETAIL = 'adminDeviceTemplateKeysDetail',
    ADMIN_DEVICE_TEMPLATE_KEYS_TABLE = 'adminDeviceTemplateKeysTable'
}
export const EAdminDeviceTemplateKeysRoutesValues = [
    EAdminDeviceTemplateKeys.ADMIN_DEVICE_TEMPLATE_KEYS_CREATE,
    EAdminDeviceTemplateKeys.ADMIN_DEVICE_TEMPLATE_KEYS_DETAIL,
    EAdminDeviceTemplateKeys.ADMIN_DEVICE_TEMPLATE_KEYS_TABLE
]

export enum EAdminDeviceFirmwares {
    ADMIN_DEVICE_FIRMWARES_CREATE = 'adminDeviceFirmwaresCreate',
    ADMIN_DEVICE_FIRMWARES_DETAIL = 'adminDeviceFirmwaresDetail',
    ADMIN_DEVICE_FIRMWARES_TABLE = 'adminDeviceFirmwaresTable'
}
export const EAdminDeviceFirmwaresRoutesValues = [
    EAdminDeviceFirmwares.ADMIN_DEVICE_FIRMWARES_CREATE,
    EAdminDeviceFirmwares.ADMIN_DEVICE_FIRMWARES_DETAIL,
    EAdminDeviceFirmwares.ADMIN_DEVICE_FIRMWARES_TABLE
]
