<script setup lang="ts">
import CreateButton from '@/components/Buttons/CreateButton.vue'
import RadioButton from '@/components/Buttons/RadioButton.vue'
import UploadButton from '@/components/Buttons/UploadButton.vue'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import CheckboxField from '@/components/Fields/CheckboxField.vue'
import DateTimePickerField from '@/components/Fields/DateTimePickerField.vue'
import InputField from '@/components/Fields/InputField.vue'
import MultilineField from '@/components/Fields/MultilineField.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import DeviceTypeSelector from '@/components/Selector/components/DeviceTypeSelector.vue'
import SelectorField from '@/components/Selector/SelectorField.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import type { SelectorObject } from '@/types/components/SelectorObject'
import { EReleaseChannelValues } from '@/types/enum/ReleaseChannelEnum'
import type { DeviceFirmwaresModel } from '@/types/models/DeviceFirmwaresModel'
import moment from 'moment'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
    confirmLabel?: string
    data?: Partial<DeviceFirmwaresModel>
    file?: File
    loading?: boolean
    buttonLabel: string
    uploadLabel?: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DeviceFirmwaresModel>): void
    (e: 'confirm'): void
    (e: 'uploadFile', file: File | File[] | undefined): void
}
const emit = defineEmits<Emits>()

const { t } = useI18n()

const submitted = ref(false)

const releaseDateValue = ref<number>(1)
const releaseDateOptions = computed<SelectorObject[]>(() => [
    {
        name: t('deviceConfig.firmware.model.now'),
        value: 1
    },
    {
        name: t('deviceConfig.firmware.model.later'),
        value: 0
    },
    {
        name: t('deviceConfig.firmware.model.undefined'),
        value: 2
    }
])

const handleReleaseDateValue = (value?: number) => {
    releaseDateValue.value = value ?? 2
}

const handleConfirm = () => {
    submitted.value = true
    if (Object.values(hasErrors.value).filter(item => item).length) return
    submitted.value = false
    if (releaseDateValue.value === 2) emit('change', { release_at: undefined })
    if (releaseDateValue.value === 1) emit('change', { release_at: moment().utc().toDate() })
    emit('confirm')
}
const hasErrors = computed(() => ({
    deviceTypeError: submitted.value && !props.data?.device_type_id,
    nameError: submitted.value && !props.data?.name,
    channelError: submitted.value && !props.data?.channel,
    releaseAtError: submitted.value && releaseDateValue.value === 0 && !props.data?.release_at
}))
</script>

<template>
    <StackLayout :gap="16" direction="column">
        <CreateButton :label="buttonLabel" @click="handleConfirm" />
        <InformationBaseCard>
            <StackLayout direction="column" :gap="16" class="full-width">
                <InputField
                    :label="$t('deviceConfig.firmware.model.name')"
                    :modelValue="data?.name"
                    @change="$emit('change', { name: $event })"
                    required
                    :hasError="hasErrors.nameError"
                    class="full-width" />
                <MultilineField
                    :label="$t('deviceConfig.firmware.model.description')"
                    :modelValue="data?.description"
                    @change="$emit('change', { description: $event })"
                    class="full-width" />
                <StackLayout :gap="8" class="full-width">
                    <DeviceTypeSelector
                        :modelValue="data?.device_type_id"
                        @select="$emit('change', { device_type_id: $event?.id })"
                        required
                        :hasErrors="hasErrors.deviceTypeError" />
                    <SelectorField
                        :label="$t('deviceConfig.firmware.model.channel')"
                        :modelValue="data?.channel"
                        :options="EReleaseChannelValues.map(value => ({ value }))"
                        @change="$emit('change', { channel: $event })"
                        required
                        :hasError="hasErrors.channelError"
                        class="full-width" />
                </StackLayout>
                <StackLayout direction="column" :gap="16" class="full-width">
                    <slot name="releaseDate">
                        <StackLayout :gap="8">
                            <TypographyItem
                                :label="`${$t('deviceConfig.firmware.model.releaseAt')} :`" />
                            <RadioButton
                                :label="$t('deviceConfig.firmware.model.releaseAt')"
                                :modelValue="releaseDateValue"
                                :options="releaseDateOptions"
                                @change="handleReleaseDateValue" />
                        </StackLayout>
                        <DateTimePickerField
                            v-if="!releaseDateValue"
                            :label="$t('deviceConfig.firmware.model.releaseAt')"
                            :modelValue="data?.release_at"
                            @change="
                                $emit('change', {
                                    release_at: $event ? moment($event).utc().toDate() : undefined
                                })
                            "
                            required
                            :hasError="hasErrors.releaseAtError"
                            class="full-width" />
                    </slot>
                </StackLayout>
                <CheckboxField
                    :label="$t('deviceConfig.firmware.model.canDowngrade')"
                    :value="data?.can_downgrade"
                    @click="$emit('change', { can_downgrade: $event })"
                    class="full-width" />
            </StackLayout>
        </InformationBaseCard>
        <InformationBaseCard>
            <StackLayout :gap="8">
                <UploadButton
                    :label="uploadLabel"
                    class="fit-content place-self-end"
                    @change="$emit('uploadFile', $event)" />
                <TypographyItem v-if="file" :label="file.name" class="align-self-center" />
            </StackLayout>
        </InformationBaseCard>
    </StackLayout>
</template>
