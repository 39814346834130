import type { TabWithTablePermissions } from '../components/TabPermissions'

export enum EAdminPerm {
    SUPER_ADMIN = 'super_admin',
    ADMIN_USERS_IMPERSONATE = 'users_impersonate',

    ADMIN_USERS = 'users_admin',
    ADMIN_ORGANIZATIONS = 'organizations_admin',
    ADMIN_DEVICES = 'devices_admin',
    ADMIN_DEVICES_CONFIG = 'devices_config_admin',
    ADMIN_APIS = 'apis_admin',
    ADMIN_ROLES = 'roles_admin',
    ADMIN_PERMISSIONS = 'permissions_admin'
}
export const EAdminOrganizationUsersTabPerm: TabWithTablePermissions = {
    deletePermission: EAdminPerm.ADMIN_ORGANIZATIONS,
    editPermission: EAdminPerm.ADMIN_ORGANIZATIONS,
    viewPermission: EAdminPerm.ADMIN_ORGANIZATIONS
}
export const EAdminOrganizationDevicesTabPerm: TabWithTablePermissions = {
    deletePermission: EAdminPerm.ADMIN_ORGANIZATIONS,
    editPermission: EAdminPerm.ADMIN_ORGANIZATIONS,
    viewPermission: EAdminPerm.ADMIN_ORGANIZATIONS
}
export const EAdminOrganizationNetworksTabPerm: TabWithTablePermissions = {
    deletePermission: EAdminPerm.ADMIN_ORGANIZATIONS,
    editPermission: EAdminPerm.ADMIN_ORGANIZATIONS,
    viewPermission: EAdminPerm.ADMIN_ORGANIZATIONS
}

export const EAdminOrganizationsApisTabPerm: TabWithTablePermissions = {
    deletePermission: EAdminPerm.ADMIN_ORGANIZATIONS,
    editPermission: EAdminPerm.ADMIN_ORGANIZATIONS,
    viewPermission: EAdminPerm.ADMIN_ORGANIZATIONS
}
