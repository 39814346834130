<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import EventCheckpointAutocomplete from '@/components/Filters/components/EventCheckpointAutocomplete.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { computed, ref, watch } from 'vue'
import { ERdrChannel, nextRdrChannel } from '../../../types/enum/RdrChannelEnum'
import type { EventModel } from '../../../types/models/EventModel'
import RadioButton from '../../Buttons/RadioButton.vue'

type Props = {
    device?: Partial<DeviceModel>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh', device: DeviceModel): void
}
const emit = defineEmits<Emits>()

const event = ref(props.device?.event)
const checkpoint = ref(props.device?.checkpoint)

watch(
    () => props.device?.event,
    newEvent => (event.value = newEvent)
)

const handleConfirm = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.UPDATE_API_DEVICE}`, {
            deviceId: props.device?.device_id ?? props.device?.id,
            deviceData: {
                checkpoint_id: checkpoint.value?.id
            }
        })
        .then(res => {
            emit('refresh', res)
            open.value = false
            checkpoint.value = undefined
        })

const handleSelectEvent = (newEvent?: EventModel) => {
    checkpoint.value = undefined
    event.value = newEvent
}

const selectedRdrChannel = ref<ERdrChannel | undefined>()
const availableRdrChannels = computed(() =>
    checkpoint.value?.associates
        ? nextRdrChannel(checkpoint.value.associates)?.availableChannels.map(value => ({ value }))
        : []
)

const open = ref(false)
</script>

<template>
    <PrimaryButton
        @click="open = true"
        :label="$t('device.actions.reassignCheckpoint')"
        v-bind="$attrs" />
    <BaseDialog
        :title="$t('device.actions.reassignCheckpoint')"
        :open="open"
        @confirm="handleConfirm"
        @close="open = false"
        :disabled="!checkpoint"
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <StackLayout :gap="8">
                <EventCheckpointAutocomplete
                    :event="event"
                    :checkpoint="checkpoint"
                    noQueryParam
                    @selectEvent="handleSelectEvent"
                    @selectCheckpoint="checkpoint = $event" />
            </StackLayout>
            <template v-if="!!availableRdrChannels.length">
                <RadioButton :modelValue="selectedRdrChannel" :options="availableRdrChannels" />
            </template>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.event-checkpoint-component {
    & :deep(.dropdown-content) {
        position: fixed;
        display: flex;
        flex-flow: wrap;
    }
}
</style>
