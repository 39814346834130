import { Store, createStore } from 'vuex'
import { type StoreState } from './storeType'

//import modules used
import alert from './alertStore/alert.module'
import api from './apiStore/api.module'
import authentication from './authStore/auth.module'
import checkpoint from './checkpointStore/checkpoint.module'
import detection from './detectionStore/detection.module'
import deviceConfig from './deviceConfigStore/deviceConfig.module'
import device from './deviceStore/device.module'
import event from './eventStore/event.module'
import filters from './filtersStore/filters.module'
import loading from './loadingStore/loading.module'
import network from './networksStore/network.module'
import notification from './notificationStore/notification.module'
import organization from './organizationStore/organization.module'
import pagination from './paginationStore/pagination.module'
import partner from './partnersStore/partners.module'
import permission from './permissionsStore/permissions.module'
import question from './questionStore/question.module'
import role from './roleStore/role.module'
import session from './sessionStore/session.module'
import statistic from './statisticStore/statistic.module'
import technician from './technicianStore/technician.module'
import ticket from './ticketStore/ticket.module'
import topic from './topicStore/topic.module'
import user from './userStore/user.module'
import zone from './zoneStore/zone.module'

const store: Store<StoreState> = createStore({
    modules: {
        alert,
        api,
        authentication,
        checkpoint,
        detection,
        device,
        deviceConfig,
        event,
        filters,
        loading,
        network,
        notification,
        organization,
        pagination,
        partner,
        permission,
        question,
        role,
        session,
        statistic,
        technician,
        ticket,
        user,
        zone,
        topic
    },
    devtools: true
})

export default store
