import type { OrganizationLocales } from '@/types/lang/locales'

export const organizationEn: OrganizationLocales = {
    admin: {
        actions: {
            dissociateDeviceMessage: 'Are you sure you want to dissociate this device from {name} ?'
        },
        detail: {
            actionsTitle: 'Actions',
            informationsTitle: 'Information',
            localizationTitle: 'Localization'
        },
        model: {
            name: 'Name',
            socialReason: 'Social reason',

            street: 'Street',
            zipCode: 'Zip code',
            city: 'City',
            country: 'Country'
        },
        table: {
            id: 'ID',
            name: 'Name',
            createOrganization: 'Add an organization'
        }
    },
    api: {
        error: {
            createOrganization: 'An error occured while creating the organization {name}',
            deleteOrganization: 'An error occured while deleting the organization {name}',
            fetchOrganization: 'An error occured while fetching the organization',
            fetchOrganizations: 'An error occured while fetching the organizations',
            updateOrganization: 'An error occured while updating the organization {name}',

            createUser: 'An error occured while creating the user {name}',
            initOrganizationWithUser:
                'An error occured while creating the user {name} and the organization',

            fetchMyOrganization: 'An error occured while fetching my organization',
            fetchOrganizationUsers: 'An error occured while fetching users of my organization',
            fetchOrganizationApis: 'Erreur lors de la récupération des APIs de mon entreprises',
            attachUserToOrganization: 'An error occured while linking the user to the organization',
            detachUserFromOrganization: 'An error occured while unlinking the organization',
            fetchOrganizationDevices:
                'An error occured while fetching devices from the organization',
            attachDeviceToOrganization:
                'An error occured while linking the device to the organization',
            detachDeviceFromOrganization:
                'An error occured while unlinking the device to the organization',

            onboarding: 'An error occured while requesting access'
        },
        success: {
            createOrganization: 'Organization {name} successfully created !',
            deleteOrganization: 'Organization {name} successfully deleted !',
            fetchOrganization: 'Organization successfully fetched !',
            fetchOrganizations: 'Organizations successfully fetched !',
            updateOrganization: 'Organization {name} successfully edited !',

            createUser: 'User {name} successfully created !',
            initOrganizationWithUser:
                'User {name} successfully created and organization successfully initialized !',

            fetchMyOrganization: 'My organization has been successfully fetched !',
            fetchOrganizationUsers: "Organization's users successfully fetched !",
            fetchOrganizationApis: "Récupération des APIs de l'entreprises effectuée avec succès !",
            attachUserToOrganization: 'User successfully attached to the organization !',
            detachUserFromOrganization: 'User successfully detached from the organization !',
            fetchOrganizationDevices:
                "Organization's devices successfully attached to the organization !",
            attachDeviceToOrganization: 'Device successfully attached to the organization !',
            detachDeviceFromOrganization: 'Device successfully detached from the organization !',

            onboarding: 'Access request successfully done !'
        }
    },
    buttons: {
        associateAnUser: 'Associate an user',
        dissociateAnUser: 'Dissociate an user',
        associateUser: 'Associate user',
        dissociateUser: 'Dissociate user',
        associateDevice: 'Associate device',
        dissociateDevice: 'Dissociate device'
    },
    filters: {
        deactivated: 'Only deactivated users'
    },
    organizations: {
        api: {
            error: {
                updateOrganization: "Erreur lors de la modification de l'entreprise {name}"
            },
            success: {
                updateOrganization:
                    "Modification(s) de l'entreprise {name} effectuée(s) avec succès !"
            }
        },
        buttons: {
            invitations: 'Invitations'
        },
        invite: {
            addFriendCode: 'Add a friend code',
            friendCode: 'Friend code',
            inviteLink: 'Invite link',
            yourFriendCode: 'Your friend code',
            yourLink: 'Your invite link',
            qrCode: 'QR Code'
        },
        table: {
            id: 'ID',
            name: 'Fullname',
            email: 'Email',
            roles: 'Rôles'
        }
    },
    informations: {
        general: 'General'
    },
    model: {
        id: 'ID',
        name: 'Name',
        socialReason: 'Social reason',
        organizationId: 'Organization ID',
        deviceId: 'Device ID',
        userId: 'User ID'
    },
    networks: {
        invitation: {
            invitedBy:
                "{recipient}, you've been invited to join {sender}'s network to allow devices rentals. Would you like to join it ?"
        },
        invites: {
            removeInvite: 'Cancel invitation',
            revokeAccess: 'Remove from partners',
            noReceivedInviteCurrently: 'No received invite currently',
            noSentInviteCurrently: 'No sent invite currently',
            email: "Receiver's email",
            generateInvitation: 'Send an invite',
            receivedInvites: 'Received invitations',
            sentInvites: 'Sent invites',
            declineInvite: 'Decline',
            acceptInvite: 'Accept'
        }
    },
    users: {
        buttons: {
            associateUser: 'Associate an existing user',
            createUser: 'Create an user'
        },
        roles: 'Roles'
    },
    title: {
        myOrganization: 'Organization management',
        associateUserTitle: 'Associate an user to an organization',
        dissociateUserTitle: 'Dissociate a user from an organization',

        associateDeviceTitle: 'Associate a device to an organization',
        dissociateDeviceTitle: 'Dissociate a device from an organization'
    },
    tabs: {
        devicesTitle: 'Devices',
        informationsTitle: 'Information',
        networksTitle: 'Organizations',
        rolesTitle: 'Roles',
        usersTitle: 'Utilisateurs',
        apisTitle: 'Apis'
    }
}
