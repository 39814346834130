import type { DeviceModel } from '../models/DeviceModel'

export const RdrChannelKey = 'rdr_channel'

export enum ERdrChannel {
    FOUR = 4,
    SEVEN = 7,
    TEN = 10,
    THIRTEEN = 13,
    NO = -1
}

export const ERdrChannelValues = [
    ERdrChannel.FOUR,
    ERdrChannel.SEVEN,
    ERdrChannel.TEN,
    ERdrChannel.THIRTEEN
]

export const ERdrChannelLabels = {
    [ERdrChannel.FOUR]: { label: 'enum.rdrChannels.channel', channel: ERdrChannel.FOUR },
    [ERdrChannel.SEVEN]: { label: 'enum.rdrChannels.channel', channel: ERdrChannel.SEVEN },
    [ERdrChannel.TEN]: { label: 'enum.rdrChannels.channel', channel: ERdrChannel.TEN },
    [ERdrChannel.THIRTEEN]: { label: 'enum.rdrChannels.channel', channel: ERdrChannel.THIRTEEN },
    [ERdrChannel.NO]: { label: 'enum.rdrChannels.no' }
}

export const ERdrChannelPairs = {
    [ERdrChannel.FOUR]: [ERdrChannel.TEN, ERdrChannel.THIRTEEN],
    [ERdrChannel.SEVEN]: [ERdrChannel.TEN, ERdrChannel.THIRTEEN],
    [ERdrChannel.TEN]: [ERdrChannel.FOUR, ERdrChannel.SEVEN],
    [ERdrChannel.THIRTEEN]: [ERdrChannel.FOUR, ERdrChannel.SEVEN],
    [ERdrChannel.NO]: []
}

type FilteredRdrChannels = {
    availableChannels: ERdrChannel[]
    usedChannels: ERdrChannel[]
}
export const nextRdrChannel = (devices: Partial<DeviceModel>[]) =>
    ERdrChannelValues.reduce(
        (prev: FilteredRdrChannels, current) =>
            devices.find(item => item.config?.[RdrChannelKey] === current)
                ? {
                      availableChannels: ERdrChannelPairs[current],
                      usedChannels: [...prev.usedChannels, current]
                  }
                : prev,
        { availableChannels: ERdrChannelValues, usedChannels: [] }
    )

export const hasAvailableRdrChannel = (
    device: Partial<DeviceModel>,
    devices: Partial<DeviceModel>[]
) => {
    const channels = nextRdrChannel(devices)
    if (device.config?.[RdrChannelKey])
        return channels.usedChannels.find(item => item === device.config?.[RdrChannelKey])
            ? channels.availableChannels
            : false
    return false
}
