<script setup lang="ts">
import { PrimaryButton } from '@/components/Buttons'
import DateTimePickerField from '@/components/Fields/DateTimePickerField.vue'
import { StackLayout } from '@/components/Layouts'
import { BaseDialog } from '@/components/Modals/Dialog'
import organizationApi from '@/services/organization.service'
import store from '@/store'
import { EAlertActions } from '@/store/alertStore/AlertStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import NetworksSelector from '../../../components/Selector/components/NetworksSelector.vue'
import type { NetworkModel } from '../../../types/models/NetworkModel'

type Props = {
    deviceId: number
}
const props = defineProps<Props>()
const { t } = useI18n()

const organizationId = computed(() => store.state.authentication.me.organization_id)
const open = ref(false)
const selectedOrganization = ref<NetworkModel>()
const date = ref({
    start_at: new Date(),
    end_at: new Date()
})

const confirmRental = () => {
    if (!organizationId.value || !selectedOrganization.value) return
    organizationApi
        .createRental(organizationId.value, {
            ...date.value,
            device_id: props.deviceId,
            renter_id: selectedOrganization.value?.party_id
        })
        .then(() => {
            store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
                action: EAlertActions.SUCCESS,
                message: t('device.rental.rentalApiSuccess')
            })
            open.value = false
            date.value = {
                start_at: new Date(),
                end_at: new Date()
            }
            selectedOrganization.value = undefined
        })
        .catch(() =>
            store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
                action: EAlertActions.SUCCESS,
                message: t('device.rental.rentalApiSuccess')
            })
        )
}
</script>

<template>
    <PrimaryButton :label="$t('device.rental.action')" @click="open = true" v-bind="$attrs" />
    <BaseDialog
        :title="$t('device.rental.title')"
        :open="open"
        @close="open = false"
        @confirm="confirmRental"
        class="dialog-with-dropdown">
        <template #content>
            <StackLayout direction="column" :gap="16" class="full-width">
                <NetworksSelector
                    :label="$t('device.rental.rentToOrganization')"
                    :organizationId="selectedOrganization?.party_id"
                    @select="selectedOrganization = $event"
                    class="fixed-dialog-dropdown modal-selector-full-width" />
                <StackLayout direction="row" isResponsive :gap="8" class="full-width">
                    <DateTimePickerField
                        :label="$t('device.rental.startAt')"
                        :modelValue="date.start_at"
                        @change="date.start_at = $event"
                        class="full-width" />
                    <DateTimePickerField
                        :label="$t('device.rental.endAt')"
                        :modelValue="date.end_at"
                        @change="date.end_at = $event"
                        class="full-width" />
                </StackLayout>
            </StackLayout>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.fixed-dialog-dropdown {
    &:deep(.dropdown-content) {
        position: fixed;
        display: flex;
        flex-flow: wrap;
    }
}

.dialog-with-dropdown {
    @media (min-width: 1024px) {
        &:deep(.dropdown-content) {
            position: fixed;
            display: flex;
            flex-flow: wrap;
            & .content-horizontal-timepicker {
                border-right: 1px solid $primary;
            }
        }
        & :deep(footer) {
            & .dropdown-content {
                position: relative;
            }
            & select {
                background-color: var(--color-background-secondary);
                color: var(--color-text);
                & option {
                    color: var(--color-text);
                }
            }
        }
    }
    & :deep(footer) {
        & select {
            background-color: var(--color-background-secondary);
            color: var(--color-text);
            & option {
                color: var(--color-text);
            }
        }
    }
}
</style>
