<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ref } from 'vue'
import StackLayout from '../../../../components/Layouts/StackLayout.vue'
import type { ExtendedPrimitives } from '../../../../types/ExtendedPrimitives'
import type { DeviceMessageModel } from '../../../../types/models/DeviceMessageModel'
import AvailableCommands from './CommandsAction/AvailableCommands.vue'
import CommandsHistory from './CommandsAction/CommandsHistory.vue'

type Props = {
    deviceId: number
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
}
defineEmits<Emits>()

const open = ref(false)

const selectedCommand = ref<DeviceMessageModel>()
const selectedCommandConfig = ref<Record<string, ExtendedPrimitives>>()

const handleClick = (value?: DeviceMessageModel) => {
    selectedCommand.value = value
    selectedCommandConfig.value = undefined
}

const handleChange = (value: Record<string, ExtendedPrimitives>) =>
    (selectedCommandConfig.value = { ...(selectedCommandConfig.value ?? {}), ...value })

const save = () => {
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.SEND_DEVICE_COMMAND}`, {
            deviceId: props.deviceId,
            data: {
                message_id: selectedCommand.value?.id,
                arguments: selectedCommandConfig.value
            }
        })
        .finally(() => {
            handleClick(undefined)
            open.value = false
        })
}

const availableCommandsRef = ref()
</script>

<template>
    <PrimaryButton :label="$t('device.actions.commands.commandTitle')" @click="open = true" />

    <BaseDialog
        :title="$t('device.actions.commands.commandTitle')"
        :open="open"
        :confirmLabel="
            $t('device.actions.commands.sendCommand', { command: selectedCommand?.name })
        "
        :disabled="!selectedCommand"
        :loading="store?.state?.device?.isLoading"
        @confirm="save"
        @close="open = false"
        class="dialog-with-dropdown modal-selector-full-width">
        <template #content>
            <StackLayout direction="column" :gap="8" class="commands-stack">
                <AvailableCommands
                    ref="availableCommandsRef"
                    v-if="deviceId"
                    :deviceId="deviceId"
                    :selectedCommand="selectedCommand"
                    :selectedCommandConfig="selectedCommandConfig"
                    @click="handleClick($event)"
                    @change="handleChange" />
                <CommandsHistory v-if="deviceId" :open="open" :deviceId="deviceId" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.commands-stack {
    max-height: 70vh;
}
</style>
