export const menuFr = {
    administration: {
        api: 'API',
        deviceConfiguration: "Configuration de l'appareil",
        devices: 'Appareils',
        devicesConfig: 'Configurations',
        devicesFirmware: 'Firmwares',
        devicesTemplate: 'Templates',
        devicesTemplateKeys: 'Clés de configuration',
        devicesTypes: "Types d'appareil",
        organizations: 'Entreprises',
        roles: 'Rôles & permissions',
        title: 'Administration',
        users: 'Utilisateurs'
    },
    organization: 'Mon entreprise',
    checkpoints: 'Checkpoints',
    deimpersonate: 'Désincarner',
    detections: 'Détections',
    devices: 'Appareils',
    events: 'Évènements',
    home: 'Accueil',
    logout: 'Se déconnecter',
    myAccount: 'Mon compte',
    recentlyVisited: 'Dernier évènement visité',
    technician: {
        documentations: 'Documentations',
        inventory: 'Inventaire',
        questions: 'Questions',
        tickets: 'Tickets',
        title: 'Technicien',
        topics: 'Topics'
    },
    tickets: 'Demandes',
    type: {
        create: 'Création',
        detail: 'Détail',
        generate: 'Génération',
        list: 'Liste'
    },
    supportFaq: 'Support & FAQ'
}
