<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ORGANIZATIONS_NETWORKS_ROUTES } from '../../../../router/routing/organizationRouting/organizationRoutes'
import store from '../../../../store'
import { ENetworkActions } from '../../../../store/networksStore/NetworkStateType'
import { EStoreModules } from '../../../../store/storeType'
import { EColors } from '../../../../types/constants/ColorValues'
import { type NetworkInviteModel } from '../../../../types/models/NetworkModel'
import BaseButton from '../../../Buttons/BaseButton.vue'
import FullScreenLoader from '../../../LoadingSpinner/FullScreenLoader.vue'
import TypographyItem from '../../../Typography/TypographyItem.vue'

const route = useRoute()
const router = useRouter()

const me = computed(() => store.state.authentication.me)

const uuid = computed(() => route.query.uuid)
const currentInvite = ref<NetworkInviteModel>()
const getInvite = () =>
    store
        .dispatch(`${EStoreModules.NETWORK}/${ENetworkActions.FETCH_INVITE}`, uuid.value)
        .then(res => (currentInvite.value = res))

onMounted(() => getInvite())

const handleDecline = () =>
    store
        .dispatch(`${EStoreModules.NETWORK}/${ENetworkActions.DECLINE_NETWORK_INVITE}`, {
            uuid: uuid.value,
            email: me.value.email
        })
        .then(() => router.replace(ORGANIZATIONS_NETWORKS_ROUTES.generateUrl))
const handleAccept = () =>
    store
        .dispatch(`${EStoreModules.NETWORK}/${ENetworkActions.VALIDATE_NETWORK_INVITE}`, {
            uuid: uuid.value,
            email: me.value.email
        })
        .then(() => router.replace(ORGANIZATIONS_NETWORKS_ROUTES.generateUrl))
</script>

<template>
    <FullScreenLoader :isLoading="!!store.state.network.isLoading" />
    <StackLayout direction="column" :gap="16">
        <TypographyItem
            :label="
                $t('organization.networks.invitation.invitedBy', {
                    recipient: currentInvite?.recipient_name,
                    sender: currentInvite?.sender_name
                })
            " />
        <StackLayout :gap="16">
            <BaseButton
                :label="$t('organization.networks.invites.declineInvite')"
                :type="EColors.DANGER"
                class="full-width left-button"
                @click="handleDecline()" />
            <BaseButton
                :label="$t('organization.networks.invites.acceptInvite')"
                :type="EColors.SUCCESS"
                class="full-width right-button"
                @click="handleAccept()" />
        </StackLayout>
    </StackLayout>
</template>
