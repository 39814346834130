<script setup lang="ts">
import ApiTable from '@/components/Api/ApiTable.vue'
import store from '@/store'
import { EApiActions } from '@/store/apiStore/ApiStateType'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { TabHref } from '@/types/components/TabHref'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import { defaultApiFiltersType, type ApiFiltersType } from '@/types/filters/ApiFilters'
import type { ApiModel } from '@/types/models/ApiModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

type Props = {
    organizationId: number
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    buttonsHref?: TabHref
}
const props = defineProps<Props>()

const router = useRouter()

/* const data = computed(() => store.state.organization.apis) */
const data = computed(() => store.state.organization.apis)

const filters = ref<ApiFiltersType>({ ...defaultApiFiltersType, ...getQueryFilters() })
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getApis = () =>
    store.dispatch(
        `${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_ORGANIZATION_APIS}`,
        {
            organizationId: props.organizationId,
            params: {
                filters: filters.value,
                sort: getSortByKey(sort.value),
                per_page: data.value.per_page,
                page: data.value.current_page
            }
        }
    )

const deleteApi = (api: ApiModel) =>
    store
        .dispatch(`${EStoreModules.API}/${EApiActions.DELETE_API}`, { id: api.id, name: api.name })
        .then(() => getApis())

const handleChangePageSize = (value: number) => {
    data.value.current_page = 1
    data.value.per_page = value
    getApis()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getApis()
}
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getApis()
}
const handleChangeFilters = (value: Partial<ApiFiltersType>) => {
    filters.value = { ...filters.value, ...value }
    data.value.current_page = 1
    getApis()
}
</script>

<template>
    <ApiTable
        :data="{ ...data, data: data.data }"
        :filters="filters"
        :sort="sort"
        :editPermission="editPermission"
        :deletePermission="deletePermission"
        :editButtonHref="buttonsHref?.editButtonHref"
        :createButtonHref="buttonsHref?.createButtonHref"
        hrefKey="api_id"
        @delete="deleteApi"
        @changePageSize="handleChangePageSize"
        @changePageNumber="handleChangePageNumber"
        @changeSortBy="handleChangeSortBy"
        @changeFilters="handleChangeFilters"
        @search="getApis" />
</template>
