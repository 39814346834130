<script setup lang="ts">
import { GridLayout, StackLayout } from '@/components/Layouts'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { useGridSize } from '@/hooks/useGridSize'
import type { NetworkModel } from '@/types/models/NetworkModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { userHasPermission } from '@/utils/permissionsUtils'
import { computed } from 'vue'
import { EColors } from '../../../../types/constants/ColorValues'
import BaseButton from '../../../Buttons/BaseButton.vue'
import OrganizationCustomerCard from '../Generate/components/OrganizationNetworkCard.vue'

type Props = {
    data: NetworkModel[]
    loading?: boolean
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    editButtonHref?: string
}
const props = defineProps<Props>()
defineEmits<{
    (e: 'click', user: NetworkModel): void
    (e: 'revoke', network: NetworkModel): void
}>()

const gridSize = useGridSize()

const hasEditPermission = computed(
    () => props.editPermission && userHasPermission(props.editPermission)
)
</script>

<template>
    <GridLayout v-if="data?.length" :maxItemPerRow="gridSize" :gap="8" class="relative">
        <LoadingSpinner :isLoading="!!loading" />
        <OrganizationCustomerCard
            v-for="(customer, key) in data"
            :key="key"
            :label="customer.party_name"
            @click="hasEditPermission && $emit('click', customer)"
            :class="!hasEditPermission ? 'not-clickable' : ''"
            :editButtonHref="editButtonHref">
            <template #footer>
                <BaseButton
                    :label="$t('organization.networks.invites.revokeAccess')"
                    :type="EColors.DANGER"
                    class="full-width"
                    @click="$emit('revoke', customer)" />
            </template>
        </OrganizationCustomerCard>
    </GridLayout>
    <StackLayout v-else class="align-self-center no-record">
        <LoadingSpinner :isLoading="!!loading" />
        {{ $t('common.noRecord') }}
    </StackLayout>
</template>
