import i18n from '@/locales/i18n'
import organizationService from '@/services/organization.service'
import roleService from '@/services/role.service'
import {
    defaultPaginationDataType,
    type PaginationDataType,
    type PaginationType
} from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import type { DeviceFiltersType } from '@/types/filters/DeviceFilters'
import type { AdminUserCreateModel } from '@/types/models/AdminUserCreateModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { OnboardingModel } from '@/types/models/OnboardingModel'
import { defaultOrganizationModel, type OrganizationModel } from '@/types/models/OrganizationModel'
import type { RoleModel } from '@/types/models/RoleModel'
import type { UserModel } from '@/types/models/UserModel'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import type { ApiModel } from '../../types/models/ApiModel'
import { setDocumentAndTitle } from '../../utils/routeUtils'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import {
    EOrganizationActions,
    EOrganizationMutations,
    type OrganizationState
} from './OrganizationStoreTypes'

const state: Ref<OrganizationState> = ref({
    organization: { ...defaultOrganizationModel },
    myOrganization: { ...defaultOrganizationModel },
    organizations: { ...defaultPaginationDataType },
    users: { ...defaultPaginationDataType },
    roles: { ...defaultPaginationDataType },
    apis: { ...defaultPaginationDataType },
    devices: { ...defaultPaginationDataType },
    networks: { ...defaultPaginationDataType },
    isLoading: false
})

const getters = {
    getOrganizations: () => state.value.organizations,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [EOrganizationActions.FETCH_ORGANIZATION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        organizationId: number
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .getOrganization(organizationId)
            .then(res => {
                commit(EOrganizationMutations.SET_ORGANIZATION, res.data)
                setDocumentAndTitle(res.data.name)
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },
    [EOrganizationActions.FETCH_ORGANIZATIONS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: any
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        organizationService
            .getOrganizations(params)
            .then(data => {
                commit(EOrganizationMutations.SET_ORGANIZATIONS, data)
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },
    async [EOrganizationActions.CREATE_ORGANIZATION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        organizationData: OrganizationModel
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .createOrganization(organizationData)
            .then(response => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: organizationData.name,
                    key: EOrganizationActions.CREATE_ORGANIZATION
                })
                return response
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    async [EOrganizationActions.UPDATE_ORGANIZATION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        {
            organizationId,
            organizationData
        }: { organizationId: number; organizationData: OrganizationModel }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .updateOrganization(organizationId, organizationData)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: organizationData.name,
                    key: EOrganizationActions.UPDATE_ORGANIZATION
                })
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    [EOrganizationActions.DELETE_ORGANIZATION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        organization: OrganizationModel
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return organizationService
            .deleteOrganization(organization.id)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: organization.name,
                    key: EOrganizationActions.DELETE_ORGANIZATION
                })
                return res
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    async [EOrganizationActions.CREATE_USER](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { userData, organizationId }: { userData: UserModel; organizationId: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        const res = await organizationService
            .createUser(userData, organizationId)
            .then(response => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: userData.name,
                    key: EOrganizationActions.CREATE_USER
                })
                return response.data
            })
        commit(EOrganizationMutations.SET_LOADING, false)
        return res
    },
    async [EOrganizationActions.CREATE_API](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { data, id }: { data: ApiModel; id: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        const res = await organizationService.createApi(data, id).then(response => {
            dispatch(EHandler.HANDLE_SUCCESS, {
                name: data.name,
                key: EOrganizationActions.CREATE_API
            })
            return response.data
        })
        commit(EOrganizationMutations.SET_LOADING, false)
        return res
    },

    async [EOrganizationActions.INIT_ORGANIZATION_WITH_USER](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: AdminUserCreateModel
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .initOrganizationWithFirstUser(data)
            .then(response => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.user.name,
                    key: EOrganizationActions.INIT_ORGANIZATION_WITH_USER
                })
                return response.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    async [EOrganizationActions.FETCH_MY_ORGANIZATION]({
        commit,
        dispatch
    }: {
        commit: Commit
        dispatch: Dispatch
    }) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .getMyOrganization()
            .then(res => {
                commit(EOrganizationMutations.SET_MY_ORGANIZATION, res.data)
                setDocumentAndTitle(res.data.name)
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },
    async [EOrganizationActions.FETCH_MY_DEVICES](
        { commit }: { commit: Commit },
        params: Partial<PaginationType & DeviceFiltersType>
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)

        return await organizationService
            .getMyDevices(params)
            .then(res => {
                commit(EOrganizationMutations.SET_DEVICES, res.data)
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    async [EOrganizationActions.FETCH_ORGANIZATION_ROLES](
        { commit }: { commit: Commit },
        params: Partial<PaginationType>
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await roleService
            .getRoles(params)
            .then(res => {
                commit(EOrganizationMutations.SET_ROLES, res)
                return res
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    async [EOrganizationActions.FETCH_ORGANIZATION_USERS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { params, organizationId }: { params: Partial<PaginationType>; organizationId: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .getOrganizationUsers(params, organizationId)
            .then(res => {
                commit(EOrganizationMutations.SET_USERS, res.data)
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    async [EOrganizationActions.FETCH_ORGANIZATION_APIS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { params, organizationId }: { params: Partial<PaginationType>; organizationId: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .getOrganizationApis(organizationId, params)
            .then(res => {
                commit(EOrganizationMutations.SET_APIS, res.data)
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    async [EOrganizationActions.ATTACH_USER_TO_ORGANIZATION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { organizationId, userId }: { organizationId: number; userId: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        const response = await organizationService
            .attachUserToOrganization(organizationId, userId)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    key: EOrganizationActions.ATTACH_USER_TO_ORGANIZATION
                })
                return res.data
            })
        commit(EOrganizationMutations.SET_LOADING, false)
        return response
    },
    async [EOrganizationActions.DETACH_USER_TO_ORGANIZATION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { organizationId, userId }: { organizationId: number; userId: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .detachUserFromOrganization(organizationId, userId)
            .then(res => res.data)
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    async [EOrganizationActions.FETCH_ORGANIZATION_DEVICES](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        {
            params,
            organizationId
        }: { params: Partial<PaginationType & DeviceFiltersType>; organizationId: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)

        return await organizationService
            .getOrganizationDevices(organizationId, params)
            .then(res => {
                commit(EOrganizationMutations.SET_DEVICES, res.data)
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },
    async [EOrganizationActions.ATTACH_DEVICE_TO_ORGANIZATION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { organizationId, deviceId }: { organizationId: number; deviceId: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        const response = await organizationService
            .attachDeviceToOrganization(organizationId, deviceId)
            .then(res => res.data)
        commit(EOrganizationMutations.SET_LOADING, false)
        return response
    },
    async [EOrganizationActions.DETACH_DEVICE_TO_ORGANIZATION](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { organizationId, deviceId }: { organizationId: number; deviceId: number }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        const response = await organizationService
            .detachDeviceFromOrganization(organizationId, deviceId)
            .then(res => res.data)
        commit(EOrganizationMutations.SET_LOADING, false)
        return response
    },

    async [EOrganizationActions.ONBOARDING](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: OnboardingModel
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .onboarding(data)
            .then(res => {
                dispatch(
                    `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
                    {
                        action: EAlertActions.SUCCESS,
                        message: i18n.global.t(
                            'authentication.onboarding.api.success.sendOnboarding'
                        )
                    },
                    { root: true }
                )
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },
    async [EOrganizationActions.GET_ONBOARDING](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        uuid: string
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .getOnboarding(uuid)
            .then(res => res.data)

            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },
    async [EOrganizationActions.VALIDATE_ONBOARDING](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { uuid, data }: { uuid: string; data: OnboardingModel }
    ) {
        commit(EOrganizationMutations.SET_LOADING, true)
        return await organizationService
            .validateOnboarding(uuid, data)
            .then(res => {
                dispatch(
                    `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
                    {
                        action: EAlertActions.SUCCESS,
                        message: i18n.global.t(
                            'authentication.onboarding.api.success.validateOnboarding'
                        )
                    },
                    { root: true }
                )
                return res.data
            })
            .finally(() => commit(EOrganizationMutations.SET_LOADING, false))
    },

    /* onboarding/validate?uuid=d191e355-8347-4cde-a137-0db9db7a5123 */

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`organization.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`organization.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [EOrganizationMutations.SET_USERS](
        _: OrganizationState,
        users: PaginationDataType<UserModel[]>
    ) {
        state.value.users = users
    },
    [EOrganizationMutations.SET_DEVICES](
        _: OrganizationState,
        devices: PaginationDataType<DeviceModel[]>
    ) {
        state.value.devices = devices
    },
    [EOrganizationMutations.SET_DEVICE_IN_DEVICES](_: OrganizationState, device: DeviceModel) {
        const index = state.value.devices.data.findIndex(
            item => item.device_id === device.device_id || item.id === device.id
        )
        if (index !== -1) state.value.devices.data[index] = device
    },
    [EOrganizationMutations.SET_ROLES](
        _: OrganizationState,
        roles: PaginationDataType<RoleModel[]>
    ) {
        state.value.roles = roles
    },
    [EOrganizationMutations.SET_APIS](_: OrganizationState, apis: PaginationDataType<ApiModel[]>) {
        state.value.apis = apis
    },
    [EOrganizationMutations.SET_ORGANIZATIONS](
        _: OrganizationState,
        organizations: PaginationDataType<OrganizationModel[]>
    ) {
        state.value.organizations = organizations
    },
    [EOrganizationMutations.SET_ORGANIZATION](
        _: OrganizationState,
        organization: OrganizationModel
    ) {
        state.value.organization = organization
    },
    [EOrganizationMutations.SET_MY_ORGANIZATION](
        _: OrganizationState,
        organization: OrganizationModel
    ) {
        state.value.myOrganization = organization
    },
    [EOrganizationMutations.SET_LOADING](_: OrganizationState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
