<script setup lang="ts">
import deviceConfigApi from '@/services/deviceConfig.service'
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import moment from 'moment'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import TypographyItem from '../../../../components/Typography/TypographyItem.vue'
import type { DeviceFirmwaresModel } from '../../../../types/models/DeviceFirmwaresModel'
import { DATETIME_FORMAT } from '../../../../utils/dateUtils'
import DeviceFirmwareView from './components/DeviceFirmwareView.vue'

const route = useRoute()
const deviceConfigId = ref(route.params.id.toString())
const data = ref<Partial<DeviceFirmwaresModel>>()
const file = ref<File>()

const getDeviceFirmware = () =>
    deviceConfigApi.getDeviceFirmware(deviceConfigId.value).then(res => (data.value = res.data))
onMounted(() => {
    if (typeof deviceConfigId.value === 'string') getDeviceFirmware()
})

const updateDevice = (value: Partial<DeviceConfigModel>) =>
    (data.value = { ...data.value, ...value })

const save = () => {
    const formData = new FormData()
    formData.append('data', JSON.stringify(data.value))

    if (file.value) {
        const blobData = new Blob([file.value], { type: file.value.type })
        formData.append('file', blobData)
    }
    store
        .dispatch(`${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.UPDATE_DEVICE_FIRMWARE}`, {
            id: deviceConfigId.value,
            data: formData
        })
        .then(() => getDeviceFirmware())
}
</script>

<template>
    <DeviceFirmwareView
        :data="data"
        :confirmLabel="$t('deviceConfig.template.buttons.updateFirmware')"
        :permission="EAdminPerm.ADMIN_PERMISSIONS"
        :buttonLabel="$t('common.saveModifications')"
        :uploadLabel="$t('deviceConfig.firmware.buttons.replaceFile')"
        :file="file"
        @change="updateDevice"
        @uploadFile="file = $event as File"
        @confirm="save">
        <template v-if="data?.release_at" #releaseDate>
            <TypographyItem
                :label="moment(data?.release_at).utc().format(DATETIME_FORMAT)" />
        </template>
    </DeviceFirmwareView>
</template>
