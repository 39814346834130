<script setup lang="ts">
import { DeviceDetailPage } from '@/components/DeviceComponents'
import DeviceBaseActions from '@/components/DeviceComponents/components/DeviceBaseActions.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { ClickableTypography, TypographyItem, TypographyWithPrefix } from '@/components/Typography'
import { ADMIN_ORGANIZATIONS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import deviceConfig from '@/services/deviceConfig.service'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { EDeviceStatus } from '@/types/enum/DeviceStatusEnum'
import { defaultCreateDevice } from '@/types/models/DeviceCreateModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { EDevicesPerm } from '@/types/permissions/DevicesPermissionsEnum'
import { addToQuery } from '@/utils/queryUtils'
import { isEqual } from 'lodash'
import { computed, provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import DeviceApiKeyTypography from './components/DeviceApiKeyTypography.vue'
import DeviceSendCommandsAction from './components/DeviceSendCommandsAction.vue'
import DeviceTypeActionComponent from './components/DeviceTypeActionComponent.vue'

type Props = {
    device?: Partial<DeviceModel>
    tabsMultilined?: boolean
    isOutlookMode?: boolean
}
const props = defineProps<Props>()

const route = useRoute()
const deviceStore = ref(store.state.device.device)

const localDeviceId = computed(() => {
    if (route.query.preview) return parseInt(route.query.preview.toString())
    if (props.device && props.device.id) return props.device.device_id ?? props.device.id
    return route.params.device_id || route.params.id
        ? parseInt((route.params.device_id ?? route.params.id).toString())
        : undefined
})

const data = ref<Partial<DeviceModel>>({ ...defaultCreateDevice })
const templateKeys = ref<DeviceTemplateKeysModel[]>([])
const getDeviceConfig = (deviceId: number) =>
    deviceConfig
        .getDeviceTypeConfigByVersion(deviceId)
        .then(res => (templateKeys.value = res.data?.config_keys ?? []))

const getDevice = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.FETCH_DEVICE}`, {
            deviceId: localDeviceId.value,
            noDocumentTitle: props.isOutlookMode
        })
        .then(res => {
            data.value = { ...res }
            deviceStore.value = { ...res }
            if (props.isOutlookMode) addToQuery({ preview: res.device_id ?? res.id })
            if (res.device_type_id && localDeviceId.value)
                return getDeviceConfig(localDeviceId.value)
            templateKeys.value = []
        })

watch(
    () => localDeviceId.value,
    (newDeviceId, oldDeviceId) => {
        if (newDeviceId && newDeviceId !== oldDeviceId) getDevice()
    },
    { immediate: true }
)

const save = () => {
    if (!isEqual(data.value, deviceStore.value))
        store
            .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.UPDATE_DEVICE}`, {
                deviceId: localDeviceId.value,
                deviceData: { ...data.value, roles: ['device'] }
            })
            .then(res => {
                data.value = { ...data.value, ...res }
                deviceStore.value = { ...data.value, ...res }
            })
}
const updateDeviceValue = (value: Partial<DeviceModel>) => (data.value = value)

const deviceStatus = computed(() => ({
    battery: props.device?.battery ?? deviceStore.value?.battery,
    plugged: props.device?.plugged ?? deviceStore.value?.plugged,
    signal: props.device?.signal ?? deviceStore.value?.signal,
    status: (props.device?.status as EDeviceStatus) ?? (deviceStore.value?.status as EDeviceStatus),
    network_type: props.device?.network_type ?? deviceStore.value?.network_type
}))

provide('isAdmin', ref(true))
</script>

<template>
    <DeviceDetailPage
        :storedData="deviceStore"
        :data="data"
        :status="deviceStatus"
        :templateKeys="templateKeys"
        :editPermission="EAdminPerm.ADMIN_DEVICES"
        noDetectionLink
        @save="save"
        @update="updateDeviceValue"
        :loading="store.state.device.isLoading">
        <template #informationsContent>
            <TypographyWithPrefix
                v-if="data.organization_name && data.organization_id"
                :prefix="$t('device.model.organizationName')">
                <template #edit>
                    <ClickableTypography
                        v-if="data.organization_name"
                        :label="data.organization_name"
                        :href="ADMIN_ORGANIZATIONS_ROUTES.buildUrl(data.organization_id)" />
                    <TypographyItem v-else :label="$t('common.undefined')" />
                </template>
            </TypographyWithPrefix>
            <PermissionWrapper :permission="EDevicesPerm.DEVICES_API_KEY">
                <DeviceApiKeyTypography
                    v-if="data?.device_id || data?.id"
                    :deviceId="data?.device_id ?? data?.id" />
            </PermissionWrapper>
        </template>
        <template #informationsActions></template>
        <template #actions>
            <DeviceBaseActions
                :deviceId="localDeviceId"
                :organizationId="data.organization_id"
                :organizationName="data.organization_name"
                @refresh="getDevice()" />
            <DeviceTypeActionComponent :data="data" @refresh="getDevice()" />
            <DeviceSendCommandsAction
                v-if="data?.device_id || data?.id"
                :deviceId="data?.device_id ?? data.id!" />
        </template>
    </DeviceDetailPage>
</template>
