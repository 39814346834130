<script setup lang="ts">
import BaseButton from '@/components/Buttons/BaseButton.vue'
import EditableTypedInput from '@/components/Fields/EditableTypedInput.vue'
import GridLayout from '@/components/Layouts/GridLayout.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EColors } from '@/types/constants/ColorValues'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { DeviceMessageModel } from '@/types/models/DeviceMessageModel'
import { onMounted, ref } from 'vue'

type Props = {
    deviceId: number
    selectedCommand?: DeviceMessageModel
    selectedCommandConfig?: Record<string, ExtendedPrimitives>
}
defineProps<Props>()
type Emits = {
    (e: 'click', command: DeviceMessageModel): void
    (e: 'change', value: Record<string, ExtendedPrimitives>): void
}
defineEmits<Emits>()

const availableCommands = ref<DeviceMessageModel[]>([])

const getCommands = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.GET_COMMANDS}`)
        .then(res => (availableCommands.value = res))

onMounted(() => getCommands())
</script>

<template>
    <StackLayout v-if="availableCommands.length" direction="column" :gap="8">
        <TypographyItem :label="$t('device.actions.commands.availableCommands')" />
        <GridLayout :maxItemPerRow="2" :gap="8">
            <BaseButton
                v-for="(item, key) in availableCommands"
                :label="item.name"
                :type="item.id === selectedCommand?.id ? EColors.PRIMARY : EColors.SECONDARY"
                :class="`${
                    item.id === selectedCommand?.id ? EColors.PRIMARY : EColors.SECONDARY
                }-button`"
                @click="$emit('click', item)"
                :key="key" />
        </GridLayout>
        <TypographyItem
            v-if="selectedCommand?.config && Object.entries(selectedCommand.config).length"
            :label="
                $t('device.actions.commands.commandConfig', {
                    command: selectedCommand.name
                })
            " />
        <GridLayout :maxItemPerRow="2" :rowGap="0" :gap="4" v-if="selectedCommand?.config">
            <EditableTypedInput
                v-for="([argumentKey, argumentValue], key) in Object.entries(
                    selectedCommand.config
                )"
                :key="key"
                isInEditMode
                :label="argumentKey"
                @change="
                    $emit('change', {
                        ...selectedCommandConfig,
                        [argumentKey]: $event
                    })
                "
                :modelValue="selectedCommandConfig?.[argumentKey]"
                :dataType="argumentValue" />
        </GridLayout>
    </StackLayout>
    <TypographyItem v-else :label="$t('device.actions.commands.noAvailableCommand')" />
</template>
