export enum EReleaseChannel {
    STABLE = 'stable',
    NEXT = 'next',
    DEV = 'dev'
}

export const EReleaseChannelValues = [
    EReleaseChannel.STABLE,
    EReleaseChannel.NEXT,
    EReleaseChannel.DEV
]
