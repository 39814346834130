import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PaginationDataType } from '@/types/PaginationType'

export type DeviceState = PaginationDataType<DeviceModel[]> & { device?: DeviceModel }

export enum EDeviceActions {
    FETCH_DEVICE = 'fetchDevice',
    FETCH_DEVICES = 'fetchDevices',
    CREATE_DEVICE = 'createDevice',
    UPDATE_DEVICE = 'updateDevice',
    DELETE_DEVICE = 'deleteDevice',

    MASS_UPDATE_DEVICE = 'massUpdateDevice',

    FETCH_API_DEVICE = 'fetchApiDevice',
    UPDATE_API_DEVICE = 'updateApiDevice',
    GET_DEVICE_API_KEY = 'getApiKey',

    UPDATE_DEVICE_TABLE = 'updateDeviceTable',

    GET_COMMANDS = 'getCommands',
    GET_DEVICE_COMMANDS_HISTORY = 'getDeviceCommandsHistory',
    SEND_DEVICE_COMMAND = 'sendDeviceCommand'
}

export enum EDeviceMutations {
    SET_DEVICES = 'setDevices',
    SET_DEVICE = 'setDevice',
    SET_DEVICE_IN_TABLE = 'setDeviceInTable',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
