import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { DeviceTypesModel } from '@/types/models/DeviceTypesModel'
import type { PaginationDataType } from '@/types/PaginationType'
import type { DeviceFirmwaresModel } from '../../types/models/DeviceFirmwaresModel'

export type DeviceConfigState = {
    deviceTypes: {
        deviceType?: DeviceTypesModel
        isLoading: boolean
    } & PaginationDataType<DeviceTypesModel[]>

    deviceConfigs: {
        deviceConfig?: DeviceConfigModel
        isLoading: boolean
    } & PaginationDataType<DeviceConfigModel[]>

    templateKeys: {
        templateKey?: DeviceTemplateKeysModel
        isLoading: boolean
    } & PaginationDataType<DeviceTemplateKeysModel[]>

    firmwares: {
        firmware?: DeviceFirmwaresModel
        isLoading: boolean
    } & PaginationDataType<DeviceFirmwaresModel[]>
}

export enum EDeviceConfigActions {
    FETCH_DEVICE_TYPE = 'fetchDeviceType',
    FETCH_DEVICE_TYPES = 'fetchDeviceTypes',
    CREATE_DEVICE_TYPE = 'createDeviceType',
    UPDATE_DEVICE_TYPE = 'updateDeviceType',
    DELETE_DEVICE_TYPE = 'deleteDeviceType',

    FETCH_DEVICE_CONFIG = 'fetchDeviceConfig',
    FETCH_DEVICE_CONFIGS = 'fetchDeviceConfigs',
    CREATE_DEVICE_CONFIG = 'createDeviceConfig',
    UPDATE_DEVICE_CONFIG = 'updateDeviceConfig',
    DELETE_DEVICE_CONFIG = 'deleteDeviceConfig',

    FETCH_DEVICE_TEMPLATE_KEY = 'fetchDeviceTemplateKey',
    FETCH_DEVICE_TEMPLATE_KEYS = 'fetchDeviceTemplateKeys',
    CREATE_DEVICE_TEMPLATE_KEY = 'createDeviceTemplateKey',
    UPDATE_DEVICE_TEMPLATE_KEY = 'updateDeviceTemplateKey',
    DELETE_DEVICE_TEMPLATE_KEY = 'deleteDeviceTemplateKey',

    FETCH_DEVICE_FIRMWARE = 'fetchDeviceFirmware',
    FETCH_DEVICE_FIRMWARES = 'fetchDeviceFirmwares',
    CREATE_DEVICE_FIRMWARE = 'createDeviceFirmware',
    UPDATE_DEVICE_FIRMWARE = 'updateDeviceFirmware',
    DELETE_DEVICE_FIRMWARE = 'deleteDeviceFirmware',

    FETCH_API_DEVICE_CONFIG = 'fetchApiDeviceConfig'
}

export enum EDeviceConfigMutations {
    SET_DEVICE_TYPES = 'setDeviceTypes',
    SET_DEVICE_TYPE = 'setDeviceType',
    SET_DEVICE_CONFIGS = 'setDeviceConfigs',
    SET_DEVICE_CONFIG = 'setDeviceConfig',
    SET_DEVICE_TEMPLATE_KEYS = 'setDeviceTemplateKeys',
    SET_DEVICE_TEMPLATE_KEY = 'setDeviceTemplateKey',
    SET_FIRMWARES = 'setFirmwares',
    SET_FIRMWARE = 'setFirmware',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
