<script setup lang="ts">
import ApiDetailView from '@/components/Api/ApiDetailView.vue'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import store from '@/store'
import { defaultApiModel, type ApiModel } from '@/types/models/ApiModel'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ORGANIZATIONS_APIS_ROUTES } from '../../../../router/routing/organizationRouting/organizationRoutes'
import { EOrganizationActions } from '../../../../store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '../../../../store/storeType'

const router = useRouter()
const route = useRoute()

const id = computed(
    () => route.params.id?.toString() ?? store.state.authentication.me.organization_id
)

const data = ref<Partial<ApiModel>>({ ...defaultApiModel })
const isLoading = computed(() => store.state.organization.apis.isLoading)
const submitted = ref(false)

const updateApi = (value: Partial<ApiModel>) => (data.value = value)

const create = () => {
    submitted.value = true
    if (!data.value.name) return
    store
        .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.CREATE_API}`, {
            data: data.value,
            id: id.value
        })
        .then(res => router.replace(ORGANIZATIONS_APIS_ROUTES.buildUrl(res.api_id ?? res.id)))
}
</script>

<template>
    <FullScreenLoader :isLoading="!!isLoading" />
    <ApiDetailView
        :data="data"
        :buttonLabel="$t('api.buttons.createApi')"
        hasPermission
        isCreateMode
        :loading="!!store.state.organization.apis.isLoading"
        @click="create"
        @update="updateApi" />
</template>
