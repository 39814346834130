import type { TabHref } from '@/types/components/TabHref'

/* ADMIN ROUTES */
export const ADMIN_ROUTE = '/admin'

export const ADMIN_ROLES_ROUTE = `${ADMIN_ROUTE}/roles`
export const ADMIN_ROLES_ROUTES = {
    baseUrl: ADMIN_ROLES_ROUTE,
    createUrl: `${ADMIN_ROLES_ROUTE}/create`,
    detailUrl: `${ADMIN_ROLES_ROUTE}/:id`,
    buildUrl: (roleId: number) => `${ADMIN_ROLES_ROUTE}/${roleId}`
}

export const ADMIN_APIS_ROUTE = `${ADMIN_ROUTE}/apis`
export const ADMIN_APIS_ROUTES = {
    baseUrl: ADMIN_APIS_ROUTE,
    createUrl: `${ADMIN_APIS_ROUTE}/create`,
    detailUrl: `${ADMIN_APIS_ROUTE}/:id`,
    buildUrl: (apiId: number) => `${ADMIN_APIS_ROUTE}/${apiId}`
}

export const ADMIN_DEVICES_ROUTE = `${ADMIN_ROUTE}/devices`
export const ADMIN_DEVICES_ROUTES = {
    baseUrl: ADMIN_DEVICES_ROUTE,
    createUrl: `${ADMIN_DEVICES_ROUTE}/create`,
    detailUrl: `${ADMIN_DEVICES_ROUTE}/:id`,
    buildUrl: (deviceId: number) => `${ADMIN_DEVICES_ROUTE}/${deviceId}`
}

export const ADMIN_USERS_ROUTE = `${ADMIN_ROUTE}/users`
export const ADMIN_USERS_ROUTES = {
    baseUrl: ADMIN_USERS_ROUTE,
    createUrl: `${ADMIN_USERS_ROUTE}/create`,
    detailUrl: `${ADMIN_USERS_ROUTE}/:id`,
    buildUrl: (deviceId: number) => `${ADMIN_USERS_ROUTE}/${deviceId}`
}

export const ADMIN_ORGANIZATIONS_ROUTE = `${ADMIN_ROUTE}/organizations`
export const ADMIN_ORGANIZATIONS_ROUTES = {
    baseUrl: ADMIN_ORGANIZATIONS_ROUTE,
    createUrl: `${ADMIN_ORGANIZATIONS_ROUTE}/create`,
    detailUrl: `${ADMIN_ORGANIZATIONS_ROUTE}/:id`,
    buildUrl: (organizationId: number) => `${ADMIN_ORGANIZATIONS_ROUTE}/${organizationId}`
}

export const ADMIN_DEVICE_CONFIGURATION_ROUTE = `${ADMIN_ROUTE}/configuration`
export const ADMIN_DEVICE_TYPES_ROUTE = `${ADMIN_DEVICE_CONFIGURATION_ROUTE}/types`
export const ADMIN_DEVICE_TYPES_ROUTES = {
    baseUrl: ADMIN_DEVICE_TYPES_ROUTE,
    createUrl: `${ADMIN_DEVICE_TYPES_ROUTE}/create`,
    detailUrl: `${ADMIN_DEVICE_TYPES_ROUTE}/:id`,
    buildUrl: (configurationId: number) => `${ADMIN_DEVICE_TYPES_ROUTE}/${configurationId}`
}
export const ADMIN_DEVICE_TEMPLATES_ROUTE = `${ADMIN_DEVICE_CONFIGURATION_ROUTE}/templates`
export const ADMIN_DEVICE_TEMPLATES_ROUTES = {
    baseUrl: ADMIN_DEVICE_TEMPLATES_ROUTE,
    createUrl: `${ADMIN_DEVICE_TEMPLATES_ROUTE}/create`,
    detailUrl: `${ADMIN_DEVICE_TEMPLATES_ROUTE}/:id`,
    buildUrl: (configurationId: number) => `${ADMIN_DEVICE_TEMPLATES_ROUTE}/${configurationId}`
}
export const ADMIN_DEVICE_TEMPLATE_KEYS_ROUTE = `${ADMIN_DEVICE_CONFIGURATION_ROUTE}/template-keys`
export const ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES = {
    baseUrl: ADMIN_DEVICE_TEMPLATE_KEYS_ROUTE,
    createUrl: `${ADMIN_DEVICE_TEMPLATE_KEYS_ROUTE}/create`,
    detailUrl: `${ADMIN_DEVICE_TEMPLATE_KEYS_ROUTE}/:id`,
    buildUrl: (templateKeyId: number) => `${ADMIN_DEVICE_TEMPLATE_KEYS_ROUTE}/${templateKeyId}`
}
export const ADMIN_FIRMWARES_ROUTE = `${ADMIN_DEVICE_CONFIGURATION_ROUTE}/firmwares`
export const ADMIN_FIRMWARES_ROUTES = {
    baseUrl: ADMIN_FIRMWARES_ROUTE,
    createUrl: `${ADMIN_FIRMWARES_ROUTE}/create`,
    detailUrl: `${ADMIN_FIRMWARES_ROUTE}/:id`,
    buildUrl: (templateKeyId: number) => `${ADMIN_FIRMWARES_ROUTE}/${templateKeyId}`
}

export const ADMIN_ORGANIZATION_USER_ROUTE = (organizationId?: number) =>
    `${ADMIN_ORGANIZATIONS_ROUTE}/${organizationId ?? ':organization_id'}/users`
export const ADMIN_ORGANIZATION_USER_ROUTES = (organizationId?: number) => ({
    baseUrl: ADMIN_ORGANIZATION_USER_ROUTE(organizationId),
    createUrl: `${ADMIN_ORGANIZATION_USER_ROUTE(organizationId)}/create`,
    detailUrl: `${ADMIN_ORGANIZATION_USER_ROUTE()}/:user_id`,
    buildUrl: (userId: number) => `${ADMIN_ORGANIZATION_USER_ROUTE(organizationId)}/${userId}`
})
export const ADMIN_ORGANIZATION_USER_HREF = (organizationId: number): TabHref => ({
    editButtonHref: ADMIN_ORGANIZATION_USER_ROUTES(organizationId).baseUrl,
    createButtonHref: ADMIN_ORGANIZATION_USER_ROUTES(organizationId).createUrl
})

export const ADMIN_ORGANIZATION_DEVICES_ROUTE = (organizationId?: number) =>
    `${ADMIN_ORGANIZATIONS_ROUTE}/${organizationId ?? ':organization_id'}/devices`
export const ADMIN_ORGANIZATION_DEVICES_ROUTES = (organizationId?: number) => ({
    baseUrl: ADMIN_ORGANIZATION_DEVICES_ROUTE(organizationId),
    createUrl: `${ADMIN_ORGANIZATION_DEVICES_ROUTE(organizationId)}/create`,
    detailUrl: `${ADMIN_ORGANIZATION_DEVICES_ROUTE()}/:device_id`,
    buildUrl: (deviceId: number) =>
        `${ADMIN_ORGANIZATION_DEVICES_ROUTE(organizationId)}/${deviceId}`
})
export const ADMIN_ORGANIZATION_DEVICE_HREF = (organizationId: number): TabHref => ({
    editButtonHref: ADMIN_ORGANIZATION_DEVICES_ROUTES(organizationId).baseUrl,
    createButtonHref: ADMIN_ORGANIZATION_DEVICES_ROUTES(organizationId).createUrl
})

export const ADMIN_ORGANIZATION_NETWORKS_ROUTE = (organizationId?: number) =>
    `${ADMIN_ORGANIZATIONS_ROUTE}/${organizationId ?? ':organization_id'}/networks`
export const ADMIN_ORGANIZATION_NETWORKS_ROUTES = (organizationId?: number) => ({
    baseUrl: ADMIN_ORGANIZATION_NETWORKS_ROUTE(organizationId),
    createUrl: `${ADMIN_ORGANIZATION_NETWORKS_ROUTE(organizationId)}/create`,
    detailUrl: `${ADMIN_ORGANIZATION_NETWORKS_ROUTE()}/:customer_id`,
    buildUrl: (customerId: number) =>
        `${ADMIN_ORGANIZATION_NETWORKS_ROUTE(organizationId)}/${customerId}`
})
export const ADMIN_ORGANIZATION_NETWORKS_HREF = (organizationId: number): TabHref => ({
    editButtonHref: ADMIN_ORGANIZATION_NETWORKS_ROUTES(organizationId).baseUrl,
    createButtonHref: ADMIN_ORGANIZATION_NETWORKS_ROUTES(organizationId).createUrl
})

export const ADMIN_ORGANIZATION_API_ROUTE = (organizationId?: number) =>
    `${ADMIN_ORGANIZATIONS_ROUTE}/${organizationId ?? ':organization_id'}/apis`
export const ADMIN_ORGANIZATION_API_ROUTES = (organizationId?: number) => ({
    baseUrl: ADMIN_ORGANIZATION_API_ROUTE(organizationId),
    createUrl: `${ADMIN_ORGANIZATION_API_ROUTE(organizationId)}/create`,
    detailUrl: `${ADMIN_ORGANIZATION_API_ROUTE()}/:api_id`,
    buildUrl: (apiId: number) => `${ADMIN_ORGANIZATION_API_ROUTE(organizationId)}/${apiId}`
})
export const ADMIN_ORGANIZATION_API_HREF = (organizationId: number): TabHref => ({
    editButtonHref: ADMIN_ORGANIZATION_API_ROUTES(organizationId).baseUrl,
    createButtonHref: ADMIN_ORGANIZATION_API_ROUTES(organizationId).createUrl
})
