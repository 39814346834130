<script setup lang="ts">
import CreateButton from '@/components/Buttons/CreateButton.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import store from '@/store'
import { ENetworkActions } from '@/store/networksStore/NetworkStateType'
import { EStoreModules } from '@/store/storeType'
import { REGEX_EMAIL } from '@/utils/regexUtils'
import { ref } from 'vue'

type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const friendCode = ref()

const handleClick = () =>
    store
        .dispatch(
            `${EStoreModules.NETWORK}/${ENetworkActions.SEND_NETWORK_INVITE}`,
            friendCode.value
        )
        .then(() => emit('refresh'))
</script>

<template>
    <StackLayout direction="column" :gap="16" class="full-width">
        <StackLayout direction="row" :gap="8">
            <InputField
                :label="$t('organization.networks.invites.email')"
                :modelValue="friendCode"
                required
                @change="friendCode = $event"
                class="full-width add-friend-code" />
            <CreateButton
                :disabled="!friendCode?.match(REGEX_EMAIL)"
                :label="$t('common.send')"
                @click="handleClick" />
        </StackLayout>
    </StackLayout>
</template>
