<script setup lang="ts">
import CreateButton from '@/components/Buttons/CreateButton.vue'
import { StackLayout } from '@/components/Layouts'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'
import OrganizationEmailInvite from './components/OrganizationEmailInvite.vue'

type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const open = ref(false)

const isMobileScreen = ref(isMobile())
isMobileEvent(e => (isMobileScreen.value = e.matches))

const handleRefresh = () => {
    open.value = false
    emit('refresh')
}
</script>

<template>
    <CreateButton
        :label="$t('organization.networks.invites.generateInvitation')"
        @click="open = true" />
    <BaseDialog
        :title="$t('organization.networks.invites.generateInvitation')"
        :open="open"
        @close="open = false"
        noFooter
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <StackLayout
                direction="column"
                :gap="16"
                :class="`full-width ${isMobileScreen ? 'align-items-center' : ''}`"
                isResponsive>
                <!-- <InformationBaseCard
                    :label="$t('organization.organizations.invite.qrCode')"
                    class="background-color align-self-center qrCode-card full-width">
                    <OrganizationQRCode />
                </InformationBaseCard>
                <InformationBaseCard
                    :label="$t('organization.organizations.invite.friendCode')"
                    class="background-color full-width invite-link">
                    <OrganizationFriendCode />
                </InformationBaseCard>
                <InformationBaseCard
                    :label="$t('organization.organizations.invite.inviteLink')"
                    class="background-color full-width friend-code invite-link">
                    <OrganizationLinkShare />
                </InformationBaseCard> -->

                <OrganizationEmailInvite @refresh="handleRefresh" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.qrCode-card {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    max-width: 400px;
}
.friend-code {
    & :deep(.information-card-content) {
        min-height: fit-content;
        & .field.is-floating-label .label::before {
            background-color: var(--color-background-secondary);
        }
    }
    & span {
        overflow-x: auto;
    }
}
.invite-link {
    max-width: 100%;
}
.link-class {
    padding: 4px;
    border-radius: 4px;
    height: fit-content;
    overflow-x: auto;
    &:focus {
        background-color: gray;
    }
    &:focus-within {
        background-color: gray;
    }
}
</style>
