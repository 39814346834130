<script setup lang="ts">
import { BaseTable, TableColumn } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import TagStack from '@/components/Tags/TagStack.vue'
import { TypographyItem } from '@/components/Typography'
import type { NetworkModel } from '@/types/models/NetworkModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type Props = {
    data: NetworkModel[]
    loading?: boolean
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    editButtonHref?: string
}
defineProps<Props>()
type Emits = {
    (e: 'click', network: NetworkModel): void
    (e: 'delete', network: NetworkModel): void
}
const emit = defineEmits<Emits>()

const columns = computed(() => ({
    id: { field: 'id', label: t('organization.organizations.table.id') },
    name: { field: 'name', label: t('organization.organizations.table.name') },
    email: { field: 'email', label: t('organization.organizations.table.email') },
    roles: { field: 'roles', label: t('organization.organizations.table.roles') }
}))

const table = ref<any>([])
</script>

<template>
    <BaseTable v-bind:ref="table" :data="data ?? []" :loading="loading">
        <template #columns>
            <TableColumn :label="columns['id'].label" field="id" />
            <TableColumn :label="columns['name'].label" field="name" />
            <TableColumn :label="columns['email'].label" field="email" />
            <TableColumn :label="columns['roles'].label" v-slot="props">
                <TagStack
                    v-if="props.row.roles?.length"
                    :data="props.row.roles?.map((item: string) => ({ name: item }))"
                    :closable="false"
                    class="tag-stack" />
                <TypographyItem v-else :label="$t('common.noRole')" />
            </TableColumn>
            <TableActionColumn
                @goTo="$emit('click', $event)"
                @delete="emit('delete', $event)"
                :deletePermission="deletePermission"
                :editPermission="editPermission"
                :editButtonHref="editButtonHref"
                hrefKey="network_id" />
        </template>
    </BaseTable>
</template>

<style scoped lang="scss">
.tag-stack {
    & :hover {
        background-color: $primary;
    }
}
</style>
