import type { TabWithTablePermissions } from '../components/TabPermissions'

export enum EOrganizationsPerm {
    ORGANIZATIONS_DELETE = 'organizations_delete',
    ORGANIZATIONS_EDIT = 'organizations_edit',
    ORGANIZATIONS_VIEW = 'organizations_view'
}
export enum EOrganizationsUsersPerm {
    ORGANIZATIONS_USERS_DELETE = 'organizations_users_delete',
    ORGANIZATIONS_USERS_EDIT = 'organizations_users_edit',
    ORGANIZATIONS_USERS_VIEW = 'organizations_users_view'
}
export const EOrganizationsUsersTabPerm: TabWithTablePermissions = {
    deletePermission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_DELETE,
    editPermission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_EDIT,
    viewPermission: EOrganizationsUsersPerm.ORGANIZATIONS_USERS_VIEW
}

export enum EOrganizationsDevicesPerm {
    ORGANIZATIONS_DEVICES_DELETE = 'organizations_devices_delete',
    ORGANIZATIONS_DEVICES_EDIT = 'organizations_devices_edit',
    ORGANIZATIONS_DEVICES_VIEW = 'organizations_devices_view'
}

export enum EOrganizationsNetworksPerm {
    ORGANIZATIONS_NETWORKS_DELETE = 'organizations_networks_delete',
    ORGANIZATIONS_NETWORKS_EDIT = 'organizations_networks_edit',
    ORGANIZATIONS_NETWORKS_VIEW = 'organizations_networks_view'
}
export const EOrganizationsNetworksTabPerm: TabWithTablePermissions = {
    deletePermission: EOrganizationsNetworksPerm.ORGANIZATIONS_NETWORKS_DELETE,
    editPermission: EOrganizationsNetworksPerm.ORGANIZATIONS_NETWORKS_EDIT,
    viewPermission: EOrganizationsNetworksPerm.ORGANIZATIONS_NETWORKS_VIEW
}

export enum EOrganizationsRolesPerm {
    ORGANIZATIONS_ROLES_DELETE = 'organizations_roles_delete',
    ORGANIZATIONS_ROLES_EDIT = 'organizations_roles_edit',
    ORGANIZATIONS_ROLES_VIEW = 'organizations_roles_view'
}
export const EOrganizationsRolesTabPerm: TabWithTablePermissions = {
    deletePermission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_DELETE,
    editPermission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_EDIT,
    viewPermission: EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_VIEW
}

export enum EOrganizationsApisPerm {
    ORGANIZATIONS_APIS_DELETE = 'organizations_apis_delete',
    ORGANIZATIONS_APIS_EDIT = 'organizations_apis_edit',
    ORGANIZATIONS_APIS_VIEW = 'organizations_apis_view'
}
export const EOrganizationsApisTabPerm: TabWithTablePermissions = {
    deletePermission: EOrganizationsApisPerm.ORGANIZATIONS_APIS_DELETE,
    editPermission: EOrganizationsApisPerm.ORGANIZATIONS_APIS_EDIT,
    viewPermission: EOrganizationsApisPerm.ORGANIZATIONS_APIS_VIEW
}
