<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import { computed } from 'vue'
import type { ExtendedPrimitives } from '../../../../../types/ExtendedPrimitives'

type Props = {
    arguments: Record<string, ExtendedPrimitives>
}
const props = defineProps<Props>()

const args = computed(() => Object.entries(props.arguments))
</script>

<template>
    <StackLayout direction="column" :gap="2">
        <TypographyItem :label="`${$t('device.actions.commands.arguments')}:`" />
        <StackLayout class="command-arguments">
            <TypographyItem
                v-for="([argKey, argValue], key) in args"
                :key="key"
                :label="`${argKey} : ${argValue}${key + 1 !== args.length ? ', ' : ''}`" />
        </StackLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.command-arguments {
    &.stack {
        flex-direction: row;
        flex-wrap: wrap;
    }
}
</style>
