<script setup lang="ts">
import { FullScreenLoader } from '@/components/LoadingSpinner'
import { ADMIN_APIS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EApiActions } from '@/store/apiStore/ApiStateType'
import { EStoreModules } from '@/store/storeType'
import { defaultApiModel, type ApiModel } from '@/types/models/ApiModel'
import AdministrationApiDetailView from '@/views/AdministrationView/Api/AdministrationApiDetailView.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import OrganizationSelector from '../../../../components/Selector/components/OrganizationSelector.vue'
import { EOrganizationActions } from '../../../../store/organizationStore/OrganizationStoreTypes'

const router = useRouter()

const data = ref<Partial<ApiModel>>({ ...defaultApiModel })
const isLoading = computed(() => store.state.api.isLoading)
const submitted = ref(false)

const updateApi = (value: Partial<ApiModel>) => (data.value = value)

const create = () => {
    submitted.value = true
    if (!data.value.name) return
    if (data.value.organization_id) {
        const { organization_id, ...otherData } = data.value
        return store
            .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.CREATE_API}`, {
                id: organization_id,
                data: otherData
            })
            .then(() => router.push(ADMIN_APIS_ROUTES.baseUrl))
    }
    store
        .dispatch(`${EStoreModules.API}/${EApiActions.CREATE_API}`, data.value)
        .then(() => router.push(ADMIN_APIS_ROUTES.baseUrl))
}
</script>

<template>
    <FullScreenLoader :isLoading="!!isLoading" />
    <AdministrationApiDetailView
        :data="data"
        :buttonLabel="$t('api.buttons.createApi')"
        hasPermission
        :loading="!!store.state.api.isLoading"
        @click="create"
        @update="updateApi">
        <OrganizationSelector
            :organizationId="data?.organization_id"
            @select="updateApi({ ...data, organization_id: $event?.id })"
            class="full-width" />
    </AdministrationApiDetailView>
</template>
