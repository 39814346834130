<script setup lang="ts">
import { BaseCard } from '@/components/Cards'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import moment from 'moment'
import { computed } from 'vue'
import DeviceCardContent from './DeviceCardContent.vue'
import DeviceCardFooter from './DeviceCardFooter.vue'
import DeviceCardHeader from './DeviceCardHeader.vue'
import CurrentlyRentOverlay from './components/CurrentlyRentOverlay.vue'

type Props = {
    data: DeviceModel
    status?: AlertSocketMessageModel
    detections: DetectionModel[]
    showNextEvent?: boolean
    showNextCheckpoint?: boolean
}

const props = defineProps<Props>()
type Emits = {
    (e: 'click'): void
}
defineEmits<Emits>()

const currentlyRent = computed(
    () =>
        props.data?.rental_start_at &&
        props.data?.rental_end_at &&
        moment().isAfter(props.data?.rental_start_at) &&
        moment().isBefore(props.data?.rental_end_at)
)
</script>

<template>
    <BaseCard
        :class="`flex primary-border color-text device-card ${
            currentlyRent ? undefined : 'pointer'
        }`"
        @click="currentlyRent ? undefined : $emit('click')"
        v-bind="$attrs">
        <template #title>
            <DeviceCardHeader
                :title="data.name ?? data.serial_number"
                :subtitle="data.device_type_name"
                :data="data"
                :status="status"
                :currentlyRent="currentlyRent"
                class="device-card-header align-items-center" />
        </template>
        <template #content>
            <CurrentlyRentOverlay
                v-if="currentlyRent"
                :start_date="data?.rental_start_at"
                :end_date="data?.rental_end_at"
                :renter_name="data?.rental_party_name"></CurrentlyRentOverlay>
            <DeviceCardContent
                v-else
                :data="data"
                :status="status"
                class="device-card-content"
                :showNextEvent="showNextEvent"
                :showNextCheckpoint="showNextCheckpoint">
                <slot name="content" />
            </DeviceCardContent>
        </template>
        <template v-if="!currentlyRent" #footer>
            <DeviceCardFooter :latestDetections="detections" />
        </template>
    </BaseCard>
</template>

<style scoped lang="scss">
.currently-rent-device {
    background-color: var(--color-background-secondary);
}

.device-card {
    flex-direction: column;
    min-height: 240px;

    & :deep(.card-header-title) {
        min-height: 70px;
        & .title {
            font-size: large;
            color: var(--color-text);
        }
    }

    & :deep(.card-header-title .subtitle) {
        color: var(--color-text-secondary);
    }

    & :deep(.card-content) {
        min-height: 110px;
        height: 100%;
    }

    & :deep(.card-footer) {
        min-height: 150px;
        flex: auto;
    }

    & :deep(.card-header-title),
    & :deep(.card-content),
    & :deep(.card-footer) {
        padding: 0.5em;
    }
}
</style>
